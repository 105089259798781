<template>
  <div 
    class="call-details"
    :class="{
      muted: 
      ((callN === muted.call) ? 
        (muted.type === 'm') : 
        (muted.type === 's'))
      }"
  >
    <div class="waveform-info">
      <b>Call: </b>
      <span
        v-on:click="selectCallOption(callN + 1)"
        class="title"
      > Call #{{callN}}</span>
      <br />
      <b>From:</b>
      <span class="info-val">{{callDetails.from.split("@")[1].split(">")[0]}}</span>
      <br />
      <b>To:</b>
      <span class="info-val">{{callDetails.to.split("@")[1].split(">")[0]}}</span>
      <br />
      <b>SSRC:</b>
      <span class="info-val">{{callDetails.ssrc}}</span>
      <br>
      <b>Relative start:</b>
      <span class="info-val">{{callDetails.startTime}}</span>
      <br />
      <p>
        <select class="audio-saver" v-on:change="downloadAudio">
          <option selected="selected">Save as...</option>
            <option :value="audioLinks && audioLinks.raw">
            raw
            </option>
            <option :value="audioLinks && audioLinks.raw">
            wav
            </option>
            <option :value="audioLinks && audioLinks.raw">
            mp3
            </option>
        </select>
      </p>
    </div>
    <div class="switch-buttons">
      <button 
        v-on:click="muteChannel(callN, 'm')"
        :class="{
          muted:(callN === muted.call) && 
            (muted.type === 'm')
        }"
      >M</button>
      <button
        v-on:click="muteChannel(callN, 's')"
        :class="{
          'muted-s':(callN === muted.call) && 
            (muted.type === 's')
        }"
      >S</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CallDetails',
  components: {

  },
  props: {
    callDetails: Object,
    callN: Number,
    audioLinks: Object,
    muted: Object,
    muteChannel: Function,
    selectCallOption: Function
  },
  methods: {
    downloadAudio(e) {
      window.open(e.target.value, "_blank");
    }
  }
}
</script>

<style scoped>
.waveform-info {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  margin-top: 5px;
  width: 85%;
}

.waveform-info span.title {
  color: #03C0FC;
  cursor: pointer;
}

.waveform-info {
  user-select: none;
}

.waveform-info p {
  text-align: right;
  margin-right: 15%;
}

.waveform-info b,
.waveform-info .info-val {
  user-select: none;
  pointer-events: none;
}

.switch-buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.switch-buttons button {
  width: 22px;
  height: 22px;
  padding: 0;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 11px;
  user-select: none;
}
.switch-buttons button.muted {
  background: rgb(15, 58, 122);
  color: #fff;
}
.switch-buttons button.muted-s {
  background: #ffae00;
}
.call-details.muted {
  opacity: 0.5;
}
</style>
