<template>
    <div 
      class="filter-button logout"
    >
        <button
          v-on:click="reloadState"
          class="logout-button"
        >
            Logout
        </button>
    </div>
</template>

<script>

import {  mapActions } from 'vuex';

export default {
  name: 'Logout',
  methods: {
    ...mapActions({
      removeAllState: 'packets/removeUploadedPacket'
    }),
    reloadState() {
      window.localStorage.clear();
      window.sessionStorage.clear();
      this.$router.push('/');
      document.location.reload()
    }
  }

}
</script>

<style scoped>
.logout .logout-button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 6px;
    padding: .4em 2.1em;
}
</style>
