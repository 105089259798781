var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"call-details",class:{
    muted: 
    ((_vm.callN === _vm.muted.call) ? 
      (_vm.muted.type === 'm') : 
      (_vm.muted.type === 's'))
    }},[_c('div',{staticClass:"waveform-info"},[_c('b',[_vm._v("Call: ")]),_c('span',{staticClass:"title",on:{"click":function($event){return _vm.selectCallOption(_vm.callN + 1)}}},[_vm._v(" Call #"+_vm._s(_vm.callN))]),_c('br'),_c('b',[_vm._v("From:")]),_c('span',{staticClass:"info-val"},[_vm._v(_vm._s(_vm.callDetails.from.split("@")[1].split(">")[0]))]),_c('br'),_c('b',[_vm._v("To:")]),_c('span',{staticClass:"info-val"},[_vm._v(_vm._s(_vm.callDetails.to.split("@")[1].split(">")[0]))]),_c('br'),_c('b',[_vm._v("SSRC:")]),_c('span',{staticClass:"info-val"},[_vm._v(_vm._s(_vm.callDetails.ssrc))]),_c('br'),_c('b',[_vm._v("Relative start:")]),_c('span',{staticClass:"info-val"},[_vm._v(_vm._s(_vm.callDetails.startTime))]),_c('br'),_c('p',[_c('select',{staticClass:"audio-saver",on:{"change":_vm.downloadAudio}},[_c('option',{attrs:{"selected":"selected"}},[_vm._v("Save as...")]),_c('option',{domProps:{"value":_vm.audioLinks && _vm.audioLinks.raw}},[_vm._v(" raw ")]),_c('option',{domProps:{"value":_vm.audioLinks && _vm.audioLinks.raw}},[_vm._v(" wav ")]),_c('option',{domProps:{"value":_vm.audioLinks && _vm.audioLinks.raw}},[_vm._v(" mp3 ")])])])]),_c('div',{staticClass:"switch-buttons"},[_c('button',{class:{
        muted:(_vm.callN === _vm.muted.call) && 
          (_vm.muted.type === 'm')
      },on:{"click":function($event){return _vm.muteChannel(_vm.callN, 'm')}}},[_vm._v("M")]),_c('button',{class:{
        'muted-s':(_vm.callN === _vm.muted.call) && 
          (_vm.muted.type === 's')
      },on:{"click":function($event){return _vm.muteChannel(_vm.callN, 's')}}},[_vm._v("S")])])])}
var staticRenderFns = []

export { render, staticRenderFns }