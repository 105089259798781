<template>
    <div 
      v-bind:class="{main: !protocol}"
      class="ladder-view"
      v-if="protocol || diagramOpened.switcher"
    >

      <div class="dialog-header">

        <div class="title-section">

          <div class="header-title">
            <span class="title-text">{{title}}</span>
            <span
              class="file-name"
              v-on:click="toggleDiagram({switcher: false, searchQuery: ''})"
            >{{uploadedFile.fileName}}</span>
          </div>

          <div 
            class="data-description"
            v-if="!protocol"
          >
              {{`${allPackets.length} packets | ${uniqueIpsLength} endpoints — `}}
              <a 
                class="display-link"
                href="#"
                v-on:click="applyDisplay">
                Apply as Display Filter
              </a>
          </div>

        </div>

      </div>

      <div class="ladder-content" :class="{protocol: protocol}">
        <div class="ladder-toolbar">
            <button 
                class="conversations"
                v-on:click="openDialog({value: 'ProtocolConversations', isLadder: isLadder, query: 'proto=eth'})"
            >
                <span>Conversations</span>
            </button>
            <button 
                class="protocols"
                v-on:click="openDialog({value: 'ProtocolHierarchyStats', isLadder: isLadder})"
            >
                <span>Protocols</span>
            </button>
            <button 
                class="endpoints"
                v-on:click="openDialog({value: 'NetworkEndpoints', isLadder: isLadder})"
            >
                <span>Endpoints</span>
            </button>
            <div class="filter-button search-filter">
                Display Filter
                <input
                    placeholder="Start tuping a display filter" type="text"
                    v-on:input="searchInsert"
                />
                <button
                    class="apply"
                    v-on:click="applySearch"
                >Apply</button>
                <button
                    class="clear"
                    v-on:click="clearSearch"
                >Clear</button>
            </div>
            <div class="endpoints-dropdown">
                Endpoints
                <div 
                  class="dropdown"
                  v-on:click="openEndpointList"
                  v-bind:class="{
                    active: listIsOpened,
                    unactive: !listIsOpened
                  }"
                >
                    <span class="label">{{endpointsOptions[selectedEndpoint].label}}</span>
                    <span class="icon"></span>

                    <div class="choice-list">
                      <div 
                        v-for="(option, indx) in endpointsOptions"
                        v-bind:key="indx + 'endpoints-options-lv'"
                        v-bind:option="option"
                        v-on:click="selectEndpointOption(indx)"
                        class="option"
                      >{{option.label}}</div>
                    </div>
                </div>
            </div>
            <div class="label-dropdown">
                Label
                <div 
                  class="dropdown"
                  v-on:click="openLabelList"
                  v-bind:class="{
                    active: labelListIsOpened,
                    unactive: !labelListIsOpened
                  }"
                >
                    <span class="label">{{labelOptions[selectedLabel].label}}</span>
                    <span class="icon"></span>

                    <div class="choice-list">
                      <div 
                        v-for="(option, indx) in labelOptions"
                        v-bind:key="indx + 'label-options-lv'"
                        v-bind:option="option"
                        v-on:click="selectLabelOption(indx)"
                        class="option"
                      >{{option.label}}</div>
                    </div>
                </div>
            </div>
            <div
              v-if="!protocol"
              class="reset">reset</div>
        </div>
        <div
          v-if="protocol"
          class="warn-message-protocol"
        >
            Choose a Conversation, Protocol, Endpoint, or Apply an initial Display Filter to generate a ladder diagram of the selected packets.
        </div>
            <div 
              v-if="!protocol"
              class="ladder-diagrams">
                <div
                  v-if="packetsLength"
                  class="diagram-graph"
                  v-bind:class="{'is-fixed': isFixed}"
                >
                  <svg 
                    id="ips-header"
                    preserveAspectRatio="xMinYMin meet" 
                    :viewBox="`0 0 ${320 * uniqueIpsLength} 50`"
                    v-bind:class="{'is-fixed': isFixed}"
                  >
                      <rect
                        :width="320 * uniqueIpsLength" 
                        height="50" 
                        x="0" 
                        y="0" 
                        fill="rgba(255,255,255,0.7)" 
                        :stroke-dasharray="`${320 * uniqueIpsLength} ${320 * uniqueIpsLength + (25 * 2)}`" 
                        stroke="rgba(0,0,0,0.4)" 
                        stroke-width="1" 
                        :stroke-dashoffset="`-${320 * uniqueIpsLength + (25 * 2)}`"
                        id="header"
                      ></rect>
                      <g
                        :class="`ip-address-${uipind} ip-address`"
                        v-for="(uip, uipind) in uniqueIps"
                        v-bind:key="'uniue-ip-address' + uipind"
                        :transform="`translate(${pointers[uipind] ? pointers[uipind] : 0}, ${pointersY[uipind] ? pointersY[uipind] : 0})`"
                        text-anchor="middle"
                      >
                        <!-- :x="(330 * (uipind + 1)) - 190" -->
                        <!-- :x="(330 * (uipind + 1)) - 184" -->
                        <rect
                          :x="step(uipind + 1) - 36"
                          width="70"
                          height="20"
                          y="15"
                          fill="#fff"
                          stroke="#03C0FC"
                          rx="6"
                          style="cursor: move"
                          v-on:mousedown="dragStart($event, uipind)" 
                          v-on:mousemove="dragMove($event, uipind)" 
                          v-on:mouseleave="dragStop($event, uipind)"
                          v-on:mouseup="dragStop($event, uipind)"
                        ></rect>
                        <text
                          :x="step(uipind + 1) - 2"
                          y="28"
                          font-size="9px"
                          font-family="'Roboto', sans-serif"
                          style="cursor: move; pointer-events: none; user-select: none"
                        >{{
                            (!pointersY[uipind] || (pointersY[uipind] &lt; 20)) ?
                            `${(endpointsType === 'udp' || endpointsType === 'tcp') ? 
                            `${uip}:${ports[uipind]}` : uip}` :
                            `remove`
                          }}</text>
                      </g>
                  </svg>
                  <svg id="path-diagram" preserveAspectRatio="xMinYMin meet" :viewBox="`0 0 ${320 * uniqueIpsLength} ${(40 * allPackets.length) + 50}`">
                    <defs>
                      <marker id="arrow" refX="4" refY="2.5" markerWidth="5" markerHeight="5" orient="auto">
                        <path stroke="none" fill="#000" d="M0,0 L5,2.5 L0,5 Z" class="arrow"></path>
                      </marker>
                      <marker id="backarrow" refX="4" refY="2.5" markerWidth="5" markerHeight="5" orient="auto">
                        <path stroke="none" fill="#000" d="M0,2.5 L5,0 L5,5 Z" class="arrow"></path>
                      </marker>
                    </defs>
                    <g>
                        <text 
                            class="time-labels"
                            v-for="(sipPacket, spIndx) in allPackets"
                            v-bind:key="'sipPacketLabel' + spIndx"
                            v-bind="sipPacket"
                            :x="20" 
                            :y="`${40 * (spIndx + 1)}`"
                            font-size="8px"
                            font-family="'Roboto', sans-serif"
                        >{{`${sipPacket.time.split(".")[0]}`}}</text>
                        <!-- parserTime(sipPacket.time) -->
                    </g>

                    <g 
                      v-for="(col, indx) in uniqueIps"
                      v-bind:key="'uniqueip' + indx"
                      v-bind:col="col"
                      v-bind:uniqueKey="(indx > 0 && indx % 2 !== 0) && indx"
                    >
                        <path 
                            stroke="gray" 
                            fill="none" 
                            :d="`M${step(indx + 1)},0 L${step(indx + 1)},${(50 * allPackets.length) + 50}`" 
                            :transform="`translate(${pointers[indx] ? pointers[indx] : 0}, 0)`"
                            class="arrow"></path>
                    </g>

                    <g
                        v-for="(sipPacket, spIndx) in allPackets"
                        v-bind:key="'sipPacket' + spIndx"
                        v-bind="sipPacket"
                        class="one-path"
                        v-on:click="showDecodedData(sipPacket.no)"
                    >
                        <text 
                            :x="
                                (step(uniqueIps.findIndex(uip => uip===sipPacket.destination) + 1) >
                                step(uniqueIps.findIndex(uip => uip===sipPacket.source) + 1)) ?
                                step(uniqueIps.findIndex(uip => uip===sipPacket.destination) + 1) -170 :
                                step(uniqueIps.findIndex(uip => uip===sipPacket.source) + 1) - 170
                            " 
                            transform="translate(0,-5)"
                            text-anchor="middle"
                            :y="`${40 * (spIndx + 1)}`"
                            font-size="9px"
                            font-family="'Roboto', sans-serif"
                        >{{`${sipPacket[pathsLabel]}`}}</text>
                        <path 
                            :stroke="`${colors.find(color => color[0] === sipPacket.protocol)[1]}`" 
                            :d="`M${
                                step(uniqueIps.findIndex(uip => uip===sipPacket.source) + 1)
                            },${40 * (spIndx + 1)} L${step(uniqueIps.findIndex(uip => uip===sipPacket.destination) + 1)},${40 * (spIndx + 1)}`" 
                            :style="`${step(uniqueIps.findIndex(uip => uip===sipPacket.source) + 1) > 
                                step(uniqueIps.findIndex(uip => uip===sipPacket.destination) + 1) ? 
                                'marker-end: url(#arrow)' : 'marker-end: url(#arrow)'}`"
                        ></path>
                    </g>
                  </svg>
                </div>
                <div
                  v-else
                  class="default-text"
                >
                  Choose a Conversation, Protocol, Endpoint, or Apply an initial Display Filter to generate a ladder diagram of the selected packets.
                </div>
            </div>
      </div>
      <LeftPaneDialog
        v-if="!protocol && openedDetails"
        v-bind:closeDialog="openLocalDialog"
        v-bind:fileName="uploadedFile.fileName"
      />
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'; //mapGetters, mapActions
import * as d3 from "d3";
import LeftPaneDialog from './content/LeftPaneDialog';

export default {
  name: 'LadderView',
  components: {
    LeftPaneDialog,
  },
  props: {
    closeDialog: Function,
    fileName: String,
    protocol: Boolean,
    isLadder: Boolean
  },
  data () {
    return {
        title: 'Protocol Ladder View: ',//this.protocol ? '' : 'Protocol Ladder View: ',
        // filename: this.fileName,//this.protocol ? this.fileName : this.uploadedFile.fileName || '',
        isFixed: false,
        uniqueIps: [],
        ports: [],
        uniqueTypes: [],
        allPackets: [],
        colors: [
            ["SIP/SDP", "blue"],
            ["SIP", "orange"],
            ["RTP", "green"],
            ["RTCP", "red"],
            ["RTP EVENT", "purple"],
            ["SIP/sipfrag", "brown"],
        ],
        dragged: false,
        startPoint: 0,
        startPointY: 0,
        pointers: [],
        pointersY: [],
        step: (repeat) => (330 * repeat) - 155,
        endpointsOptions: [
            // {label: 'Default', value: ''}, 
            {label: 'Network Adresses', value: ''},
            {label: 'UDP', value: 'udp'},
            // {label: 'Hardware Addresses', value: 'h'},
            {label: 'TCP', value: 'tcp'},
            // {label: 'ASNumber', value: 'a'}
        ],
        labelOptions: [
            {label: 'Info', value: 'info'}, 
            {label: 'Protocol', value: 'protocol'},
        ],
        listIsOpened: false,
        labelListIsOpened: false,
        selectedEndpoint: 0,
        selectedLabel: 0,
        pathsLabel: 'info',
        endpointsType: '',
        openedDetails: false
    }
  },
  computed: {
    ...mapState({
      dialogContent: state => state.packets.dialogContent,
      diagramOpened: state => state.views.diagramOpened,
      packets: state => state.packets.packetList,
      uuid: state => state.packets.uuid,
      currentWindow: state => state.views.currentDialog,
      packetDetails: state => state.packets.packetDetails,
      selectedPacket: state => state.packets.selectedPacket,
    }),
    ...mapGetters('packets', {
      uploadedFile: 'uploadedFileFormat',
    }),
    packetsLength() {
        return this.packets && this.packets.length;
    },
    diagramQuery() {
        return this.diagramOpened && this.diagramOpened.searchQuery
    },
    uniqueIpsLength() {
        return this.uniqueIps && this.uniqueIps.length;
    },
    // graphDimensionsCalculated: function(){
    //   return {
    //     width: `${320 * this.uniqueIpsLength}`, 
    //     height: `${(50 * this.allPackets.length) + 500}`
    //   };
    // }
  },
  watch: {
    packetsLength(newValue, oldValue) {
      (newValue !== oldValue) && this.drawDiagram();// : d3.selectAll("svg > *").remove();
    },
    diagramQuery(newValue, oldValue) {
        (newValue !== oldValue) && this.loadPackets({filter: newValue});
    },
    uploadedFile(newValue) {
        if (newValue) this.filename = newValue.fileName;
    }
  },
  methods: {
    dragStart (d, i) {

        this.dragged = true;
        this.startPoint = d.clientX;
        this.startPointY = d.offsetY;
        let curPointers = [...this.pointers],
            curPointersY = [...this.pointersY];
        curPointers[i] = 0;
        curPointersY[i] = 0;
        this.pointers = curPointers;
        this.pointersY = curPointersY;
        console.log(this.pointersY, d);
        this.$el.addEventListener('mousemove', this.dragMove, false);
        this.$el.addEventListener('mouseup', this.dragStop, false);
        this.$el.addEventListener('mouseleave', this.dragStop, false);
    },
    dragStop (d, i) {
        if (this.dragged && ((this.pointers[i] > 160) || (this.pointers[i] < -160))) {
            let reordered = this.uniqueIps;

            reordered.move = function(from, to) {
            this.splice(to, 0, this.splice(from, 1)[0]);
            };

            (this.pointers[i] > this.startPoint) ? 
                reordered.move(i,(i +1)) : reordered.move(i,(i -1));
            this.uniqueIps = reordered;
        }
        
        if (this.dragged && this.pointersY[i] <= 20) {
            this.pointersY[i] = 0;
        }
        if (this.dragged && this.pointersY[i] > 20) {
            // this.uniqueIps.splice(i, 1);
            // this.pointersY.splice(i, 1);
            this.dragged = false;
            let searchQuery = `!(ip.addr == ${this.uniqueIps[i]})`;
            this.loadPackets({filter: searchQuery});
        }

        this.dragged = false;
        let curPointers = [...this.pointers];
        curPointers[i] = 0;
        this.pointers = curPointers;
    },
    dragMove (d, i) {
        if (this.dragged && (i >=0 )) {
            console.log(d);
          const svg = document.querySelector('#ips-header');
          let pt = svg.createSVGPoint();

          const cursorPoint = (e) =>  {
            pt.x = e.clientX - this.startPoint; pt.y = 0;
            return pt.matrixTransform(svg.getScreenCTM().inverse());
          }
    
          let loc = cursorPoint(d);

          let pointerMoveY = (d.offsetY - (d.target.height.baseVal.value + d.target.y.baseVal.value));

          let curPointers = [...this.pointers],
            curPointersY = [...this.pointersY];
            curPointers[i] = loc.x;
            curPointersY[i] = (pointerMoveY >= 0) ? pointerMoveY : 0;
          this.pointers = curPointers;
          this.pointersY = curPointersY;
        } 
    },
    showDecodedData(no) {
        this.selectPacket(no);
        this.openLocalDialog();
        this.getDetails(no);

        // this.toggleDialog({value: 'LeftPaneDialog'});
        // this.openDialog({value: 'LeftPaneDialog'});
    },
    openLocalDialog() {
        this.openedDetails = !this.openedDetails;
    },
    parserTime: (d) => {
        const parseIt = d3.timeParse("%Y-%m-%d %H:%M:%S"),
            formatIt = d3.timeFormat("%S.%L"); 
        return formatIt(parseIt(d))
    },
    reorderDiagram() {
        this.uniqueIps = this.uniqueIps.reverse();
    },
    searchInsert(ev) {
        this.searchField = ev.target.value;
    },
    applySearch() {
        console.log(this.searchField, ' request');
        this.loadPackets({filter: this.searchField});
        if (this.protocol) {
          this.closeDialog('ProtocolLadderView');
          this.toggleDiagram({switcher: true, searchQuery: this.searchField});
        //   console.log(this.$router);
          let routeData = this.uuid && this.$router.resolve({path: `/analysis/${this.uuid}/ladder`, query: {searchField: this.searchField}});
          this.uuid && window.open(routeData.href, '_blank');
        }
    },
    applyDisplay() {
        this.loadPackets({filter: this.searchField});
        this.toggleDiagram({switcher: true, searchQuery: this.searchField});
        let routeData = this.$router.resolve({path: `/`});
        this.uuid && window.open(routeData.href, '_blank');
    },
    clearSearch() {
        this.searchField = '';
        this.removeAllPacketData();
    },
    ...mapActions({
      toggleDiagram: 'views/toggleDiagram',
      toggleDialog: 'views/toggleDialog',
      loadPackets: 'packets/getPacketsList',
      openDialog: 'packets/loadDialogData',
      removeAllPacketData: 'packets/removeUploadedPacket',
      selectPacket: 'packets/getSelectedPacket',
      getDetails: 'packets/getPacketDetails',
    }),
    drawDiagram() {

        let uniqueIps = [],
            ports = [],
            uniqueTypes = [],
            allPackets = this.packets,
            // onlySip = this.packets.filter(pl => {
            //   if (pl.protocol.includes('SIP')) {
            //     uniqueTypes.push(pl.info);
            //     return pl;
            //   }
            // }),
        // sources = onlySip.filter((v,i,a)=>a.findIndex(t=>(t.source === v.source))===i),
        // destination = onlySip.filter((v,i,a)=>a.findIndex(t=>(t.destination === v.destination))===i),
        sourcesDestinations = allPackets.filter((v,i,a)=>a.findIndex(t=>((t.source === v.source) && (t.destination === t.destination)))===i);
        sourcesDestinations.forEach(sd => {
            uniqueIps.push(sd.source, sd.destination);
            ports.push(sd.sourcePort, sd.destinationPort);
        });

        allPackets.forEach(pckt => {
            uniqueTypes.push(pckt.protocol);
        });
        uniqueIps = [...new Set(uniqueIps)];
        ports = [...new Set(ports)];
        uniqueTypes = [...new Set(uniqueTypes)];
        this.allPackets = allPackets;
        this.uniqueIps = uniqueIps;
        this.ports = ports;
        this.uniqueTypes = uniqueTypes;

        console.log(uniqueTypes);

    },
    handleScroll (event) {
        console.log(event.target.scrollingElement.scrollTop);
        this.isFixed = event.target.scrollingElement.scrollTop >= 240;
    },
    openEndpointList () {
      this.listIsOpened = !this.listIsOpened;
    },
    openLabelList () {
        this.labelListIsOpened = !this.labelListIsOpened;
    },
    selectEndpointOption(val) {
        this.selectedEndpoint = val;
        this.endpointsType = this.endpointsOptions[val].value;
        this.loadPackets({filter: this.endpointsType});
    },
    selectLabelOption(val) {
        console.log(this.labelOptions[val].value);
        this.selectedLabel = val;
        this.pathsLabel = this.labelOptions[val].value;
        // let query = `proto=${val}`;
        
        // this.openDialog({value: this.$options.name, query});
    },
  },
  mounted() {
      let searchField = this.$router.history.current.query.searchField,
          current = this.$router.history.current.params;
      console.log(searchField, current);
      if (!this.protocol && (this.$router.history.current.name === 'ladder')) {
        console.log(current, searchField, this.$store.state.packets.uploadedFile.fileName);
        this.loadPackets({filter: searchField});
        this.toggleDiagram({switcher: true, searchQuery: searchField ? searchField : ''});
        this.drawDiagram();
      }
    document.addEventListener('scroll', (ev) => {
        ev.target.scrollingElement && this.handleScroll(ev);
    });
  }
}
</script>

<style scoped>

body {
  overflow: auto;
}

.ladder-view {
  /* max-width: 760px; */
  max-height: 296px;
  width: 100%;
  height: 30%;
  margin-bottom: auto;
}
.ladder-view .dialog-header {
  margin: 13px 0 0;  
}
.ladder-view.main {
    width: 100%;
    /* height: calc(100vh); */
    height: auto;
    position: absolute;
    top: 0px;
    left: 0;
    background: #fff;
    z-index: 8;
    padding-top: 20px;
    max-width: none;
    max-height: none;
}
.app-container::-webkit-scrollbar {
    width: 10px;
}

.ladder-content {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    flex-direction: column;
}
.ladder-content.protocol {
    height: max-content;
}
.warn-message-protocol {
    width: 60%;
    margin: 35px auto;
    text-align: center;
}
.main .ladder-content,
.main .ladder-diagrams {
    height: auto;
    overflow: auto;
}

.main .ladder-content {
  min-height: 20vh;
}

.ladder-diagrams {
    font-size: 14px;
    width: 100%;
    height: 100%;
    margin: auto;
    margin-top: 0;
    text-align: center;
    overflow: scroll;
}
.diagram-graph {
    /* width: 100%;
    height: 100%; */

}
.diagram-graph.is-fixed {
    margin-top: 97px;
}
.diagram-graph .ips-header {
    display: flex;
    width: 100%;
    position: absolute;
    height: 70px;
    align-items: center;
    background: rgba(255,255,255,0.5);
    border-bottom: 1px solid #d2d2d2;
}

.diagram-graph #ips-header.is-fixed {
    position: fixed;
    top: 0;
    margin-top: 0;
}
.diagram-graph .ips-header .ip-address {
    display: flex;
    margin-left: 340px;
}
.diagram-graph #ips-header {
    margin-top: 30px;
    overflow: visible;
}
.diagram-graph #ips-header .ip-address button {
    border: 2px solid #03C0FC;
    background: #fff;
    border-radius: 6px;
    height: 35px;
}
.diagram-graph #ips-header .ip-address:first-child {
    margin-left: 190px;
}
#path-diagram {
    /* margin-top: 70px; */
    margin-top: 20px;
}

#path-diagram .one-path {
    cursor: pointer;
}

#path-diagram .one-path:hover text {
    font-weight: 600;
}

.ladder-toolbar {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    font-size: 11px;
    height: 40px;
    background: rgba(3, 192, 252, 0.1);
    border-radius: 6px;
}

.ladder-toolbar .dropdown {
    border: 0.5px solid #000;
    border-radius: 6px;
    background: none;
    height: 28px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: max-content;
    /* padding: 5px 8px 6px; */
    font-size: 11px;
    margin-left: 5px;
    position: relative;
}
.ladder-toolbar .dropdown .icon {
    display: flex;
    text-indent: -9999px;
    width: 8px;
    height: 8px;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-image: url('/../assets/chevron_down.svg');
    mask-image: url('/../assets/chevron_down.svg');
    -webkit-mask-size: 8px 8px;
    mask-size: 8px 8px;
    background-color: #000;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}

.ladder-toolbar .search-filter {
    flex-basis: 45%;
    white-space: nowrap;
}

.conversations,
.protocols,
.endpoints {
    height: 22px;
    border: 1px solid #03C0FC;
    color: #03C0FC;
    background: #fff;
    border-radius: 6px;
    font-size: 11px;
    padding: 0;
}

.main .conversations,
.main .protocols,
.main .endpoints {
    /* height: 70%;
    flex-basis: 6%;
    margin-right: 5px;
    height: 35px; */
    width: auto;
    margin-right: 10px;
    padding: .4em 2.1em;
    height: auto;
    font-size: 13px;
}

.main .search-filter {
    flex-basis: 56%;
    height: 6vh;
    justify-content: center;
    font-size: 13px;
}

.main .search-filter input {
    margin-left: 10px;
    height: auto;
    font-size: 13px;
    flex-basis: 40%;
    padding: .4em;
}

.main .search-filter .apply,
.main .search-filter .clear {
    /* flex-basis: 10%;
    height: 70%;
    font-size: 1.2vw; */
    width: auto;
    height: auto;
    padding: .4em 2.1em;
}

.main .ladder-toolbar {
    width: calc(100% - 50px);
    padding: 0 10px;
    font-family: 'Roboto', sans-serif;
}
.main .dialog-header {
    margin: 30px 18px;
    font-family: 'Roboto', sans-serif;
}

.header-title .file-name {
    color: #03C0FC;
    cursor: pointer;
}

.main .ladder-toolbar {
    height: 6vh;
}

.ladder-toolbar button,
.ladder-toolbar .reset {
    cursor: pointer;
}

.main .label-dropdown {
    justify-content: space-around;
    margin-right: 10px;
    font-size: 13px;
}
.main .endpoints-dropdown {
    justify-content: space-around;
    margin-right: 10px;
    font-size: 13px;
}

.main .endpoints-dropdown .dropdown {
    /* flex-basis: 60%; */
    padding: .4em;
    width: auto;
    height: auto;
    margin-left: 10px;
    font-size: 13px;
}

.main .ladder-toolbar .endpoints-dropdown .dropdown .label {
  width: 120px;
  text-align: center;
}

.main .label-dropdown .dropdown {
    /* flex-basis: 50%; */
    padding: .4em 2.1em;
    width: auto;
    height: auto;
    margin-left: 10px;
    font-size: 13px;
}
.main .label-dropdown .dropdown .icon,
.main .endpoints-dropdown .dropdown .icon {
    margin-left: 5px;
}

.main .reset {
    /* flex-basis: 4%; */
    display: flex;
    justify-content: center;
    font-size: 13px;
}

.main .title-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.main .data-description {
    font-size: 14px;
}

.main .data-description .display-link {
    color: #03C0FC;
}

.conversations {
    width: 78px;
}
.protocols {
    width: 66px;
}
.endpoints {
    width: 60px;
}
.endpoints-dropdown,
.label-dropdown {
    display: flex;
    align-items: center;
}

.endpoints-dropdown .dropdown {
    width: 115px;
    height: 22px;
    background: #fff;
}
.label-dropdown .dropdown {
    width: 40px;
    height: 22px;
    background: #fff;
}

.dropdown.active .choice-list {
    display: flex;
    animation: listexpand 0.3s linear forwards;
}
.dropdown.unactive .choice-list {
    display: flex;
    animation: listrelease 0.3s linear forwards;
}

@keyframes listrelease {
    from {
        height: max-content;
        pointer-events: auto;
        opacity: 1;
    }
    to {
        height: 0;
        pointer-events: none;
        opacity: 0;
    }
}
@keyframes listexpand {
    from {
        height: 0;
        pointer-events: none;
        opacity: 0;
    }
    to {
        height: max-content;
        pointer-events: auto;
        opacity: 1;
    }
}

.dropdown .choice-list {
    display: none;

    flex-direction: column;
    align-items: flex-start;

    position: absolute;
    top: 30px;
    left: 0;
    background: #fff;
    width: max-content;
    border-radius: 6px;
    border: 0.5px solid #000;
    color: #000;
    z-index: 10;
    height: 0;
    opacity: 0;

    max-height: 125px;
    overflow: scroll;
}

.dropdown .choice-list .option {
    padding: 3px;
    width: 100%;
}
.dropdown .choice-list .option:hover {
    background: #d3d3d3;
}

.search-filter {
    align-items: center;
    background: none;
}
.search-filter input {
    height: 20px;
    padding: 0;
    border: 0.5px solid #03C0FC;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    width: 200px;
    text-indent: 14px;
    font-size: 11px;
    margin-left: 5px;
}
.search-filter .apply {
    width: 44px;
    height: 22px;
    background: #fff;
}
.search-filter .clear {
    border-left: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    width: 43px;
    height: 22px;
    background: #fff;
}
@media screen and (min-width: 1500px) {
    .main .search-filter {
      justify-content: flex-end;
      margin: 20px;
    }
}
@media screen and (max-width: 767px) {
    .ladder-content {
        height: 80%;
        margin-top: 5%;
    }
    .ladder-toolbar {
        flex-wrap: wrap;
        height: auto;
        justify-content: initial;
        padding: 9px;
    }
    .filter-button {
        margin: auto 0;
    }
    .conversations, .protocols, .endpoints {
        margin-right: 10px;
    }
    .search-filter input {
        margin-left: 10px;
    }
    .dropdown {
        margin-left: 10px;
        margin-right: 10px;
    }
}
</style>
