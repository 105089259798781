<template>
  <div 
    class="wave-form"
  >
    <div class="wave-form-diagram"></div>
  </div>
</template>

<script>

export default {
  name: 'WaveForm',
  components: {

  },
  data() {
      return {
      }
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style scoped>
/* .audio-pane:not(.mobile) {
  position: absolute;
  width: 100%;
  top: 100%;
  transform: translateY(-100%);
} */
.wave-form {
  border-bottom: 1px solid #999;
  height: 120px;
  display: flex;
  align-items: center;
}

.wave-form-diagram {
  height: 70%;
  width: calc(100% - 7px);
  border: 1px solid #999;
  margin-left: 3px;
  border-radius: 6px;
  background: #d6d6d6;
}
</style>
