<template>
    <div class="dialog-body voip-calls" :class="{window: isWindow}">

      <div class="dialog-header">

        <div class="title-section">

          <div class="additional-header">
              <div class="additional-title">
                  Showing {{viewCount}}
              </div>
              
          </div>

          <div class="header-title">
            <span class="title-text">{{title}}</span>
            <span class="file-name">{{filename}}</span>
          </div>

        </div>

        <div class="close-section">
          <button
            class="close-dialog"
            v-on:click="closeDialog"
            :ref="'close'"
          >
            <span></span>
          </button>
        </div>

      </div>

      <div class="content-wrapper">
          <div class="additonal-description">
              {{description}}
          </div>

        <div
          v-if="isWindow"
          class="custom-buttons-window"
        >

          <button 
            class="entire-call"
            v-on:click="switchToDiagram"
          >
            <span>View entire call flow</span>
          </button>
          <button 
            class="sip-statistics"
            v-on:click="loadInternalData"
          >
            <span>SIP Statistics</span>
          </button>
          
        </div>

          <div class="content">
            <table cellspacing="0" >
                <thead  >
                    <tr   style="text-align: left;">
                        <th
                            v-for="(tableHeader, ind) in tableHeaders"
                            v-bind:key="tableHeader.value + ind"
                            v-bind="tableHeader"
                            v-bind:class="tableHeader.customClass"
                            v-on:click="sortOrder(tableHeader, ind)">
                            <div class="container">
                                {{tableHeader.label}}
                                <div 
                                class="order-button"
                                v-bind:class="{
                                    asc: (tableHeader.value === currentHeader.value) 
                                        && currentHeader.order === 'asc',
                                    desc: (tableHeader.value === currentHeader.value) 
                                        && currentHeader.order === 'desc'}"
                                >
                                    <span class="order-icon order-asc"></span>
                                    <span class="order-icon order-desc"></span>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody tabindex="0" class="show-annotations">
                    <tr
                      v-for="(entry, ind) in entries"
                      v-bind:key="'entry_' + ind"
                      v-bind="entry"
                      v-on:click="switchToDiagram(entry.call)"
                    >
                        <td   class="bottom-border" >{{ind}}</td>
                        <td   class="bottom-border" >{{entry.startTime}}</td>
                        <td    class="bottom-border" >{{entry.stopTime}}</td>
                        <td   class="bottom-border" >{{entry.initialSpeaker}}</td>
                        <td    class="bottom-border" >{{
                          entry.from.split(";") ?  entry.from.split(";")[0] : entry.from
                        }}</td>
                        <td   class="bottom-border" >{{entry.to}}</td>
                        <td    class="bottom-border"  >{{entry.protocol}}</td>
                        <td   class="bottom-border"  >{{entry.packets}}</td>
                        <td  class="bottom-border"  >{{entry.state}}</td>
                        <td    class="bottom-border"  >{{entry.comment}}</td>
                    </tr>
                </tbody>
            </table>
          </div>
      </div>

      <div
        v-if="!isWindow"
        class="dialog-buttons"
      >

        <div class="custom-buttons">

          <button 
            class="entire-call"
            v-on:click="switchToDiagram"
          >
            <span>View entire call flow</span>
          </button>
          <button 
            class="sip-statistics"
            v-on:click="openDialog({value: 'SIPStats'})"
          >
            <span>SIP Statistics</span>
          </button>
          
        </div>

        <div class="default-buttons">

          <button
            class="new-window"
            v-on:click="openNewWindow"
          >
            <span>Open In New Window</span>
          </button>
          <button 
            class="done"
            v-on:click="closeDialog"
          >
            <span>Done</span>
          </button>
          
        </div>
      </div>

      <div 
        v-if="isWindow && openedSip"
        class="dialog-container"
      >

        <div
          v-if="dialogLoading"
          class="loading-dialog"
        >
          <div class="animated"></div>
        </div>
          <SIPStats
            v-bind:fileName="filename"
            v-bind:closeDialog="closeSip"
            v-bind:internalDialog="true"
          />
      </div>

    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'; //mapGetters, 
import SIPStats from './SIPStats';

export default {
  name: 'VoIPCalls',
  components: {
    SIPStats
  },
  props: {
    closeDialog: Function,
    fileName: String,
    isWindow: Boolean
  },
  data () {
    return {
        title: 'Calls from ',
        filename: this.fileName || 'voip-extension.pcap',
        description: 'Click on a row to open the SIP flow diagram for that conversation. If the conversation includes any RTP streams, they may be playable within CloudShark.',
        tableHeaders: [
            {
                value: 'call',
                label: 'Call',
            },
            {
                value: 'start-time',
                label: 'Start Time',
            },
            {
                value: 'stop-time',
                label: 'Stop Time',
            },
            {
                value: 'initial-speaker',
                label: 'Initial Speaker'
            },
            {
                value: 'from',
                label: 'From'
            },
            {
                value: 'to',
                label: 'To'
            },
            {
                value: 'protocol',
                label: 'Protocol'
            },
            {
                value: 'packets',
                label: 'Packets'
            },
            {
                value: 'state',
                label: 'State'
            },
            {
                value: 'comment',
                label: 'Comment'
            },
        ],
        entries: [
        ],
        currentHeader: {},
        viewCount: 2,
        openedSip: false
    }
  },
  computed: {
    ...mapState({
      dialogContent: state => state.packets.dialogContent,
      dialogInternal: state => state.packets.dialogInternal,
      currentDialog: state => state.views.currentDialog,
      uuid: state => state.packets.uuid
    }),
    dialogContentProps() {
      let listedProps = this.dialogContent && 
          Object.entries(this.dialogContent);
      return listedProps[0] && listedProps[0][1];
    },
    dialogInternalData() {
      let dialogData = null;
      if (this.dialogInternal && this.dialogInternal.dialogInternal) {
        dialogData = this.dialogInternal.dialogInternal;
      }
      return dialogData;
    }
  },
  watch: {
      dialogContentProps(newValue, oldValue) {
          console.log(newValue, oldValue);
        if (newValue) {
          this.entries = newValue;

          console.log(this.dialogContent[this.currentDialog])
        }
      },
      dialogInternalData(newValue) {
        if (newValue) {
          this.openedSip = true;
        } 
      }
  },
  methods: {
    sortOrder(hd, ind) {
        console.log(hd.value, ind);
        this.currentHeader = {
            ...hd, 
            order: this.currentHeader.order ? 
            (this.currentHeader.order === 'asc' ? 'desc' : '') : 'asc'
        };
        console.log(this.currentHeader.value, this.currentHeader.order);
    },
    ...mapActions({
      openDialog: 'packets/loadDialogData',
      openInternalDialog: 'packets/dialogInternalRequest',
      toggleWindow: 'views/toggleWindow',
      toggleDiagram: 'views/toggleFlowDiagram',
    }),
    openNewWindow() {
      let routeData = this.uuid && this.$router.resolve({path: `window/${this.uuid}/${this.$options.name}`}); //query: {searchField: this.searchField}
      this.uuid && window.open(routeData.href, '_blank');
      this.toggleWindow({value: this.$options.name});
      setTimeout(() => this.$refs.close.click(), 3000);
    },
    switchToDiagram() {
      
      !this.isWindow && this.closeDialog(this.$options.name);
      this.toggleDiagram({switcher: true, searchQuery: 'sdp', data: this.entries});
      let routeData = this.uuid && this.$router.resolve({path: `/analysis/${this.uuid}/voip_flow`, query: {searchField: 'sdp'}});
      this.uuid && window.open(routeData.href, '_blank');

      // this.toggleDiagram({switcher: true, searchQuery: 'udp.stream eq 0'});
      // this.closeDialog(this.$options.name); 
    },
    loadInternalData() {
      this.openInternalDialog('SIPStats');
    },
    closeSip() {
      this.openedSip = false;
    }
  },
  mounted() {
    if (this.dialogContentProps && this.dialogContentProps.length > 0) {
      this.entries = this.dialogContentProps;
    }
    if (this.$router.history.current.name === 'window') {
      this.toggleWindow({value: this.$options.name});
    }
  },
}
</script>

<style scoped>
.voip-calls {
  /* max-width: 1024px;
  max-height: 262px; */
  max-width: 1200px;
  max-height: 400px;
  width: 100%;
  height: 100%;
}
.window .content-wrapper {
  height: 100%;
}
.voip-calls .header-title {
    display: flex;
    align-items: center;
}

.voip-calls .additional-header {
    margin-right: 10px;
}

.voip-calls .additional-header .additional-title {
    display: flex;
    align-items: center;
}

.voip-calls .additional-header .dropdown {
    border: 0.5px solid #000;
    border-radius: 6px;
    background: none;
    width: max-content;
    padding: 5px 8px 6px;
    font-size: 11px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 10px;
}

.voip-calls .additional-header .dropdown .icon {
    display: flex;
    text-indent: -9999px;
    width: 8px;
    height: 8px;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-image: url(/../assets/chevron_down.svg);
    mask-image: url(/../assets/chevron_down.svg);
    -webkit-mask-size: 8px 8px;
    mask-size: 8px 8px;
    background-color: #000;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}

.voip-calls .title-section {
    display: flex;
}
.voip-calls .dialog-buttons {
    justify-content: space-between;
    margin-left: 25px;
}
.voip-calls .custom-buttons {
    display: flex;
    justify-content: space-between;
    width: 275px;
}
.voip-calls .custom-buttons button,
.voip-calls .custom-buttons-window button {
    border: 0.5px solid #03C0FC;
    border-radius: 6px;
    background: none;
    color: #03C0FC;
    height: 35px;
    padding: 0px 15px;
    background: #fff;
    cursor: pointer;
}
.voip-calls .custom-buttons-window button {
  margin-right: 10px;
}

.voip-calls .custom-buttons-window {
  background: rgba(3, 192, 252, 0.2);
  border-radius: 6px;
  margin: 10px 0;
  padding: 10px;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    height: 70%;
    margin-top: 5px;
}
.content-wrapper .additonal-description {
    font-size: 11px;
    margin-bottom: 25px;
}
.content-wrapper .content {
    display: flex;
    justify-content: initial;
    height: 100%;
    align-items: initial;
    overflow-y: scroll;
    max-height: 100%;
    /* max-height: calc(262px - 160px); */
}

.window .content {
  overflow: auto;
}

.content table {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    width: 100%;
}
.content table th:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.content table th:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.content table th {
    background: #EDEFF5;
    text-align: center;
    height: 35px;

    top: 0;
    position: sticky;

    cursor: pointer;
}
.content table tr td {
    text-align: center;
    height: 19px;
}
.content table th,
.content table tr td {
    width: 7%;
}
.content table th:nth-child(5),
.content table tr td:nth-child(5) {
  width: 27%;
}
.content table th:nth-child(6),
.content table tr td:nth-child(6) {
    /* width: 120px; */
    width: 20%;
}

.content table td.bottom-border {
    border-bottom: 1px solid #C4C4C4;
}
.content table th .order-button {
    display: flex;
    flex-direction: column;
    width: 10px;
    margin: 0;
    align-items: center;
}
.content table th .container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}
.content table th .order-button .order-icon {
  display: flex;
  text-indent: -9999px;
  width: 5px;
  height: 9px;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url('../../../assets/arrow_icon.svg');
  mask-image: url('../../../assets/arrow_icon.svg');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 5px 9px;
  mask-size: 5px 9px;
  background-color: #000;
}

.content table th .order-button.asc .order-icon.order-asc,
.content table th .order-button.desc .order-icon.order-desc {
    background-color: #03C0FC;
}

.content table th .order-button .order-icon.order-asc {
  transform: rotate(270deg);
}

.content table tr {
    cursor: pointer;
}

.content table tr:hover {
    background: rgba(3, 192, 252, 0.24);
}

.content table th .order-button .order-icon.order-desc {
  transform: rotate(90deg);
}

.content .dialog-buttons button {
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .voip-calls {
        max-height: 330px;
    }
    .voip-calls .content-wrapper {
        margin-top: 10px;
        margin-bottom: 10px;
        max-height: calc(327px - 140px);
    }
    .voip-calls .title-section {
        flex-direction: column;
    }
    .voip-calls .dialog-buttons {
        flex-direction: column;
        align-items: flex-end;
    }
    .voip-calls .dialog-buttons .custom-buttons {
        margin-bottom: 10px;
    }
}
</style>
