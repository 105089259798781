const dialogList = [
    {label: 'Follow stream', value: 'FollowUDPStream'},
    // {label: 'Follow SSL', value: 'ssl'},
    // {label: 'Follow HTTP', value: 'http'},
    {label: 'Ladder diagrams', value: 'ProtocolLadderView'},
    {label: 'Network endpoints', value: 'NetworkEndpoints'},
    {label: 'GeoIP World Map', value: 'GeoIPWorldMap'},
    {label: 'Protocol Conversations', value: 'ProtocolConversations', query: 'proto=eth'},
    {label: 'Protocol Hierarchy', value: 'ProtocolHierarchyStats'},
    {label: 'Packet Lengths', value: 'PacketLengths'},
    // {label: 'DNS Activity', value: 'DNSActivity'},
    {label: 'VoIP Calls', value: 'VoIPCalls'},
    {label: 'RTP Streams', value: 'RTPStreams'},
    // {label: 'HTTP Analysis', value: 'HTTPAnalysis'},
    // {label: 'Wireless Networks', value: 'WirelessNetworks'},
    // {label: 'Threat Assessment', value: 'ThreatAssesment'},
    // {label: 'Zeek Logs', value: 'ZeekLogs'}
];

export default dialogList;