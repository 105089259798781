<template>
  <div 
    class="audio-pane"
  >
    <div
      class="call-list"
    >
      <CallDetails
        v-for="(call, indx) in callsList"
        v-bind:key="indx + 'calls-list-item'"
        v-bind:callDetails="call"
        v-bind:callN="indx"
        v-bind:muted="muted"
        v-bind:muteChannel="muteChannel"
        v-bind:audioLinks="audioLinks.dialogInternal && audioLinks.dialogInternal[indx + 1]"
        v-bind:selectCallOption="selectCallOption"
       />
    </div>
    <div class="play-track">
      <div
        class="time-tracker"
        v-bind:style="{transform: audioStreamPos}"
        v-on:mousedown="dragTrackerStart"
        v-on:mousemove="dragTrackerMove" 
        v-on:mouseup="dragTrackerStop"
      ></div>
      <WaveForm
        v-for="(call, indx) in callsList"
        v-bind:key="indx + 'calls-list-item'"
        v-bind:callDetails="call"
        v-bind:callN="indx"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CallDetails from './CallDetails';
import WaveForm from './WaveForm';

export default {
  name: 'AudioPanel',
  components: {
    CallDetails,
    WaveForm
  },
  props:{
    callsList: Array,
    trackControl: Function,
    audioStream: String,
    muted: Object,
    muteChannel: Function,
    selectCallOption: Function
  },
  data() {
    return {
      audioStreamPos: this.audioStream,
      dragged: false,
      pointer: 0,
      formattedPointer: 0,
      maxRight: 0
    }
  },
  computed: {
    ...mapState({
      audioLinks: state => state.packets.dialogInternal,
    }),
  },
  watch: {
    audioStream(newValue) {
      this.audioStreamPos = newValue;
    }
  },
  methods: {
    dragTrackerStart(ev){
      const container = document.querySelector('.play-track');
      this.formattedPointer = container.getBoundingClientRect().left;
      this.maxRight = container.offsetWidth - 3;
      this.dragged = true;
      this.audioStreamPos = `translateX(${ev.clientX - this.formattedPointer}px)`;
      this.$el.addEventListener('mousemove', this.dragTrackerMove, false);
      this.$el.addEventListener('mouseup', this.dragTrackerStop, false);
    },
    dragTrackerMove(ev) {
      if (this.dragged) {
        if (((ev.clientX - this.formattedPointer) >= 0) &&
            ((ev.clientX - this.formattedPointer) < this.maxRight)) {

          this.audioStreamPos = `translateX(${ev.clientX - this.formattedPointer}px)`;
        }
      }
    }, 
    dragTrackerStop(ev) {
        if (this.dragged) {
          this.trackControl(ev.clientX - this.formattedPointer);
        } 
        this.dragged = false;
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.audio-pane {
  /* margin-top: -30px; */
  margin-top: 20px;
}
.call-list {
  width: 200px;
  float: left;
}
.call-list .call-details {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #999;
  height: 120px;
  justify-content: space-between;
  padding: 0 5%;
}
.play-track {
  width: calc(100% - 200px);
  float: right;
}
.time-tracker {
  width: 3px;
  height: 100%;
  background: rgba(205,0,0,.3);
  position: absolute;
  cursor: col-resize;
  top: 0;
}
</style>
