<template>
    <div class="dialog-body network-endpoints">

      <div class="dialog-header">

        <div class="title-section">

          <div class="additional-header">
              <div class="additional-title">
                  Viewing {{viewCount}}
                  <div 
                    class="dropdown"
                    v-on:click="openList"
                    v-bind:class="{
                      active: listIsOpened,
                      unactive: !listIsOpened
                    }"
                  >
                    <span>{{allOptions[selectedOption]}}</span>
                    <span class="icon"></span>

                    <div class="choice-list">
                      <div 
                        v-for="(option, indx) in allOptions"
                        v-bind:key="option + 'prt_convs'"
                        v-bind:option="option"
                        v-on:click="selectOption(option, indx)"
                        class="option"
                      >{{option}}</div>
                    </div>
                  </div>
              </div>
              
          </div>

          <div class="header-title">
            <span class="title-text">{{title}}</span>
            <span class="file-name">{{filename}}</span>
          </div>

        </div>

        <div class="close-section">
          <button
            class="close-dialog"
            v-on:click="closeDialog"
            :ref="'close'"
          >
            <span></span>
          </button>
        </div>

      </div>

      <div class="content-wrapper">
          <div class="content">
            <table cellspacing="0" >
                <thead  >
                    <tr   style="text-align: left;">
                        <th
                            v-for="(tableHeader, ind) in tableHeaders"
                            v-bind:key="tableHeader.value + ind"
                            v-bind="tableHeader"
                            v-bind:class="tableHeader.customClass"
                            v-on:click="sortOrder(tableHeader, ind)">
                            <div class="container">
                                {{tableHeader.label}}
                                <div 
                                class="order-button"
                                v-bind:class="{
                                    asc: (tableHeader.value === currentHeader.value) 
                                        && currentHeader.order === 'asc',
                                    desc: (tableHeader.value === currentHeader.value) 
                                        && currentHeader.order === 'desc'}"
                                >
                                    <span class="order-icon order-asc"></span>
                                    <span class="order-icon order-desc"></span>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody tabindex="0" class="show-annotations">
                    <tr
                      v-for="(entry, ind) in dataList"
                      v-bind:key="'entry_' + ind"
                      v-bind="entry"
                      v-on:click="searchByQuery(entry.sourceIp)"
                    >
                        <td class="no-both-border address-column" >{{entry.sourceIp}}</td>
                        <td   class="no-right-border" >{{entry.totalFrames}}</td>
                        <td    class="no-both-border" >{{entry.totalBytes}}</td>
                        <td   class="no-right-border" >{{entry.sentFrames}}</td>
                        <td    class="no-both-border" >{{entry.receivedFrames}}</td>
                        <td   class="no-right-border" >{{entry.sentBytes}}</td>
                        <td    class="no-both-border"  >{{entry.receivedBytes}}</td>
                        <td   class="no-right-border"  >{{entry.country}}</td>
                        <td  class="no-both-border"  >{{entry.city}}</td>
                        <td    class="no-both-border"  >{{entry.asNumber}}</td>
                        <td  class="no-right-border"  >{{entry.asOrganization}}</td>
                        <td    class="no-both-border"  >{{entry.latitude}}</td>
                        <td  class="no-both-border"   >{{entry.longitude}}</td>
                    </tr>
                </tbody>
            </table>
          </div>
      </div>

      <div class="dialog-buttons">

        <div class="custom-buttons">
          <button
            class="open-geoipmap"
            v-on:click="openGeoIPMap"
          >
            <span>GeoIP Map</span>
          </button>
        </div>

        <div class="default-buttons">

          <button
            class="new-window"
            v-on:click="openNewWindow"
          >
            <span>Open In New Window</span>
          </button>
          <button 
            class="done"
            v-on:click="closeDialog"
          >
            <span>Done</span>
          </button>
          
        </div>
      </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'; //mapGetters, 

export default {
  name: 'NetworkEndpoints',
  props: {
    closeDialog: Function,
    fileName: String
  },
  data () {
    return {
        title: 'Endpoints for',
        filename: this.fileName || 'voip-extension.pcap',
        dataList: [],
        tableHeaders: [
            {
                value: 'address',
                customClass: 'no-both-border',
                label: 'Address',
            },
            {
                value: 'packets',
                customClass: 'no-right-border',
                label: 'Packets',
            },
            {
                value: 'bytes',
                customClass: 'no-both-border',
                label: 'Bytes',
            },
            {
                value: 'tx-packets',
                customClass: 'no-right-border',
                label: 'TX Packets'
            },
            {
                value: 'rx-packets',
                customClass: 'no-both-border',
                label: 'RX Packets'
            },
            {
                value: 'tx-bytes',
                customClass: 'no-right-border',
                label: 'TX Bytes'
            },
            {
                value: 'rx-bytes',
                customClass: 'no-both-border',
                label: 'RX Bytes'
            },
            {
                value: 'country',
                customClass: 'no-right-border',
                label: 'Country'
            },
            {
                value: 'city',
                customClass: 'no-both-border',
                label: 'City'
            },
            {
                value: 'as-number',
                customClass: 'no-both-border',
                label: 'AS Number'
            },
            {
                value: 'as-organization',
                customClass: 'no-right-border',
                label: 'AS Organization'
            },
            {
                value: 'latitude',
                customClass: 'no-both-border',
                label: 'Latitude'
            },
            {
                value: 'longitude',
                customClass: 'no-both-border',
                label: 'Longitude'
            }
        ],
        currentHeader: {},
        viewCount: 0,
        listIsOpened: false,
        selectedOption: 1,
        allOptions: ['eth','ipv4','ipv6','tcp','udp'],
        isLadder: false
    }
  },
  computed: {
    ...mapState({
      dialogContent: state => state.packets.dialogContent,
      currentDialog: state => state.views.currentDialog,
      uuid: state => state.packets.uuid
    }),
    dialogContentProps() {
      let listedProps = this.dialogContent && 
          Object.entries(this.dialogContent);
      return listedProps[0] && listedProps[0][1];
    },
  },
  watch: {
      dialogContentProps(newValue, oldValue) {
          console.log(newValue, oldValue);
        if (newValue) {
          this.dataList = newValue;
          this.viewCount = newValue.length;
        }
      },
      dialogContent(newValue) {
        this.isLadder = newValue.isLadder;
      }
  },
  methods: {
    sortOrder(hd, ind) {
        console.log(hd.value, ind);
        this.currentHeader = {
            ...hd, 
            order: this.currentHeader.order ? 
            (this.currentHeader.order === 'asc' ? 'desc' : '') : 'asc'
        };
        console.log(this.currentHeader.value, this.currentHeader.order);
    },
    openList () {
      this.listIsOpened = !this.listIsOpened;
    },
    selectOption(val, indx) {
        let query = `proto=${val}`;
        this.selectedOption = indx;
        
        this.openDialog({value: this.$options.name, query});
    },
    ...mapActions({
      openDialog: 'packets/loadDialogData',
      toggleWindow: 'views/toggleWindow',
      toggleDiagram: 'views/toggleDiagram',
      loadPackets: 'packets/getPacketsList',
      openInternalDialog: 'packets/dialogInternalRequest',
    }),
    openNewWindow() {
      let routeData = this.uuid && this.$router.resolve({path: `window/${this.uuid}/${this.$options.name}`}); //query: {searchField: this.searchField}
      this.uuid && window.open(routeData.href, '_blank');
      this.toggleWindow({value: this.$options.name});
      setTimeout(() => this.$refs.close.click(), 3000);
    },
    searchByQuery(ip) {
      if (this.isLadder) {
        console.log('isLadder');
        let ladderSearch = `ip.addr == ${ip}`;

        this.loadPackets({filter: ladderSearch});
        this.closeDialog('ProtocolHierarchyStats');
        this.toggleDiagram({switcher: true, searchQuery: ladderSearch});
        let routeData = this.uuid && this.$router.resolve({path: `/analysis/${this.uuid}/ladder`, query: {searchField: ladderSearch}});
        this.uuid && window.open(routeData.href, '_blank');
      
      }
    },
    openGeoIPMap() {
      this.openDialog({value: 'GeoIPWorldMap'});
    }
  },
  mounted() {
    if (this.dialogContentProps && this.dialogContentProps.length > 0) {
      this.dataList = this.dialogContentProps;
      this.viewCount = this.dialogContentProps.length;
    }
    if (this.$router.history.current.name === 'window') {
      this.toggleWindow({value: this.$options.name});
    }
  }
}
</script>

<style scoped>
.network-endpoints {
  max-width: 90vw;
  max-height: 300px;
  width: 100%;
  height: 100%;
}

.network-endpoints .header-title {
    display: flex;
    align-items: center;
}

.network-endpoints .additional-header {
    margin-right: 10px;
}

.network-endpoints .additional-header .additional-title {
    display: flex;
    align-items: center;
    position: relative;
}

.network-endpoints .additional-header .dropdown {
    border: 0.5px solid #000;
    border-radius: 6px;
    background: none;
    width: max-content;
    padding: 5px 8px 6px;
    font-size: 11px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 10px;
}

.network-endpoints .additional-header .dropdown .icon {
    display: flex;
    text-indent: -9999px;
    width: 8px;
    height: 8px;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-image: url(/../assets/chevron_down.svg);
    mask-image: url(/../assets/chevron_down.svg);
    -webkit-mask-size: 8px 8px;
    mask-size: 8px 8px;
    background-color: #000;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}

.network-endpoints .dropdown .choice-list {
    display: none;

    flex-direction: column;
    align-items: flex-start;

    position: absolute;
    top: 30px;
    background: #fff;
    width: max-content;
    border-radius: 6px;
    border: 0.5px solid #000;
    color: #000;
    z-index: 10;
    height: 0;
    opacity: 0;

    max-height: 125px;
    overflow: scroll;
}
.network-endpoints .dropdown.active .choice-list {
    display: flex;
    animation: listexpand 0.3s linear forwards;
}
.network-endpoints .dropdown.unactive .choice-list {
    display: flex;
    animation: listrelease 0.3s linear forwards;
}
.network-endpoints .dropdown .choice-list .option {
    padding: 4px 11px;
    font-size: 14px;
    width: calc(100% - (11px * 2));
    text-align: left;
    text-transform: capitalize;
}
.network-endpoints .dropdown .choice-list .option:hover {
  background: #d3d3d3;
}

.content table tr:hover {
  cursor: pointer;
  background: rgba(0,0,0,0.3);
}

.dialog-buttons button {
  cursor: pointer;
}

.custom-buttons {
  display: flex;
  /* width: calc(80% - 50px); */
  width: calc(100% - (257px + 25px));
}

.custom-buttons .open-geoipmap {
    border: 0.5px solid #03C0FC;
    border-radius: 6px;
    background: none;
    color: #03C0FC;
}

@keyframes listrelease {
    from {
        height: max-content;
        pointer-events: auto;
        opacity: 1;
    }
    to {
        height: 0;
        pointer-events: none;
        opacity: 0;
    }
}
@keyframes listexpand {
    from {
        height: 0;
        pointer-events: none;
        opacity: 0;
    }
    to {
        height: max-content;
        pointer-events: auto;
        opacity: 1;
    }
}

.network-endpoints .title-section {
    display: flex;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 5px;
    margin-bottom: 9px;
}

.content-wrapper .content {
    display: flex;
    justify-content: initial;
    height: 100%;
    align-items: initial;
    overflow: scroll;
    max-height: calc(296px - 89px);
}
.content-wrapper .content::-webkit-scrollbar-track {
  background: transparent;
}
.content table {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    width: 100%;
}
.content table .address-column {
    text-align: left;
}
.content table th:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.content table th:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.content table th {
    background: #EDEFF5;
    text-align: center;
    height: 35px;

    top: 0;
    position: sticky;

    cursor: pointer;
}
.content table tr td {
    text-align: center;
    height: 19px;
}
.content table th,
.content table tr td {
    width: 8%;
}
.content table th.no-right-border,
.content table td.no-right-border {
    border-left: 1px solid #C4C4C4;
    border-right: none;
    border-bottom: 1px solid #C4C4C4;
}
.content table th.no-both-border,
.content table td.no-both-border {
    border: none;
    border-bottom: 1px solid #C4C4C4;
}
.content table th .order-button {
    display: flex;
    flex-direction: column;
    width: 10px;
    margin: 0;
    align-items: center;
}
.content table th .container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}
.content table th .order-button .order-icon {
  display: flex;
  text-indent: -9999px;
  width: 5px;
  height: 9px;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url('../../../assets/arrow_icon.svg');
  mask-image: url('../../../assets/arrow_icon.svg');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 5px 9px;
  mask-size: 5px 9px;
  background-color: #000;
}

.content table th .order-button.asc .order-icon.order-asc,
.content table th .order-button.desc .order-icon.order-desc {
    background-color: #03C0FC;
}

.content table th .order-button .order-icon.order-asc {
  transform: rotate(270deg);
}

.content table th .order-button .order-icon.order-desc {
  transform: rotate(90deg);
}
@media screen and (min-width: 800px) {
    .network-endpoints .content-wrapper .content {
        overflow-x: auto;
    }
}
@media screen and (max-width: 767px) {
    .network-endpoints .content-wrapper {
        max-height: 150px;
        margin-top: 15px;
        margin-bottom: 20px;
    }
    .network-endpoints .title-section {
        flex-direction: column;
    }
}
</style>
