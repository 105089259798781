import Vue from 'vue'
import Vuex from 'vuex'
import views from './modules/views'
import packets from './modules/packets'
// import createLogger from './plugins/logger'
import createMultiTabState from 'vuex-multi-tab-state'

Vue.use(Vuex)

const debug = false//process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    views,
    packets,
  },
  plugins:[
    createMultiTabState({ 
      statesPaths: [
        'packets.uploadedFile', 'packets.uuid', 
        'packets.packetBytes', 'packets.dialogContent',
        //'packets.frame', 'packets.packetDetails', 
        'packets.packetList', 'packets.localFile', 'views.currentWindow',
        'views.diagramFlowOpened', //'views.ladderDiagram'
      ]
    }),
  ],
  strict: debug,
  // plugins: debug ? [createLogger()] : []
})