<template>
  <div class="audio-flow">
    <AudioControls
      v-bind:startPlaying="playAudio"
      v-bind:setToInitial="setToInitial"
      v-bind:audioStatus="audioStatus"
      v-bind:currentTrackTime="timeTracker"
     />
    <AudioPanel
      v-bind:trackControl="trackControl"
      v-bind:callsList="diagramData"
      v-bind:audioStream="audioStream"
      v-bind:muted="muted"
      v-bind:muteChannel="muteChannel"
      v-bind:selectCallOption="selectCallOption"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AudioControls from './AudioControls';
import AudioPanel from './AudioPanel';

export default {
  name: 'AudioFlow',
  components: {
    AudioControls,
    AudioPanel
  },
  props: {
    selectCallOption: Function
  },
  data() {
    return {
      audioElement: {},
      audioStatus: '',
      audioData: [],
      audioStream: '',
      timeTracker: 0.0,
      firstTrack: null,
      secondTrack: null,
      muted: {},
      mutedProps: '',
      trackPosition: 0.0
    }
  },
  computed: {
    ...mapState({
      diagramOpened: state => state.views.diagramFlowOpened,
      audioLinks: state => state.packets.dialogInternal,
    }),
    diagramData() {
      return this.diagramOpened && this.diagramOpened.data
    },
  },
  watch: {
    audioLinks(newValue) {
      this.audioData = newValue.dialogInternal;
      this.audioElement = newValue.dialogInternal && new Audio(newValue.dialogInternal[0].mp3);
      this.firstTrack = newValue.dialogInternal && new Audio(newValue.dialogInternal[1].mp3);
      this.secondTrack = newValue.dialogInternal && new Audio(newValue.dialogInternal[2].mp3);
      this.audioStatus = '';

      let parentContainer = document.querySelector('.play-track'),
        step, stepDOM;

      this.audioElement.addEventListener('canplay', () => {
        if (this.trackPosition > 0) {
          let forced = `${this.trackPosition.toFixed(6)}`;
          this.audioElement.currentTime = forced;
        }
      });

      this.audioElement.addEventListener("timeupdate", () => {
        step = this.audioElement.duration / 100;
        stepDOM = parentContainer.clientWidth / 100;

        this.audioStream = `translateX(${
          (this.audioElement.currentTime / step) * stepDOM
        }px)`;

        this.timeTracker = this.audioElement.currentTime;

        this.trackPosition = 0.0;

        if (this.audioElement.currentTime === this.audioElement.duration) {
          this.setToInitial();
        }
          
      });
    },
    muted(newValue) {

      if ((newValue.type === 'm' && newValue.call === 0) || 
      (newValue.type === 's' && newValue.call === 1)) {
        this.mutedProps = 'firstTrack';
        this.audioElement.muted = true;
      }

      if ((newValue.type === 'm' && newValue.call === 1) || 
      (newValue.type === 's' && newValue.call === 0)) {
        this.mutedProps = 'secondTrack';
        this.audioElement.muted = true;
      }

      if (!newValue.type) {
        this.mutedProps = '';
        this.audioElement.muted = false;
      }

    }
  },
  methods: {
    ...mapActions({
      loadAudio: 'packets/dialogInternalRequest',
    }),
    playAudio() {

      if (this.audioStatus === 'playing') {
        this.audioElement.pause();
        this.mutedProps && this[this.mutedProps].pause();
        this.audioStatus = 'paused'
      } else if (this.audioStatus === 'paused' || 
        this.audioStatus === '') {
        this.audioElement.play();
        this.mutedProps && this[this.mutedProps].play();
        this.audioStatus = 'playing';
      }

    },
    trackControl(val) {
      let parentContainer = document.querySelector('.play-track'),
          step = this.audioElement.duration / 100,
          stepDOM = parentContainer.clientWidth / 100,
          trackStep = (val / stepDOM) * step;
 
      this.audioElement.pause();
      this.trackPosition = trackStep;
    },
    setToInitial() {
      this.audioElement.pause();
      this.mutedProps && this[this.mutedProps].pause();
      this.audioElement.currentTime = 0.0;
      this.timeTracker = 0.0;
      this.audioStream = '';
      this.audioStatus = '';
    },
    muteChannel(callNum, muteType) {
      this.muted = (this.muted.call === callNum) ? 
        
        (this.muted.type === muteType ? 
          {} : {call: callNum, type: muteType}) :

        {call: callNum, type: muteType};
    }
  },
  mounted() {
    this.loadAudio(this.$options.name);
  }
}
</script>

<style scoped>
.audio-flow {
  min-height: 0;
}

</style>
