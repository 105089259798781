<template>
    <div class="dialog-body profile">

      <div class="dialog-header">

        <div class="title-section">

          <div class="header-title">
            <span class="title-text">{{title}}</span>
          </div>

        </div>

        <div class="close-section">
          <button
            class="close-dialog"
            v-on:click="closeDialog"
          >
            <span></span>
          </button>
        </div>

      </div>

      <div class="content-wrapper">
          <div class="content">
            Analysis Profiles control how a capture file is decoded and displayed. The profile is remembered along with this file so anybody with the link will see the packets the same way.
            <br />
            <br />
            Choose an existing profile or open the profile editor to customize it for just this file.
            <br />
            <br />
            You do not have write access to this file. If you would like to modify this capture file, please choose "Export" → "Create New Session" from the toolbar to copy the file into your account.

            <div class="additional-content">
                <span class="bold-text">Current Profile:</span> System Default
            </div>
          </div>
      </div>

      <div class="dialog-buttons">

        <div class="default-buttons">


          <div class="warn-description">
            <span class="icon"></span>
            <span>File is Read-Only - Changes will not be saved</span>
          </div>

          <button 
            class="done"
            v-on:click="closeDialog"
          >
            <span>Close</span>
          </button>
          
        </div>
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Profile',
  props: {
    closeDialog: Function,
    fileName: String
  },
  data () {
    return {
      title: 'Analysis Profile Switcher',
    }
  },
  computed: {
    ...mapState({
      dialogContent: state => state.packets.dialogContent,
    }),
  },
}
</script>

<style scoped>
.profile {
  max-width: 600px;
  max-height: 300px;
  width: 100%;
  height: 100%;
}

.profile .title-text {
    font-size: 16px;
    font-weight: 600;
}

.default-buttons {
    margin: 0 18px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.default-buttons .warn-description {
  color: #cd0a0a;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.default-buttons .warn-description .icon {
    width: 16px;
    height: 16px;
    display: flex;
    background-image: url('../../../assets/icons_set.png');
    background-position: -192px -96px;
}

.additional-content .bold-text {
  font-weight: 600;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 5px;
}

.content-wrapper .content {
    display: flex;
    justify-content: space-around;
    height: 100%;
    align-items: center;
    color: #000;

    flex-direction: column;
    align-items: flex-start;
}

@media screen and (max-width: 767px) {
    .additional-header {
        position: absolute;
        top: 65px;
    }
    .content-wrapper .content {
        height: 80%;
        margin-top: 5%;
    }
}
</style>
