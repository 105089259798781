var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dialog-body network-endpoints"},[_c('div',{staticClass:"dialog-header"},[_c('div',{staticClass:"title-section"},[_c('div',{staticClass:"additional-header"},[_c('div',{staticClass:"additional-title"},[_vm._v(" Viewing "+_vm._s(_vm.viewCount)+" "),_c('div',{staticClass:"dropdown",class:{
                  active: _vm.listIsOpened,
                  unactive: !_vm.listIsOpened
                },on:{"click":_vm.openList}},[_c('span',[_vm._v(_vm._s(_vm.allOptions[_vm.selectedOption]))]),_c('span',{staticClass:"icon"}),_c('div',{staticClass:"choice-list"},_vm._l((_vm.allOptions),function(option,indx){return _c('div',{key:option + 'prt_convs',staticClass:"option",attrs:{"option":option},on:{"click":function($event){return _vm.selectOption(option, indx)}}},[_vm._v(_vm._s(option))])}),0)])])]),_c('div',{staticClass:"header-title"},[_c('span',{staticClass:"title-text"},[_vm._v(_vm._s(_vm.title))]),_c('span',{staticClass:"file-name"},[_vm._v(_vm._s(_vm.filename))])])]),_c('div',{staticClass:"close-section"},[_c('button',{ref:'close',staticClass:"close-dialog",on:{"click":_vm.closeDialog}},[_c('span')])])]),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content"},[_c('table',{attrs:{"cellspacing":"0"}},[_c('thead',[_c('tr',{staticStyle:{"text-align":"left"}},_vm._l((_vm.tableHeaders),function(tableHeader,ind){return _c('th',_vm._b({key:tableHeader.value + ind,class:tableHeader.customClass,on:{"click":function($event){return _vm.sortOrder(tableHeader, ind)}}},'th',tableHeader,false),[_c('div',{staticClass:"container"},[_vm._v(" "+_vm._s(tableHeader.label)+" "),_c('div',{staticClass:"order-button",class:{
                                asc: (tableHeader.value === _vm.currentHeader.value) 
                                    && _vm.currentHeader.order === 'asc',
                                desc: (tableHeader.value === _vm.currentHeader.value) 
                                    && _vm.currentHeader.order === 'desc'}},[_c('span',{staticClass:"order-icon order-asc"}),_c('span',{staticClass:"order-icon order-desc"})])])])}),0)]),_c('tbody',{staticClass:"show-annotations",attrs:{"tabindex":"0"}},_vm._l((_vm.dataList),function(entry,ind){return _c('tr',_vm._b({key:'entry_' + ind,on:{"click":function($event){return _vm.searchByQuery(entry.sourceIp)}}},'tr',entry,false),[_c('td',{staticClass:"no-both-border address-column"},[_vm._v(_vm._s(entry.sourceIp))]),_c('td',{staticClass:"no-right-border"},[_vm._v(_vm._s(entry.totalFrames))]),_c('td',{staticClass:"no-both-border"},[_vm._v(_vm._s(entry.totalBytes))]),_c('td',{staticClass:"no-right-border"},[_vm._v(_vm._s(entry.sentFrames))]),_c('td',{staticClass:"no-both-border"},[_vm._v(_vm._s(entry.receivedFrames))]),_c('td',{staticClass:"no-right-border"},[_vm._v(_vm._s(entry.sentBytes))]),_c('td',{staticClass:"no-both-border"},[_vm._v(_vm._s(entry.receivedBytes))]),_c('td',{staticClass:"no-right-border"},[_vm._v(_vm._s(entry.country))]),_c('td',{staticClass:"no-both-border"},[_vm._v(_vm._s(entry.city))]),_c('td',{staticClass:"no-both-border"},[_vm._v(_vm._s(entry.asNumber))]),_c('td',{staticClass:"no-right-border"},[_vm._v(_vm._s(entry.asOrganization))]),_c('td',{staticClass:"no-both-border"},[_vm._v(_vm._s(entry.latitude))]),_c('td',{staticClass:"no-both-border"},[_vm._v(_vm._s(entry.longitude))])])}),0)])])]),_c('div',{staticClass:"dialog-buttons"},[_c('div',{staticClass:"custom-buttons"},[_c('button',{staticClass:"open-geoipmap",on:{"click":_vm.openGeoIPMap}},[_c('span',[_vm._v("GeoIP Map")])])]),_c('div',{staticClass:"default-buttons"},[_c('button',{staticClass:"new-window",on:{"click":_vm.openNewWindow}},[_c('span',[_vm._v("Open In New Window")])]),_c('button',{staticClass:"done",on:{"click":_vm.closeDialog}},[_c('span',[_vm._v("Done")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }