<template>
    <div class="dialog-body protocol-conversations">

      <div class="dialog-header">

        <div class="title-section">

          <div class="additional-header">
              <div class="additional-title">
                  Viewing {{viewCount}}
                  <div 
                    class="dropdown"
                    v-on:click="openList"
                    v-bind:class="{
                      active: listIsOpened,
                      unactive: !listIsOpened
                    }"
                  >
                    <span>{{allOptions[selectedOption]}}</span>
                    <span class="icon"></span>

                    <div class="choice-list">
                    <div 
                        v-for="(option, indx) in allOptions"
                        v-bind:key="option + 'prt_convs'"
                        v-bind:option="option"
                        v-on:click="selectOption(option, indx)"
                        class="option"
                    >{{option}}</div>
                    </div>
                  </div>
              </div>
              
          </div>

          <div class="header-title">
            <span class="title-text">{{title}}</span>
            <span class="file-name">{{filename}}</span>
          </div>

        </div>

        <div class="close-section">
          <button
            class="close-dialog"
            v-on:click="closeDialog"
            :ref="'close'"
          >
            <span></span>
          </button>
        </div>

      </div>

      <div class="content-wrapper">
          <div class="additonal-description">
              {{description}}
          </div>
          <div class="content">
            <table cellspacing="0" >
                <thead  >
                    <tr   style="text-align: left;">
                        <th
                            v-for="(tableHeader, ind) in tableHeaders"
                            v-bind:key="tableHeader.value + ind"
                            v-bind="tableHeader"
                            v-bind:class="tableHeader.customClass"
                            v-on:click="sortOrder(tableHeader, ind)">
                            <div class="container">
                                {{tableHeader.label}}
                                <div 
                                class="order-button"
                                v-bind:class="{
                                    asc: (tableHeader.value === currentHeader.value) 
                                        && currentHeader.order === 'asc',
                                    desc: (tableHeader.value === currentHeader.value) 
                                        && currentHeader.order === 'desc'}"
                                >
                                    <span class="order-icon order-asc"></span>
                                    <span class="order-icon order-desc"></span>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody tabindex="0" class="show-annotations">
                    <tr
                      v-for="(entry, ind) in entries"
                      v-bind:key="'entry_' + ind"
                      v-bind="entry"
                      v-on:click="searchByParams(entry)"
                      class="table-row"
                    >
                        <td   class="no-both-border" >{{entry.nodeA}}</td>
                        <td   class="no-both-border port-column" >{{entry.portA}}</td>
                        <td   class="no-both-border" >{{entry.nodeB}}</td>
                        <td   class="no-both-border port-column" >{{entry.portB}}</td>
                        <td    class="no-right-border" >{{entry.totalFrames}}</td>
                        <td   class="no-both-border" >{{entry.totalData}}</td>
                        <td    class="no-right-border" >{{entry.framesA}}</td>
                        <td   class="no-both-border" >{{entry.dataA}}</td>
                        <td    class="no-right-border"  >{{entry.framesB}}</td>
                        <td   class="no-both-border"  >{{entry.dataB}}</td>
                        <td  class="no-right-border"  >{{entry.relativeStart}}</td>
                        <td    class="no-right-border"  >{{entry.duration.toFixed(6)}}</td>
                        <td  class="no-right-border"  >{{ 
                          (parseFloat(entry.rateA / 1024) > 1) ?
                          `${parseFloat(entry.rateA / 1024).toFixed(1)} Kbits/s` : 
                          `${parseFloat(entry.rateA).toFixed(1)} bits/s`
                        }}</td>
                        <td class="no-both-border"  >{{ 
                          (parseFloat(entry.rateB / 1024) > 1) ?
                          `${parseFloat(entry.rateB / 1024).toFixed(1)} Kbits/s` : 
                          `${parseFloat(entry.rateB).toFixed(1)} bits/s`
                        }}</td>
                    </tr>
                </tbody>
            </table>
          </div>
      </div>

      <div class="dialog-buttons">

        <div class="default-buttons">

          <button
            class="new-window"
            v-on:click="openNewWindow"
          >
            <span>Open In New Window</span>
          </button>
          <button 
            class="done"
            v-on:click="closeDialog"
          >
            <span>Done</span>
          </button>
          
        </div>
      </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'; //mapGetters, 

export default {
  name: 'ProtocolConversations',
  props: {
    closeDialog: Function,
    fileName: String
  },
  data () {
    return {
        title: 'Conversations for',
        filename: this.fileName || 'voip-extension.pcap',
        description: 'Clicking on a row will apply a Display Filter for that conversation.',
        tableHeaders: [
            {
                value: 'node-a',
                customClass: 'no-both-border',
                label: 'Node A',
            },
            {
                value: 'port-a',
                customClass: 'no-both-border port-column',
                label: 'Port A',
            },
            {
                value: 'node-b',
                customClass: 'no-both-border',
                label: 'Node B',
            },
            {
                value: 'port-b',
                customClass: 'no-both-border port-column',
                label: 'Port B',
            },
            {
                value: 'total-frames',
                customClass: 'no-right-border',
                label: 'Total Frames',
            },
            {
                value: 'total-data',
                customClass: 'no-both-border',
                label: 'Total Data'
            },
            {
                value: 'frames-a-b',
                customClass: 'no-right-border',
                label: 'Frames A B'
            },
            {
                value: 'data-a-b',
                customClass: 'no-both-border',
                label: 'Data A B'
            },
            {
                value: 'frames-b-a',
                customClass: 'no-right-border',
                label: 'Frames B A'
            },
            {
                value: 'data-b-a',
                customClass: 'no-both-border',
                label: 'Data B A'
            },
            {
                value: 'relative-start',
                customClass: 'no-right-border',
                label: 'Relative Start'
            },
            {
                value: 'total-duration',
                customClass: 'no-right-border',
                label: 'Total Duration'
            },
            {
                value: 'rate-a-b',
                customClass: 'no-right-border',
                label: 'Rate A B'
            },
            {
                value: 'rate-b-a',
                customClass: 'no-both-border',
                label: 'Rate B A'
            }
        ],
        entries: [],
        currentHeader: {},
        viewCount: 2,
        listIsOpened: false,
        selectedOption: 0,
        allOptions: ['eth','ip','ipv6','tcp','udp'],
        isLadder: false
    }
  },
  computed: {
    ...mapState({
      dialogContent: state => state.packets.dialogContent,
      currentDialog: state => state.views.currentDialog,
      uuid: state => state.packets.uuid
    }),
    dialogContentProps() {
      let listedProps = this.dialogContent && 
          Object.entries(this.dialogContent);
      return listedProps[0] && listedProps[0][1];
    },
  },
  watch: {
      dialogContentProps(newValue, oldValue) {
          console.log(newValue, oldValue);
        if (newValue) {
          this.entries = newValue;
          this.viewCount = newValue.length;
          console.log(this.dialogContent[this.currentDialog])
        }
      },
      dialogContent(newValue) {
        this.isLadder = newValue.isLadder;
      },
      selectedOption(newValue) {
        const portColumn = document.querySelectorAll('.port-column');
        if (newValue === 1) {
          portColumn.forEach(pc => {
            pc.style.display = 'none';
          });
        } else {
          portColumn.forEach(pc => {
            pc.style.display = 'table-cell';
          });
        }
      }
  },
  methods: {
    sortOrder(hd, ind) {
        console.log(hd.value, ind);
        this.currentHeader = {
            ...hd, 
            order: this.currentHeader.order ? 
            (this.currentHeader.order === 'asc' ? 'desc' : '') : 'asc'
        };
        console.log(this.currentHeader.value, this.currentHeader.order);
    },
    openList () {
      this.listIsOpened = !this.listIsOpened;
    },
    selectOption(val, indx) {
        let query = `proto=${val}`;
        this.selectedOption = indx;
        
        this.openDialog({value: this.$options.name, query});
    },
    ...mapActions({
      openDialog: 'packets/loadDialogData',
      toggleWindow: 'views/toggleWindow',
      toggleDiagram: 'views/toggleDiagram',
      loadPackets: 'packets/getPacketsList',
    }),
    openNewWindow() {
      let routeData = this.uuid && this.$router.resolve({path: `window/${this.uuid}/${this.$options.name}`}); //query: {searchField: this.searchField}
      this.uuid && window.open(routeData.href, '_blank');
      this.toggleWindow({value: this.$options.name});
      setTimeout(() => this.$refs.close.click(), 3000);
    },
    searchByParams({nodeA, nodeB}) {
      let sourceIp = nodeA, 
        destinationip = nodeB;

      if (this.isLadder) {
        console.log('isLadder');
        let ladderSearch = ` (ip.src == ${sourceIp} && ip.dst == ${destinationip}) || (ip.src == ${destinationip} && ip.dst == ${sourceIp})`;

        this.loadPackets({filter: ladderSearch});
        this.closeDialog('ProtocolConversations');
        this.toggleDiagram({switcher: true, searchQuery: ladderSearch});
        let routeData = this.uuid && this.$router.resolve({path: `/analysis/${this.uuid}/ladder`, query: {searchField: ladderSearch}});
        this.uuid && window.open(routeData.href, '_blank');
      
      } else {
        let searchQuery = `(ip.src == ${sourceIp} && ip.dst == ${destinationip}) 
        || (ip.src == ${destinationip} && ip.dst == ${sourceIp})`;
        this.loadPackets({filter: searchQuery});
        this.$refs.close.click();
      }
    },
  },
  mounted() {
    this.selectedOption = 0;
    if (this.dialogContentProps && this.dialogContentProps.length > 0) {
      this.entries = this.dialogContentProps;
    }
    if (this.$router.history.current.name === 'window') {
      this.toggleWindow({value: this.$options.name});
    }
  }
}
</script>

<style scoped>
.protocol-conversations {
  max-width: 90vw;
  max-height: 330px;
  width: 100%;
  height: 100%;
}

.protocol-conversations .content-wrapper {
  height: 70%;
}

.protocol-conversations .content {
  overflow-y: scroll;
}

.protocol-conversations .header-title {
    display: flex;
    align-items: center;
}

.protocol-conversations .additional-header {
    margin-right: 10px;
}

.protocol-conversations .additional-header .additional-title {
    display: flex;
    align-items: center;
    position: relative;
}

.protocol-conversations .additional-header .dropdown {
    border: 0.5px solid #000;
    border-radius: 6px;
    background: none;
    width: max-content;
    padding: 5px 8px 6px;
    font-size: 11px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 10px;
}

.protocol-conversations .additional-header .dropdown .icon {
    display: flex;
    text-indent: -9999px;
    width: 8px;
    height: 8px;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-image: url(/../assets/chevron_down.svg);
    mask-image: url(/../assets/chevron_down.svg);
    -webkit-mask-size: 8px 8px;
    mask-size: 8px 8px;
    background-color: #000;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}

.table-row {
    cursor: pointer;
}
.table-row:hover {
  cursor: pointer;
  background: rgba(0,0,0,0.3);
}

.protocol-conversations .dropdown .choice-list {
    display: none;

    flex-direction: column;
    align-items: flex-start;

    position: absolute;
    top: 30px;
    background: #fff;
    width: max-content;
    border-radius: 6px;
    border: 0.5px solid #000;
    color: #000;
    z-index: 10;
    height: 0;
    opacity: 0;

    max-height: 125px;
    overflow: scroll;
}
.protocol-conversations .dropdown.active .choice-list {
    display: flex;
    animation: listexpand 0.3s linear forwards;
}
.protocol-conversations .dropdown.unactive .choice-list {
    display: flex;
    animation: listrelease 0.3s linear forwards;
}
.protocol-conversations .dropdown .choice-list .option {
    padding: 4px 11px;
    font-size: 14px;
    width: calc(100% - (11px * 2));
    text-align: left;
    text-transform: capitalize;
}
.protocol-conversations .dropdown .choice-list .option:hover {
  background: #d3d3d3;
}

@keyframes listrelease {
    from {
        height: max-content;
        pointer-events: auto;
        opacity: 1;
    }
    to {
        height: 0;
        pointer-events: none;
        opacity: 0;
    }
}
@keyframes listexpand {
    from {
        height: 0;
        pointer-events: none;
        opacity: 0;
    }
    to {
        height: max-content;
        pointer-events: auto;
        opacity: 1;
    }
}


.protocol-conversations .title-section {
    display: flex;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 5px;
}
.content-wrapper .additonal-description {
    font-size: 11px;
    margin-bottom: 25px;
}
.content-wrapper .content {
    display: flex;
    justify-content: initial;
    height: 100%;
    align-items: initial;
    /* overflow: scroll; */
    max-height: calc(590px - 170px);
}
.content table {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    width: 100%;
}
.content table th:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.content table th:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.content table th {
    background: #EDEFF5;
    text-align: center;
    height: 35px;

    top: 0;
    position: sticky;

    cursor: pointer;
}
.content table tr td {
    text-align: center;
    height: 19px;
}
.content table th,
.content table tr td {
    width: 7%;
}
/* .content table th:nth-child(1),
.content table tr td:nth-child(1),
.content table th:nth-child(3),
.content table tr td:nth-child(3) {
    width: 8%;
} */

.content table th.no-right-border,
.content table td.no-right-border {
    border-left: 1px solid #C4C4C4;
    border-right: none;
    border-bottom: 1px solid #C4C4C4;
}
.content table th.no-both-border,
.content table td.no-both-border {
    border: none;
    border-bottom: 1px solid #C4C4C4;
}
.content table th .order-button {
    display: flex;
    flex-direction: column;
    width: 10px;
    margin: 0;
    align-items: center;
}
.content table th .container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}
.content table th .order-button .order-icon {
  display: flex;
  text-indent: -9999px;
  width: 5px;
  height: 9px;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url('../../../assets/arrow_icon.svg');
  mask-image: url('../../../assets/arrow_icon.svg');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 5px 9px;
  mask-size: 5px 9px;
  background-color: #000;
}

.content table th .order-button.asc .order-icon.order-asc,
.content table th .order-button.desc .order-icon.order-desc {
    background-color: #03C0FC;
}

.content table th .order-button .order-icon.order-asc {
  transform: rotate(270deg);
}

.content table th .order-button .order-icon.order-desc {
  transform: rotate(90deg);
}

@media screen and (max-width: 767px) {
    .protocol-conversations {
        max-height: 327px;
    }
    .protocol-conversations .content-wrapper {
        margin-top: 15px;
        margin-bottom: 20px;
        max-height: calc(327px - 140px);
    }
    .protocol-conversations .title-section {
        flex-direction: column;
    }
}
</style>
