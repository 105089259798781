<template>
    <div class="dialog-body rtp-streams">

      <div class="dialog-header">

        <div class="title-section">

          <div class="header-title">
            <span class="title-text">{{this.rtpStreams.length}}&nbsp;{{title}}</span>
            <span class="file-name">{{filename}}</span>
          </div>

        </div>

        <div class="close-section">
          <button
            class="close-dialog"
            v-on:click="closeDialog"
            :ref="'close'"
          >
            <span></span>
          </button>
        </div>

      </div>

      <div class="content-wrapper">
          <div 
            class="content"
            v-if="rtpStreams.length > 0"
          >
            <div
              v-for="(stream, ind) in rtpStreams"
              v-bind:key="'stream' + ind"
              v-bind="stream"
              class="stream-row"
            >
                <div
                  class="stream-container"
                  v-bind:class="{active: streamWaveForm[ind], unactive: !streamWaveForm[ind]}"
                >
                  <div class="name-section">
                    <div
                      class="stream-name stream-label"
                      v-on:click="openListener(ind)"
                    >{{
                      streamWaveForm[ind] ? 'Hide Audio' : `Stream #${ind}`
                    }}</div>
                    <div
                      v-if="streamWaveForm[ind]"
                      class="play-audio"
                      v-bind:class="audioElement[ind].audioStatus"
                      v-on:click="playAudio(ind)"
                    ></div>
                    <div
                      v-else
                      class="stream-sound"
                      v-on:click="openListener(ind)"
                    ></div>
                  </div>

                  <div class="ssrc-section">
                    <div class="stream-from">
                        <div class="from-label stream-label">From</div>
                        <div class="from-value">{{stream.sourceIp}}:{{stream.sourcePort}}</div>
                    </div>
                    <div class="stream-to">
                        <div class="to-label stream-label">To</div>
                        <div class="to-value">{{stream.destinationIp}}:{{stream.destionationPort}}</div>
                    </div>
                    <div class="stream-ssrc">
                        <div class="ssrc-label stream-label">SSRC</div>
                        <div class="ssrc-value">{{stream.ssrc}}</div>
                    </div>
                  </div>

                  <div class="payload-section">
                    <div class="stream-start">
                        <div class="start-label stream-label">Start</div>
                        <div class="start-value">{{stream.start}}</div>
                    </div>
                    <div class="stream-duration">
                        <div class="duration-label stream-label">Duration</div>
                        <div class="duration-value">{{stream.duration}}</div>
                    </div>
                    <div class="stream-payload">
                        <div class="payload-label stream-label">Payload</div>
                        <div class="payload-value">{{stream.payload}}</div>
                    </div>
                  </div>

                  <div class="packets-lost-section">
                    <div class="stream-packets">
                        <div class="packets-label stream-label">Packets</div>
                        <div class="packets-value">{{stream.packets}}</div>
                    </div>

                    <div class="stream-lost">
                        <div class="lost-label stream-label">Lost</div>
                        <div class="lost-value">{{stream.lost}}</div>
                    </div>
                  </div>

                  <div class="max-delta-section">
                    <div class="stream-max-delta">
                        <div class="max-delta-label stream-label">Max Delta (MS)</div>
                        <div class="max-delta-chart">
                            <div class="chart-container">
                              <div class="chart-arrow"
                                :style="{
                                  transform: `rotate(${
                                    (Math.abs(stream.maxDelta * 2) - 50)
                                  }deg)`
                                }"
                              ></div>
                            </div>
                            <div class="chart-text">{{stream.maxDelta}} ms</div>
                        </div>
                    </div>
                  </div>

                  <div class="jitter-section">
                    <div class="stream-jitter">
                        <div class="jitter- label stream-label">Jitter max/mean</div>
                        <div class="jitter-chart">
                            {{stream.maxJitter}} / {{stream.meanJitter}}
                        </div>
                    </div>
                  </div>
                  <div class="wave-form">
                    <div
                      class="time-tracker"
                      v-bind:style="{transform: audioStreams[ind]}"
                    ></div>
                  </div>
               </div>

               <div class="shortcuts">
                   <div class="tools">
                      <span class="tools-label">Tools: </span>
                      <span 
                        class="tool-selection"
                        v-on:click="openGraph(null, stream, ind)"
                      >Bandwidth graph</span>
                      <span 
                        class="tool-selection"
                        v-on:click="displayFilter('only', stream)"
                      >Filter only</span>
                      <span 
                        class="tool-selection"
                        v-on:click="displayFilter('exclude', stream)"
                      >Filter exclude</span>
                   </div>

                   <div class="download">
                      <span class="downloads-label">Download as: </span>
                      <a :href="audioData[ind + 1] && audioData[ind + 1].raw" class="download-selection">raw</a>
                      <a :href="audioData[ind + 1] && audioData[ind + 1].wav" class="download-selection">wav</a>
                      <a :href="audioData[ind + 1] && audioData[ind + 1].mp3" class="download-selection">mp3</a>
                   </div>
               </div>
            </div>
          </div>

          <div 
            class="content"
            v-else
          >
            <div class="zero-length">
              <div class="header">There aren't any RTP streams in this file.</div>
              <div class="notes"><b>Note: </b> Some RTP streams need to be defined by setting 
              a specific "Decode Protocol As..." in the Analysis Tools section</div>
            </div>
          </div>
      </div>

      <div class="dialog-buttons">

        <div class="custom-buttons">

          <button 
            class="stream"
            v-on:click="openGraph('stream')"
          >
            <span>Bandwidth by Stream</span>
          </button>
          <button 
            class="sender"
            v-on:click="openGraph('sender')"
          >
            <span>Bandwidth by Sender</span>
          </button>
          
        </div>

        <div class="default-buttons">

          <button
            class="new-window"
            v-on:click="openNewWindow"
          >
            <span>Open In New Window</span>
          </button>
          <button 
            class="done"
            v-on:click="closeDialog"
          >
            <span>Done</span>
          </button>
          
        </div>
      </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'; //mapGetters, 

export default {
  name: 'RTPStreams',
  props: {
    closeDialog: Function,
    fileName: String
  },
  data () {
    return {
        title: 'RTP Streams found in ',
        filename: this.fileName || 'voip-extension.pcap',
        rtpStreams: [],
        rtpStreamsOld: [
            {
                name: 'Stream 1',
                sourceIp: '192.168.5.11:25426',
                sourcePort: "25426",
                destinationIp: '192.168.5.10:8000',
                destionationPort: "8000",
                ssrc: '0x6BD480AA',
                start: '0.264151',
                duration: '5.7600',
                payload: 'ITU-T G.711 PCMU',
                packets: '289pkts',
                lost: '0.0% / 0 pkts',
                maxDelta: '33.08',
                maxJitter: '3.71 ms',
                meanJitter: '1.55 ms',
                problems: false
            },
        ],
        audioElement: [],
        streamWaveForm: [],
        audioData: [],
        audioStreams: []
    }
  }
  ,
  computed: {
    ...mapState({
      dialogContent: state => state.packets.dialogContent,
      currentDialog: state => state.views.currentDialog,
      audioLinks: state => state.packets.dialogInternal,
      uuid: state => state.packets.uuid
    }),
    dialogContentProps() {
      let listedProps = this.dialogContent && 
          Object.entries(this.dialogContent);
      return listedProps[0] && listedProps[0][1];
    },
  },
  watch: {
      dialogContentProps(newValue, oldValue) {
          console.log(newValue, oldValue);
        if (newValue) {
          this.rtpStreams = newValue;
          this.rtpStreams.forEach((rs, ind) => {
            console.log(rs);
            this.$set(this.streamWaveForm,ind,false);
            this.$set(this.audioStreams, ind, '');
          });

          console.log(this.dialogContent[this.currentDialog])
        }
      },
      audioLinks(newValue) {
        console.log(newValue, 'audio links');
        this.audioData = newValue.dialogInternal;
        this.audioData.forEach((ad,indx) => {
          this.audioElement[indx] = {};
          this.audioElement[indx].media = new Audio(ad.mp3);
          this.audioElement[indx].audioStatus = '';
        });
      }
  },
  methods: {
    ...mapActions({
      openDialog: 'packets/loadDialogData',
      toggleWindow: 'views/toggleWindow',
      loadAudio: 'packets/dialogInternalRequest',
      loadPackets: 'packets/getPacketsList',
    }),
    openNewWindow() {
      let routeData = this.uuid && this.$router.resolve({path: `window/${this.uuid}/${this.$options.name}`}); //query: {searchField: this.searchField}
      this.uuid && window.open(routeData.href, '_blank');
      this.toggleWindow({value: this.$options.name});
      setTimeout(() => this.$refs.close.click(), 3000);
    },
    openListener(indx) {
      console.log(this.streamWaveForm);
      let isActive = this.streamWaveForm[indx];
      this.$set(this.streamWaveForm, indx, !isActive);
      // this.streamWaveForm.splice(indx, {active: !curState.active});
    },
    playAudio(indx) {
      let parentContainer = document.querySelector('.wave-form'),
        step, stepDOM;
        // timeTracker = document.querySelector('.time-tracker'),

      if (this.audioElement[indx].audioStatus === 'playing') {
        this.audioElement[indx].media.pause();
        // this.audioElement[indx].audioStatus = 'paused';
        this.$set(this.audioElement, indx, {...this.audioElement[indx], audioStatus: 'paused'});
      } else if (this.audioElement[indx].audioStatus === 'paused' || 
      this.audioElement[indx].audioStatus === '') {
        this.audioElement[indx].media.play();
        // this.audioElement[indx].audioStatus = 'playing';
        this.$set(this.audioElement, indx, {...this.audioElement[indx], audioStatus: 'playing'});
      }
      this.audioElement[indx].media.addEventListener("timeupdate", () => {
        step = this.audioElement[indx].media.duration / 100;
        stepDOM = parentContainer.clientWidth / 100;

        this.$set(this.audioStreams, indx, `translateX(${
          (this.audioElement[indx].media.currentTime / step) * stepDOM
        }px)`);

      });
    },
    displayFilter(type, stream) {
      let searchQuery = (type === 'only') ? 
      `ip.src==${stream.sourceIp} && udp.srcport==${stream.sourcePort} && ip.dst==${stream.destinationIp} && udp.dstport==${stream.destionationPort} && rtp.ssrc==${stream.ssrc}` :
      `!(ip.src==${stream.sourceIp} && udp.srcport==${stream.sourcePort} && ip.dst==${stream.destinationIp} && udp.dstport==${stream.destionationPort} && rtp.ssrc==${stream.ssrc})`
      this.loadPackets({filter: searchQuery});
      this.$refs.close.click();
    },
    openGraph(mode, stream, ind) {
      console.log(mode);
      let searchQuery = mode ? (mode === 'stream') ? {

        queries: [
          ...this.rtpStreams.map((rs, ind) => {
            return `ip.src==${
              rs.sourceIp
            } && udp.srcport==${
              rs.sourcePort
            } && ip.dst==${
              rs.destinationIp
            } && udp.dstport==${
              rs.destionationPort
            } && rtp.ssrc==${
              rs.ssrc
            } {Stream #${ind}}`
          })
        ],
        title: `Bandwidth by Stream`

      } : {
        queries: [
          ...this.rtpStreams.map(rs => {
            return `rtp && ip.src eq ${rs.sourceIp} {${rs.sourceIp}}`
          })
        ],
        title: `Bandwidth by Sender`
      } : 
      {
        queries: [
        `ip.src==${
          stream.sourceIp
        } && udp.srcport==${
          stream.sourcePort
        } && ip.dst==${
          stream.destinationIp
        } && udp.dstport==${
          stream.destionationPort
        } && rtp.ssrc==${
          stream.ssrc
        } {RTP Traffic}`],
        title: `RTP Stream #${ind}`
      }
      
      let routeData = this.uuid && this.$router.resolve({path: `window/${this.uuid}/GraphsStats/`});
      this.uuid && window.open(routeData.href, '_blank');
      this.toggleWindow({value: 'GraphsStats', mode: 'edit', data: searchQuery});
      setTimeout(() => this.$refs.close.click(), 3000);
    }
  },
  mounted() {
    this.loadAudio(this.$options.name);

    if (this.dialogContentProps && this.dialogContentProps.length > 0) {
      this.rtpStreams = this.dialogContentProps;
    }
    if (this.$router.history.current.name === 'window') {
      this.toggleWindow({value: this.$options.name});
    }
  },
}

</script>

<style scoped>
.rtp-streams {
  max-width: 90vw;
  max-height: 550px;
  width: 100%;
  height: 100%;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 5px;
}

.content-wrapper .content {
    display: flex;
    justify-content: center;
    height: max-content;
    align-items: center;
    overflow-y: scroll;
    flex-direction: column;
    max-height: calc(590px - 130px);
    margin-top: 20px;
}

.content .zero-length {
  width: 580px;
  text-align: center;
  margin-top: 20px;
}
.content .zero-length .header {
  color: #999;
  font-size: 32px;
  font-family: 'Roboto', sans-serif;
}
.content .zero-length .notes {
  color: #999;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  margin-top: 20px;
}

.stream-row {
    display: flex;
    width: 100%;
    height: 150px;
    flex-direction: column;
    margin-bottom: 50px;
    max-width: 680px;
}

.stream-row .stream-container {
    display: flex;
    height: 100%;
    max-height: 110px;
    font-size: 11px;
    /* min-width: 683px; */
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    width: max-content;
    overflow: hidden;
    position: relative;
}

.name-section {
    border: 1px solid #D9D9D9;
    border-right: none;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    width: 93px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.name-section .stream-sound,
.name-section .play-audio {
    display: flex;

    text-indent: -9999px;
    width: 18px;
    height: 18px;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-image: url('../../../assets/sound_icon.svg');
    mask-image: url('../../../assets/sound_icon.svg');
    -webkit-mask-size: 18px 18px;
    mask-size: 18px 18px;
    background-color: #03C0FC;
    mask-repeat: no-repeat;
    cursor: pointer;
}
.name-section .play-audio {
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-image: url('../../../assets/play-solid.svg');
    mask-image: url('../../../assets/play-solid.svg');
    -webkit-mask-size: 18px 18px;
    mask-size: 18px 18px;
    background-color: #03C0FC;
    mask-repeat: no-repeat;
}

.name-section .play-audio.paused {
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url('../../../assets/pause-solid.svg');
  mask-image: url('../../../assets/pause-solid.svg');
  -webkit-mask-size: 18px 18px;
  mask-size: 18px 18px;
  background-color: #03C0FC;
  mask-repeat: no-repeat;
}

.ssrc-section {
    display: flex;
    flex-direction: column;
    border: 1px solid #D9D9D9;
    width: 107px;
}

.ssrc-section .stream-from,
.ssrc-section .stream-to,
.ssrc-section .stream-ssrc,
.payload-section .stream-start,
.payload-section .stream-duration,
.payload-section .stream-payload {
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 5px;
    align-items: center;
    border-top: none;
    flex-grow: 1;
}

.ssrc-section .stream-ssrc,
.payload-section .stream-payload {
    border-bottom: none;
}

.payload-section {
    display: flex;
    flex-direction: column;
    border: 1px solid #D9D9D9;
    border-right: none;
    border-left: none;
    width: 103px;
}

.packets-lost-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 88px;
    border: 1px solid #D9D9D9;
}

.stream-packets,
.stream-lost {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    position: relative;
}
.stream-packets {
    border-bottom: 1px solid #D9D9D9;
}

.max-delta-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 153px;
    border: 1px solid #D9D9D9;
    position: relative;
    border-left: none;
}

.max-delta-chart {

}
.chart-container {
  display: flex;
  text-indent: -9999px;
  width: 120px;
  height: 110px;
  background: url('../../../assets/chart_sprite.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
  margin-bottom: 10px;
}
.chart-arrow {
  display: flex;
  text-indent: -9999px;
  width: 3px;
  height: 85px;
  background: url('../../../assets/scale_arrow_straight.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  transform: rotate(-40deg);
  transform-origin: bottom;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}
.chart-text {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 60px;
  bottom: 0;
  width: max-content;
  height: max-content;
  color: #666666;
  font-size: 16px;
  max-width: 50px;
  text-align: center;
}

.jitter-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 125px;
    border: 1px solid #D9D9D9;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    position: relative;
    border-left: none;
}

.stream-label {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(196,196,196,0.3);
    color: rgba(0,0,0,0.41);
    padding: 0 7px;
}

.stream-label.stream-name {
    cursor: pointer;
    padding: 4px 7px;
    border-radius: 7px;
}

.shortcuts {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}
.shortcuts .tool-selection,
.shortcuts .download-selection {
    color: #03C0FC;
    cursor: pointer;
}
.shortcuts .tool-selection:not(:last-child)::after,
.shortcuts .download-selection:not(:last-child)::after {
    content: "|";
    color: #03C0FC;
    margin: 0 5px;
}
.rtp-streams .dialog-buttons {
    justify-content: space-between;
    margin-left: 25px;
}
.rtp-streams .custom-buttons {
    display: flex;
    justify-content: space-between;
    width: 335px;
}
.rtp-streams .custom-buttons button {
    border: 0.5px solid #03C0FC;
    border-radius: 6px;
    background: none;
    color: #03C0FC;
    height: 35px;
    padding: 0px 15px;
}

.wave-form {
  position: absolute;
  width: calc(100% - 93px);
  height: 100%;
  background: rgb(3, 192, 252, 0.1);
  right: 0;
}
.time-tracker {
  width: 3px;
  height: 100%;
  background: rgba(218, 165, 32, 0.7);
}

.active .ssrc-section,
.active .payload-section,
.active .packets-lost-section,
.active .max-delta-section,
.active .jitter-section,
.unactive .wave-form {
  animation: slideactive 0.3s linear forwards;
}

.unactive .ssrc-section,
.unactive .payload-section,
.unactive .packets-lost-section,
.unactive .max-delta-section,
.unactive .jitter-section,
.active .wave-form {
  animation: slideunactive 0.3s linear forwards;
}

@keyframes slideactive {
    from {
      transform: translateX(0vw);
    }
    to {
      transform: translateX(70vw);
    }
}

@keyframes slideunactive {
    from {
      transform: translateX(70vw);
    }
    to {
      transform: translateX(0vw);
    }
}

@media screen and (max-width: 767px) {
    .content-wrapper .content {
        height: 80%;
        margin-top: 5%;
    }
    .rtp-streams .dialog-buttons {
        flex-direction: column;
        align-items: flex-end;
    }
    .rtp-streams .dialog-buttons .custom-buttons {
        margin-bottom: 10px;
    }
    .shortcuts {
        flex-direction: column;
    }
}
</style>