<template>
  <div 
    class="audio-controls"
  >
    <div class="control-buttons">
      <button
        class="backward-button"
        v-on:click="setToInitial"  
      >
        <span class="icon"></span>
      </button>
      <button 
        class="play-button"
        v-on:click="startPlaying"
        v-bind:class="audioStatus"
      >
        <span class="icon"></span>
        Play
      </button>
    </div>
    <div class="play-time">{{playTime}}</div>
  </div>
</template>

<script>
import * as moment from "moment/moment";

export default {
  name: 'AudioControls',
  components: {

  },
  props: {
    startPlaying: Function,
    setToInitial: Function,
    audioStatus: String,
    currentTrackTime: Number
  },
  data() {
      return {
        playTime: '00:00:00.000'
      }
  },
  methods: {
  },
  watch: {
    currentTrackTime(newValue) {
      let curTime = `${(newValue > 0) ? newValue : '0.000'}`;

      this.playTime = `${
        moment().startOf('day')
          .seconds(newValue)
          .format('HH:mm:ss')
      }.${curTime ? curTime.split('.')[1].slice(-3) : '000'}`;
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.audio-controls {
  /* position: absolute;
  width: 100%;
  top: 100%; */
  transform: translateY(-100%);
  display: flex;
  background: #eaf7ff;
  width: 250px;
  margin: auto;
  height: 50px;
  align-items: center;
  justify-content: space-around;
  border-radius: 6px;
  border: 1px solid #03C0FC;
  font-family: 'Roboto', sans-serif;
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
}
.audio-controls .control-buttons {
  display: flex;
}
.audio-controls .play-button,
.audio-controls .backward-button {
  border: 1px solid #03C0FC;
  color: #03C0FC;
  background: #fff;
  border-radius: 6px;
  cursor: pointer;
  padding: .4em 2.1em;
  font-family: 'Roboto', sans-serif;
  display: flex;
  align-items: center;
}

.audio-controls .backward-button {
  padding: .4em .8em;
  height: 28px;
}

.play-button .icon {
    display: flex;

    text-indent: -9999px;
    width: 8px;
    height: 8px;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-image: url('/../../assets/play-solid.svg');
    mask-image: url('/../../assets/play-solid.svg');
    -webkit-mask-size: 8px 8px;
    mask-size: 8px 8px;
    background-color: #03C0FC;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    margin-right: 5px;
}
.backward-button .icon {
    display: flex;

    text-indent: -9999px;
    width: 8px;
    height: 8px;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-image: url('/../../assets/step-backward-solid.svg');
    mask-image: url('/../../assets/step-backward-solid.svg');
    -webkit-mask-size: 8px 8px;
    mask-size: 8px 8px;
    background-color: #03C0FC;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}

.play-button.paused .icon {
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url('/../../assets/pause-solid.svg');
  mask-image: url('/../../assets/pause-solid.svg');
  -webkit-mask-size: 18px 18px;
  mask-size: 18px 18px;
  background-color: #03C0FC;
  mask-repeat: no-repeat;
}

</style>
