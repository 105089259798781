import { render, staticRenderFns } from "./RTPStreams.vue?vue&type=template&id=ca70499c&scoped=true&"
import script from "./RTPStreams.vue?vue&type=script&lang=js&"
export * from "./RTPStreams.vue?vue&type=script&lang=js&"
import style0 from "./RTPStreams.vue?vue&type=style&index=0&id=ca70499c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca70499c",
  null
  
)

export default component.exports