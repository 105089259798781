<template>
    <div class="filter-button search-filter">
        <input
            placeholder="Start typing a display filter" type="text"
            v-on:input="searchInsert"
            v-model="searchField"
        />
        <button
          class="apply"
          v-on:click="applySearch"
        >Apply</button>
        <button
          class="clear"
          v-on:click="clearSearch"
        >Clear</button>
    </div>
</template>

<script>
import {  mapActions } from 'vuex';

export default {
  name: 'SearchFilter',
  data() {
      return {
        searchField: ''
      }
  },
  methods: {
    searchInsert(ev) {
        this.searchField = ev.target.value;
    },
    applySearch() {
        console.log(this.searchField, ' request');
        this.loadPackets({filter: this.searchField});
    },
    clearSearch() {
        this.searchField = '';
        // this.removeAllPacketData();
        this.loadPackets({filter: ''});
    },
    ...mapActions({
      loadPackets: 'packets/getPacketsList',
      removeAllPacketData: 'packets/removeUploadedPacket'
    }),
  },
}
</script>

<style scoped>
.search-filter input {
    /* height: 35px; */
    padding: 0;
    border: none;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    /* width: 352px; */
    width: 16vw;
    text-indent: 14px;
}
/* .search-filter .apply {
    width: 73px;
} */
.search-filter .clear {
    border-left: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    /* width: 67px; */
}

@media screen and (max-width: 767px) {
    .search-filter input {
        width: 100%;
        max-width: 352px;
    }
}
</style>
