<template>
    <div 
      class="filter-button exports"
    >
        <button class="exports-button">
            Exports
            <!-- <span class="icon"></span> -->
        </button>
        <a :href="`${apiUrl}/${uuid}/download`"
          class="download-link"
        ></a>
    </div>
</template>

<script>
// import OptionsList from './shared-ui/OptionsList.vue'
import { mapState } from 'vuex'; //, mapGetters

export default {
  name: 'Exports',
  components: {
    // OptionsList
  },
  props: {
    // currentTab: String,
    // openTab: Function
  },
  data () {
    return {
        tabClass: 'exports',
        // openedOption: -1,
        apiUrl: 'http://192.99.10.113:8001/pcap'
        // graphsList: [
        //   {label: 'Download File', value: 'download_file'},
        //   {label: 'Create New Session', value: 'create_new_session'},
        // ]
    }
  },
  computed: {
    // tabIsOpened: function () {
    //   return this.currentTab === this.tabClass;
    // }
    ...mapState({
      uuid: state => state.packets.uuid
    }),
  },
  methods: {
    // ...mapActions({
    //     openDialog: 'packets/loadDialogData',
    //     openStaticDialog: 'views/toggleDialog'
    // }),
  }

}
</script>

<style scoped>
.exports .exports-button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    /* width: 90px; */
    border-radius: 6px;
}
.filter-button.exports .download-link {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
</style>
