<template>
  <div 
    class="panes"
    v-bind:class="{mobile: isMobile}"
  >
    <LeftPane v-if="isMobile" />
    <RightPane v-if="isMobile" />
    <div
      class="vertical-panel"
      v-else
    >
      <multipane class="resizable" layout="horizontal">
        <div :style="{ maxHeight: '190px' }"></div>
        <multipane-resizer id="hrz"></multipane-resizer>
          <div class="panes-layout">
            <multipane class="resizable" layout="vertical">
              <div :style="{ minWidth: '300px', width: '50%', maxHeight: '400px' }">
                <LeftPane />
              </div>
              <multipane-resizer id="vtc"></multipane-resizer>
              <div :style="{ minWidth: '300px', maxHeight: '400px' }">
                <RightPane />
              </div>
            </multipane>
          </div>
      </multipane>
    </div>
  </div>
</template>

<script>
import LeftPane from './LeftPane.vue';
import RightPane from './RightPane.vue';
import { Multipane, MultipaneResizer } from 'vue-multipane';

export default {
  name: 'Panes',
  components: {
    LeftPane,
    RightPane,
    Multipane,
    MultipaneResizer,
  },
  data() {
      return {
        isMobile: screen.width <= 768
      }
  },
  methods: {
    setScreenWidth(val) {
      this.isMobile = (val <= 768);
    }
  },
  mounted() {
    let self = this;
    window.addEventListener("resize", function () {
      self.setScreenWidth(document.documentElement.clientWidth);
    });
  }
}
</script>

<style>
/* .panes {
    width: 100%;
    height: 50%;
    margin-top: 100px;
}
.vertical-panel {
    position: absolute;
    width: 100%;
    padding-left: 4px;
    height: 50%;
    right: 0;
    background-color: #fff;
}

.vertical-panel::after {
    content: " ";
    background-color: #EDEFF5;
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    cursor: n-resize;
    z-index: 2
} */

.panes:not(.mobile) {
  position: absolute;
  width: 100%;
  top: 100%;
  transform: translateY(-100%);
}

.vertical-panel {
  height: 45vh;
  overflow: hidden;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  /* background: #fff; */
}

.multipane.resizable.layout-v .multipane-resizer {
  margin: 0; left: 0;
  background: #c8c8c8;
}

.multipane.resizable.layout-h .multipane-resizer {
  margin: 0; top: 0;
  background: #c8c8c8;
}
#hrz {
  height: 5px;
}

#vtc {
  height: 500px;
  width: 5px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.panes-layout {
  margin-left: 0!important;
  margin-right: 0!important;
  background: #fff;
}

.panes.mobile {
  width: 100vw;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}

.panes.mobile .left-panel,
.panes.mobile .right-panel {
  padding-left: 4px;
  height: 35vh;
  background-color: #fff;
  width: calc(100vw - 10px);
  overflow: scroll;
  position: static;
  padding: 0;
  padding-top: 20px;
  margin: auto;
}
.panes.mobile .left-panel {
  margin-bottom: 30px;
  white-space: nowrap;
  position: relative;
}
</style>
