var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left-panel no-scrollbar"},[(_vm.packetDetailsLoading)?_c('div',{staticClass:"loading-animated"}):_vm._l((_vm.tabsList),function(tab,index){return _c('div',_vm._b({key:tab + index,class:{
      active: index === _vm.tabIsOpened, 
      collapsible: tab.children
    },attrs:{"index":index}},'div',tab,false),[_c('div',{staticClass:"selector",on:{"click":function($event){return _vm.selectData($event, index, tab.name)}}},[_c('span',{staticClass:"arrow-icon"}),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v(_vm._s(tab.name))])]),(tab.children)?_c('div',{staticClass:"collapsible-lower"},_vm._l((tab.children),function(subtab,index){return _c('div',_vm._b({key:subtab + index,class:{
          active: index === _vm.subTabIsOpened, 
          collapsible: subtab.children
        },attrs:{"index":index}},'div',subtab,false),[_c('div',{staticClass:"selector",on:{"click":function($event){return _vm.selectData($event, index, subtab.name)}}},[(subtab.children)?_c('span',{staticClass:"arrow-icon"}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v(_vm._s(subtab.name))])]),(subtab.children)?_c('div',{staticClass:"collapsible-lower"},_vm._l((subtab.children),function(subsubtab,index){return _c('div',_vm._b({key:subsubtab + index,class:{
              active: index === _vm.subSubTabIsOpened, 
              collapsible: subsubtab.children
            },attrs:{"index":index}},'div',subsubtab,false),[_c('div',{staticClass:"selector",on:{"click":function($event){return _vm.selectData($event, index, subsubtab.name)}}},[(subsubtab.children)?_c('span',{staticClass:"arrow-icon"}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v(_vm._s(subsubtab.name))])]),(subsubtab.children)?_c('div',{staticClass:"collapsible-lower"},_vm._l((subsubtab.children),function(subsubsubtab,index){return _c('div',_vm._b({key:subsubsubtab + index,class:{
                  active: index === _vm.subSubSubTabIsOpened, 
                  collapsible: subsubsubtab.children
                },attrs:{"index":index}},'div',subsubsubtab,false),[_c('div',{staticClass:"selector",on:{"click":function($event){return _vm.selectData($event, index, subsubsubtab.name)}}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(subsubsubtab.name))])])])}),0):_vm._e()])}),0):_vm._e()])}),0):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }