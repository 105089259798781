<template>
    <div 
      class="filter-button analysis-tools"
      v-on:click="openTab(tabClass)"
      v-bind:class="{
        active: tabIsOpened,
        unactive: !tabIsOpened
      }"
    >
        <button class="analysis">
            Analysis tools
            <span class="icon"></span>
        </button>

        <OptionsList
            v-bind:optionsList="analysisToolsList"
        />
    </div>
</template>

<script>
import OptionsList from './shared-ui/OptionsList.vue';
import dialogList from '../../store/config';

export default {
  name: 'AnalysisTools',
  components: {
    OptionsList
  },
  props: {
    currentTab: String,
    openTab: Function
  },
  data () {
      return {
          tabClass: 'analysis-tools',
          openedOption: -1,
          analysisToolsList: dialogList
      }
  },
  computed: {
    tabIsOpened: function () {
      return (this.currentTab === this.tabClass);
    }
  }
}
</script>

<style scoped>
.analysis-tools .analysis {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    /* width: 131px; */
    border-radius: 6px;
}
</style>
