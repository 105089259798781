<template>
    <div class="dialog-body protocol-ladder">

      <div class="dialog-header">

        <div class="title-section">

          <div class="header-title">
            <!-- <span class="title-text">{{title}}</span>
            <span class="file-name">{{filename}}</span> -->
          </div>

        </div>

        <div class="close-section">
          <button
            class="close-dialog"
            v-on:click="closeDialog"
          >
            <span></span>
          </button>
        </div>

      </div>

      <div class="content-wrapper">
          <div class="content">
              <LadderView
                v-bind:isLadder="true"
                v-bind:closeDialog="closeDialog"
                v-bind:protocol="true"
                v-bind:fileName="fileName"
              />
          </div>
      </div>

      <div class="dialog-buttons">

        <div class="default-buttons">

          <button
            class="new-window"
            v-on:click="openNewWindow"
          >
            <span>Open In New Window</span>
          </button>
          <button 
            class="done"
            v-on:click="closeDialog"
          >
            <span>Done</span>
          </button>
          
        </div>
      </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'; //mapGetters, mapActions
import LadderView from '../LadderView';

export default {
  name: 'ProtocolLadderView',
  components: {
    LadderView
  },
  props: {
    closeDialog: Function,
    fileName: String
  },
  data () {
    return {
        // title: 'Protocol Ladder View: ',
        filename: this.fileName,
    }
  },
  computed: {
    ...mapState({
      dialogContent: state => state.packets.dialogContent,
      uuid: state => state.packets.uuid
    }),
  },
  methods: {
    ...mapActions({
      toggleDiagram: 'views/toggleDiagram',
    }),
    openNewWindow() {
      this.closeDialog('ProtocolLadderView');
      this.toggleDiagram({switcher: true, searchQuery: ''});
        //   console.log(this.$router);
      let routeData = this.uuid && this.$router.resolve({path: `/analysis/${this.uuid}/ladder`, query: {searchField: this.searchField}});
      this.uuid && window.open(routeData.href, '_blank');
    }
  }
}
</script>

<style scoped>
.protocol-ladder {
  max-width: 920px;
  max-height: 296px;
  width: 100%;
  height: 100%;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 5px;
}

.content-wrapper .content {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 767px) {
    .content-wrapper .content {
        height: 80%;
        margin-top: 5%;
    }
}
</style>
