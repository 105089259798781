<template>
  <div
    class="graph-toolbar"
  >

    <div class="editor-section">

        <button 
            class="graph-index"
            v-on:click="closeWindow"
            v-if="editMode"
        >
          <span>Cancel</span>
        </button>

        <button 
            class="graph-index"
            v-on:click="closeWindow"
            v-if="!editMode"
        >
        <span>Graph Index</span>
        </button>

        <button 
            class="open-editor"
            v-on:click="openEditor"
            v-if="!editMode"
        >
        <span>Open In Editor</span>
        </button>

    </div>

    <div class="graph-saving">

        <button 
            class="display-filter"
            v-on:click="applySearch"
        >
        <span>Apply As Display Filter</span>
        </button>

        <div
          class="export-image"
          v-bind:class="{
            active: dropDownIsOpened,
            unactive: !dropDownIsOpened
          }"
          v-on:click="openDropDown"
        >
            <span>Export As <span class="type">{{selectedImageOption}}</span></span>
            <span class="icon"></span>

            <div 
                class="choice-list"
            >
                <div 
                v-for="(option, indx) in imageOptions"
                v-bind:key="option + 'img_options'"
                v-bind:option="option"
                v-on:click="selectOption(option, indx)"
                class="option"
                >{{option}}</div>
            </div>

        </div>

        <button 
            class="print-graph"
            v-on:click="printGraph"
        >
        <span>Print</span>
        </button>

    </div>

  </div>
</template>

<script>


export default {
  name: 'GraphToolbar',
  props: {
    closeWindow: Function,
    exportChart: Function,
    printChart: Function,
    applySearch: Function,
    openEditor: Function,
    editMode: Boolean,
    selectedImageOption: String,
  },
  data () {
    return {
      imageOptions: ['PNG', 'JPG', 'SVG'],
      dropDownIsOpened: false
    }
  },
  computed: {

  },
  methods: {
    selectOption(opt) {
      this.exportChart(opt);
    },
    openDropDown() {
        this.dropDownIsOpened = !this.dropDownIsOpened;
    },
    printGraph() {
        this.printChart();
    }
  }
}

</script>

<style scoped>
  .graph-toolbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 8vh;
    background: rgba(3, 192, 252, 0.1);
    border-radius: 6px;
    align-items: center;
    margin: 20px 0;
  }
  .graph-toolbar .editor-section {
    display: flex;
  }
  .graph-toolbar .graph-saving {
    display: flex;
  }

  .graph-toolbar button,
  .graph-toolbar .export-image {
    border: 1px solid #03C0FC;
    color: #03C0FC;
    background: #fff;
    border-radius: 6px;
    padding: .4em 2.1em;
    font-size: 13px;
    cursor: pointer;
    height: max-content;
    margin: auto 10px;
  }

  .graph-toolbar .export-image {
    border-radius: 6px;
    background: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: max-content;
    /* padding: 5px 8px 6px; */
    font-size: 13px;
    margin-left: 5px;
    position: relative;
  }
  .graph-toolbar .export-image .type {
    width: 30px;
    display: inline-block;
  }
  .graph-toolbar .export-image .icon {
    display: flex;
    text-indent: -9999px;
    width: 8px;
    height: 8px;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-image: url('/../assets/chevron_down.svg');
    mask-image: url('/../assets/chevron_down.svg');
    -webkit-mask-size: 8px 8px;
    mask-size: 8px 8px;
    background-color: #03C0FC;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    margin-left: 5px;
  }
  .graph-toolbar .export-image.active .icon {
    transform: rotate(180deg);
  }
  .export-image {
    /* flex-basis: 50%; */
    padding: .4em 2.1em;
    width: auto;
    height: auto;
    margin-left: 10px;
    font-size: 13px;
    position: relative;
  }
  .export-image.active .choice-list {
    display: flex;
    animation: listexpand 0.3s linear forwards;
  }
  .export-image.unactive .choice-list {
    display: flex;
    animation: listrelease 0.3s linear forwards;
  }

  @keyframes listrelease {
    from {
        height: max-content;
        pointer-events: auto;
        opacity: 1;
    }
    to {
        height: 0;
        pointer-events: none;
        opacity: 0;
    }
  }
  @keyframes listexpand {
    from {
        height: 0;
        pointer-events: none;
        opacity: 0;
    }
    to {
        height: max-content;
        pointer-events: auto;
        opacity: 1;
    }
  }

  .export-image .choice-list {
    display: none;

    flex-direction: column;
    align-items: flex-start;

    position: absolute;
    top: 28px;
    right: 0;
    background: #fff;
    width: 50%;
    border-radius: 6px;
    border: 0.5px solid #03C0FC;
    color: #03C0FC;
    z-index: 10;
    height: 0;
    opacity: 0;

    max-height: 125px;
    overflow: scroll;
  }

  .export-image .choice-list .option {
    padding: 3px;
    width: 100%;
  }
  .export-image .choice-list .option:hover {
    background: #d3d3d3;
  }
</style>