<template>
    <div class="dialog-body profile"  :class="{window: isWindow}">

      <div class="dialog-header">

        <div class="title-section">

          <div class="header-title">
            <span class="title-text">{{title}}</span>
            <span class="file-name">{{uploadedFile.fileName}}</span>
          </div>

        </div>

        <div class="close-section">
          <button
            class="close-dialog"
            v-on:click="closeDialog"
            :ref="'close'"
          >
            <span></span>
          </button>
        </div>

      </div>

      <div class="content-wrapper">
          <div class="content">
            <div
              class="graph-link"
              v-on:click="openDialog({value: 'GraphsStats'})"
            >All Traffic</div>
            <p>
              Built-in Graph
              <br />
              Series: all traffic
              <br />
            </p>
            <div
              class="graph-link"
              v-on:click="openDialog({value: 'GraphsStats', query: 'filter='})"
            >Current Display Filter</div>
            <p>
              Built-in Graph
              <br />
              Series: current display filter
            </p>
          </div>

          <div
            v-if="isWindow"
            class="empty-container"
          >
            <div class="container-title">
              <span class="title-text">{{title}}</span>
              <span 
                class="file-name"
                v-on:click="closeDialog"
              >{{uploadedFile.fileName}}</span>
            </div>
            <div
              class="custom-buttons-window"
            >
              <button
                class="create-new"
                v-on:click="openNewGraph(true)"
              >
                <span>Create A New Graph</span>
              </button>
            </div>
            <div class="description">
              This capture file doesn't have any saved graphs yet.
            </div>
          </div>
      </div>

      <div class="dialog-buttons">

        <div class="custom-buttons">
          <button
            class="create-new"
            v-on:click="openNewGraph(false)"
          >
            <span>Create A New Graph</span>
          </button>
        </div>

        <div class="default-buttons">

          <button
            class="new-window"
            v-on:click="openNewWindow"
          >
            <span>Open In New Window</span>
          </button>

          <button 
            class="done"
            v-on:click="closeDialog"
          >
            <span>Close</span>
          </button>
          
        </div>
      </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'GraphsDialog',
  props: {
    closeDialog: Function,
    fileName: String,
    isWindow: Boolean
  },
  data () {
    return {
      title: 'CloudShark Graphs saved with ',
    }
  },
  computed: {
    ...mapState({
      dialogContent: state => state.packets.dialogContent,
      uuid: state => state.packets.uuid
    }),
    ...mapGetters('packets', {
      uploadedFile: 'uploadedFileFormat',
      dataSet: 'packetsFrequency',
    }),
  },
  methods: {
    ...mapActions({
      openDialog: 'packets/loadDialogData',
      toggleWindow: 'views/toggleWindow'
    }),
    openNewGraph(fromWindow) {
      let routeData = this.uuid && 
        fromWindow ?
        this.$router.replace({ path: `GraphsStats` }) :
        this.$router.resolve({path: `window/${this.uuid}/${'GraphsStats'}`}); //query: {searchField: this.searchField}
      
      (this.uuid && !fromWindow) && window.open(routeData.href, '_blank');
      this.toggleWindow({value: 'GraphsStats', mode: 'edit'});
      setTimeout(() => this.$refs.close.click(), 3000);
    },
    openNewWindow() {
      let routeData = this.uuid && 
      this.$router.resolve({path: `window/${this.uuid}/${this.$options.name}`}); //query: {searchField: this.searchField}
      this.uuid && window.open(routeData.href, '_blank');
      this.toggleWindow({value: this.$options.name});
      setTimeout(() => this.$refs.close.click(), 3000);
    }
  }
}
</script>

<style scoped>
.profile {
  max-width: 600px;
  max-height: 300px;
  width: 100%;
  height: 100%;
}

.profile .title-text {
    font-size: 16px;
    font-weight: 600;
}

.profile.window .dialog-header,
.profile.window .dialog-buttons,
.profile.window .content-wrapper .content {
  display: none;
}

.profile.window {
  max-width: none;
}

.profile.window .custom-buttons-window .create-new {
  border: 0.5px solid #03C0FC;
  border-radius: 6px;
  background: #fff;
  color: #03C0FC;
  cursor: pointer;
  padding: 9px;
}
.profile.window .custom-buttons-window {
  background: rgba(3, 192, 252, 0.2);
  border-radius: 6px;
  margin: 10px 0;
  padding: 10px;
}

.default-buttons {
    margin: 0 18px;
    display: flex;
    justify-content: space-between;
    width: 45%;
}

button {
  cursor: pointer;
}

.custom-buttons {
  display: flex;
  width: 45%;
}

.custom-buttons .create-new {
    border: 0.5px solid #03C0FC;
    border-radius: 6px;
    background: none;
    color: #03C0FC;
}

.default-buttons .warn-description {
  color: #cd0a0a;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.default-buttons .warn-description .icon {
    width: 16px;
    height: 16px;
    display: flex;
    background-image: url('../../../assets/icons_set.png');
    background-position: -192px -96px;
}

.additional-content .bold-text {
  font-weight: 600;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 5px;
}

.content-wrapper .content {
    display: flex;
    /* justify-content: space-around; */
    height: 100%;
    align-items: center;
    color: #000;

    flex-direction: column;
    align-items: flex-start;
}

.content-wrapper .content p {
  margin: 0;
  margin-left: 10px;
}

.content-wrapper .content .graph-link {
  color: #03C0FC;
  font-size: 16px;
  cursor: pointer;
  margin: 10px 0;
}

.container-title .title-text {
  font-weight: 400;
}

.container-title .file-name {
  font-size: 16px;
}

.empty-container .description {
  margin-top: 20px;
  font-size: 21px;
  color: #999;
}

@media screen and (max-width: 767px) {
    .additional-header {
        position: absolute;
        top: 65px;
    }
    .content-wrapper .content {
        height: 80%;
        margin-top: 5%;
    }
}
</style>
