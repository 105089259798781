<template>
  <div class="app-container">
    <UploadPanel v-if="!uuid && !errorResponse.status" />
    <ErrorPage v-if="!uuid && errorResponse.status" />
    <div
      v-if="uuid"
      class="main-header"
    >
      <a href="http://www.denovolab.com" class="main-logo"></a>
      <button
        v-on:click="uploadDirectly"
        class="upload-button-main"
      >Upload File</button>
    </div>
    <Toolset v-if="!openedWindowRoute && !openRoute" ref="toolset" />
    <TableStats v-if="!openedWindowRoute && !openRoute && uuid" />
    <Panes v-if="!openedWindowRoute && !openRoute && uuid" />
    <!-- <MainModal 
      v-if="!currentWindow.value || 
      (currentWindow.value && !openedWindowRoute)"
    /> -->
    <MainModal 
      v-if="!openedWindowRoute"
    />
    <LadderView
      v-bind:protocol="false"
      v-if="!openedWindowRoute && !openedFlow && openRoute "
    />
    <VoipFlow
      v-if="!openedWindowRoute && openedFlow"
      v-bind:fileName="uploadedFile.fileName"
    />
    <EntireWindow
      v-if="openedWindowRoute"
    />
  </div>
</template>

<script>
// import Header from './Header.vue';
import UploadPanel from './UploadPanel.vue';
import ErrorPage from './ErrorPage.vue';
import Toolset from './Toolset.vue';
import TableStats from './TableStats.vue';
import Panes from './datapanes/Panes.vue';
import MainModal from './dialogs/MainModal';
import EntireWindow from './dialogs/EntireWindow';
import LadderView from './dialogs/LadderView';
import VoipFlow from './dialogs/VoipFlow';
import {   mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'App',
  components: {
    // Header,
    UploadPanel,
    ErrorPage,
    Toolset,
    TableStats,
    Panes,
    MainModal,
    EntireWindow,
    LadderView,
    VoipFlow
  },
  data() {
    return {
       
    }
  },
  computed: {
    ...mapState({
      currentWindow: state => state.views.currentWindow,
      flowDiagram: state => state.views.diagramFlowOpened,
      ladderDiagram: state => state.views.diagramOpened,
      uuid: state => state.packets.uuid,
      errorResponse: state => state.packets.errorResponse
      // currentDialog: state => state.packets.currentDialog
    }),
    ...mapGetters('packets', {
      uploadedFile: 'uploadedFileFormat',
    }),
    openedWindowRoute() {
      return this.$router.history.current.params.window
    },
    openedFlow() {
      let voipFlow = 
        this.$router.history.current.path.split(`${this.$router.history.current.params.uuid}/`)[1];

        voipFlow = voipFlow && voipFlow.split("?")[0];

        let openedFlow = voipFlow === 'voip_flow';

      return openedFlow;
    },
    // currentDialogVal() {
    //   return this.currentDialog;
    // },
    openRoute() {
      console.log(this.$router.history);
      let newTab = (this.$router.history.current.name === 'window') ||
        (this.$router.history.current.name === 'ladder') ||
        (this.$router.history.current.name === 'flow');
      return newTab;
    }
  },
  watch: {
    // currentDialog(newValue) {
    //   console.log(newValue);
    // }
    openedFlow(newValue) {
      if (newValue) {
        document.querySelector('html').style.height = '100vh';
        document.querySelector('html').style.overflow = 'hidden';
        document.querySelector('body').style.overflow = 'auto';
      }
    },
    uuid(newValue) { //oldValue
      console.log(newValue);
      newValue && this.$router.push(newValue);
      // (newValue !== oldValue && !newValue) && this.$router.push('/');
    },
    errorResponse(newValue) {
      newValue && newValue.status && this.$router.push('/error');
    }
  },
  methods: {
    ...mapActions({
      uploadFile: 'packets/uploadAndGetUUID',
      getExistingFile: 'packets/getExistingFile'
    }),
    uploadDirectly() {
      this.$refs.toolset.$refs.toolsetheader.$refs.upload.click();
    }
  },
  mounted() {
    if (this.openedFlow) {
      document.querySelector('html').style.height = '100vh';
      document.querySelector('html').style.overflow = 'hidden';
      document.querySelector('body').style.overflow = 'auto';
    }
    if (
      !this.uuid &&
      !this.openRoute &&
      this.$router.history.current.params.uuid
    ) {
      this.getExistingFile(this.$router.history.current.params.uuid);
    }
    if (
      (this.uuid && this.$router.history.current.params.uuid) &&
      (this.uuid !== this.$router.history.current.params.uuid)
    ) {
      this.getExistingFile(this.$router.history.current.params.uuid);
    }
    window.onbeforeunload = function () {
      window.localStorage.clear();
      window.sessionStorage.clear();
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0;
  width: 100vw;
  height: 100vh;
}
.app-container {
  background: #F7F8F9;
  height: 100%;
}

.main-header {
  background: #F7F8F9;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-header .main-logo {
  background: url('../assets/logo/3-01.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 120px;
  height: 60px;
  margin-left: 20px;
}

.main-header .upload-button-main {
  cursor: pointer;
  border: 1px solid #03C0FC;
  color: #03C0FC;
  border-radius: 6px;
  background: #fff;
  padding: .8em 2.1em;
  margin-right: 20px;
}

.main-header #file-main {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

body::-webkit-scrollbar {
  width: 10px;
}

  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #b5b7b7;
  }

  ::-webkit-scrollbar-thumb {
    background: #606060;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  button:focus {
    outline: none;
  }
</style>
