<template>
    <div class="toolset-container">
      <ToolsetHeader ref="toolsetheader"  />
      <div class="toolset-content">
        <SearchFilter 
          v-bind:openTab="openTab"
          v-bind:currentTab="currentTab" />
        <!-- <SavedFilters 
          v-bind:openTab="openTab"
          v-bind:currentTab="currentTab" /> -->
        <RangeFilter 
          v-bind:openTab="openTab"
          v-bind:currentTab="currentTab" />
        <AnalysisTools 
          v-bind:openTab="openTab"
          v-bind:currentTab="currentTab" />
        <Graphs 
          v-bind:openTab="openTab"
          v-bind:currentTab="currentTab" />
        <Exports 
          v-bind:openTab="openTab"
          v-bind:currentTab="currentTab" />
        <Logout v-if="uuid" />
        <!-- <Profile 
          v-bind:openTab="openTab"
          v-bind:currentTab="currentTab" /> -->
        <div
          class="options-list-closer"
          v-if="currentTab.length > 0"
          v-on:click="openTab('')"
        ></div>
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ToolsetHeader from './toolset/ToolsetHeader';
import SearchFilter from './toolset/SearchFilter.vue';
// import SavedFilters from './toolset/SavedFilters.vue';
import RangeFilter from './toolset/RangeFilter.vue';
import AnalysisTools from './toolset/AnalysisTools.vue';
import Graphs from './toolset/Graphs.vue';
import Exports from './toolset/Exports.vue';
import Logout from './toolset/Logout.vue';
// import Profile from './toolset/Profile.vue';

export default {
  name: 'Toolset',
  components: {
    ToolsetHeader,
    SearchFilter,
    RangeFilter,
    // SavedFilters,
    AnalysisTools,
    Graphs,
    Exports,
    Logout
    // Profile
  },
  data() {
    return {
      currentTab: ''
    }
  },
  computed: {
    ...mapState({
      uuid: state => state.packets.uuid
    }),
  },
  methods: {
    openTab (openedTab) {
      this.currentTab = (this.currentTab === openedTab) ? '' : openedTab;
    }
  }
}
</script>

<style>

.toolset-content {
  display: flex;
  justify-content: space-between;
  width: 96vw;
  height: 6vh;
  margin: auto;
  margin-left: 2vw;
  flex-wrap: wrap;
  font-family: 'Roboto', sans-serif;
}
.toolset-content button {
  cursor: pointer;
}
.toolset-header .file-name {
  color: #000;
}
.toolset-header .file-size {
  color: rgba(0, 0, 0, 0.38);
}
.toolset-header .file-packets {
  color: rgba(0, 0, 0, 0.38);
}
.toolset-header .file-info {
  color: #03C0FC;
}
.filter-button {
  height: 35px;
  background: #fff;
  border-radius: 6px;
  display: flex;
  position: relative;
}
.filter-button button,
.filter-button .range-filter-controller {
  /* height: 35px; */
  border: 1px solid #03C0FC;
  color: #03C0FC;
  background: none;
}
.filter-button .icon {
    display: flex;

    text-indent: -9999px;
    width: 8px;
    height: 8px;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-image: url('/../assets/chevron_down.svg');
    mask-image: url('/../assets/chevron_down.svg');
    -webkit-mask-size: 8px 8px;
    mask-size: 8px 8px;
    background-color: #03C0FC;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    margin-left: 5px;
}
.filter-button.active .icon {
  transform: rotate(180deg);
}
.options-list-closer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 9;
}
.toolset-content .search-filter button,
.toolset-content .filters,
.toolset-content .analysis,
.toolset-content .graphs-button,
.toolset-content .exports-button,
.toolset-content .profile-button {
  padding: .4em 2.1em;
}
@media screen and (min-width: 1400px) {
  .toolset-content {
    justify-content: space-evenly;
    width: 96vw;
    margin-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .toolset-content {
    justify-content: flex-start;
    padding-left: 15px;
    width: calc(100% - 15px);
    font-family: 'Roboto', sans-serif;
    height: max-content;
  }
  .filter-button {
    margin: 0 15px 14px 0;
    height: 35px;
  }

}
/* @media screen and (min-width: 800px) {

  .search-filter input,
  .search-filter .apply,
  .search-filter .clear,
  .analysis-tools .analysis,
  .saved-filters .filters,
  .graphs .graphs-button,
  .exports .exports-button,
  .profile .profile-button {
    width: 100% !important;
  }
  .filter-button.search-filter {
    flex-basis: 32%;
  }
  .filter-button.search-filter .apply,
  .filter-button.search-filter .clear {
    flex-basis: 18%;
  }
  .filter-button.search-filter input {
    flex-basis: 64%;
  }
  .filter-button.analysis-tools {
    flex-basis: 14%;
  }
  .filter-button.saved-filters,
  .filter-button.graphs,
  .filter-button.exports,
  .filter-button.profile {
    flex-basis: 8%;
  }

  .filter-button button, .filter-button .range-filter-controller {
    font-size: 1.2vw;
  }
} */
</style>
