<template>
    <div class="dialog-body sip-stats">
        <div class="dialog-header">

        <div class="title-section">

          <div class="header-title">
            <span class="title-text">{{title}}</span>
            <span class="file-name">{{filename}}</span>
            <span>{{titlePart}}</span>
          </div>

        </div>

        <div class="close-section" :class="{'internal-close': internalDialog}">
          <button
            class="close-dialog"
            v-on:click="closeDialog"
            :ref="'close'"
          >
            <span></span>
          </button>
        </div>

      </div>

      <div class="content-wrapper">
          <div class="additonal-description">
            Click on a bar to filter the capture file to only those packets.
          </div>

          <div class="content">
            <div id="sip-stats-graph">

              <highcharts 
                class="hc hc-message-count" 
                :options="messageCountBar"
                ref="messageCount"></highcharts>

              <highcharts 
                class="hc hc-pie" 
                :options="pieChart"
                ref="pieChart"></highcharts>

              <highcharts 
                class="hc hc-status" 
                :options="statusCodeBar"
                ref="statusChart"></highcharts>
              
              <highcharts 
                class="hc hc-time" 
                :options="setupTimeBar"
                ref="setupTime"></highcharts>

            </div>
          </div>
      </div>

      <div class="dialog-buttons">

        <div class="default-buttons">

          <button
            class="new-window"
            v-on:click="openNewWindow"
          >
            <span>Open In New Window</span>
          </button>
          <button 
            class="done"
            v-on:click="closeDialog"
          >
            <span>Done</span>
          </button>
          
        </div>
      </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'; //mapGetters, 

export default {
  name: 'SIPStats',
  props: {
    closeDialog: Function,
    fileName: String,
    internalDialog: Boolean
  },
  data () {
    return {
        title: 'SIP Stats for',
        titlePart: '// All calls',
        filename: this.fileName,
        dataSetPie: [],
        countBarMessagesData: [],
        statusCodeData: [],
        setupTimeData: [],
        // Create the chart
        pieChart: {
            chart: {
                type: 'pie',
                width: 900
            },
            title: {
                text: 'SIP Request Methods'
            },

            accessibility: {
                announceNewData: {
                    enabled: true
                },
                point: {
                    valueSuffix: '%'
                }
            },

            plotOptions: {
                pie: {
                  size: '50%'
                },
                series: {
                  cursor: 'pointer',
                  events: {
                    click: (event) => this.applySearch(event.point.query)
                  },
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: {point.y}'
                    },
                  showInLegend: true
                }
            },


            legend: {
                enabled: true,
                layout: 'vertical',
                align: 'left',
                verticalAlign: 'top',
                floating: true,
            },

            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
            },

            exporting: {
              enabled: false
            },

            series: [
                {
                    colorByPoint: true,
                    data: [
                    ]
                }
            ]
        },
        messageCountBar: {
          chart: {
            type: 'bar',
            height: 200,
            width: 900
          },
          title: {
              text: 'SIP Message Count'
          },
          legend: {
            enabled: false
          },
          xAxis: {
            // categories: ['Messages', 'Resent Messages'],
            // visible: false,
            type: 'category',
            lineWidth: 0,
            tickWidth: 0,
          },
          yAxis: {
            visible: false,
          },
          tooltip: {
          },
          plotOptions: {
              series: {
                cursor: 'pointer',
                events: {
                  click: (event) => this.applySearch(event.point.query)
                },
                dataLabels: {
                  enabled: true,
                  verticalAlign: 'top'
                },
                pointWidth: 15,
                // groupPadding: 0.1
              }
          },
          credits: {
            enabled: false
          },
          series: [],
          exporting: {
            enabled: false
          }
        },
        statusCodeBar: {
          chart: {
            type: 'bar',
            height: 300,
            width: 900
          },
          title: {
              text: 'Status code in SIP reply messages'
          },
          legend: {
            enabled: false
          },
          xAxis: {
            type: 'category',
            lineWidth: 0,
            tickWidth: 0,
          },
          yAxis: {
            visible: false,
          },
          tooltip: {
          },
          plotOptions: {
            series: {
              cursor: 'pointer',
              events: {
                click: (event) => this.applySearch(event.point.query)
              },
              dataLabels: {
                enabled: true,
              },
              pointWidth: 15
            }
          },
          credits: {
            enabled: false
          },
          series: [],
          exporting: {
            enabled: false
          }
        },
        setupTimeBar: {
          chart: {
            type: 'bar',
            height: 200,
            width: 900
          },
          title: {
              text: 'SIP Setup Times'
          },
          legend: {
            enabled: false
          },
          xAxis: {
            type: 'category',
            lineWidth: 0,
            tickWidth: 0,
          },
          yAxis: {
            visible: false,
          },
          tooltip: {
          },
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
                y: this.y + 20
              },
              pointWidth: 15
            }
          },
          credits: {
            enabled: false
          },
          series: [],
          exporting: {
            enabled: false
          }
        }
    }
  },
  computed: {
    ...mapState({
      dialogContent: state => state.packets.dialogContent,
      currentDialog: state => state.views.currentDialog,
      dialogInternal: state => state.packets.dialogInternal,
      uuid: state => state.packets.uuid
    }),
    dialogContentProps() {
      let receivedData = this.dialogContent || this.internalData,
          listedProps = receivedData && 
          Object.entries(receivedData);
      return listedProps[0] && listedProps[0][1];
    },
  },
  watch: {
      dialogContentProps(newValue, oldValue) {
          console.log(newValue, oldValue);
        if (newValue && newValue.requestList) {
          this.renderData(newValue);
        }
      },
  },
  methods: {
    ...mapActions({
      openDialog: 'packets/loadDialogData',
      toggleWindow: 'views/toggleWindow',
      loadPackets: 'packets/getPacketsList',
    }),
    openNewWindow() {
      let routeData = this.uuid && this.$router.resolve({path: `window/${this.uuid}/${this.$options.name}`}); //query: {searchField: this.searchField}
      this.uuid && window.open(routeData.href, '_blank');
      this.toggleWindow({value: this.$options.name});
      setTimeout(() => this.$refs.close.click(), 3000);
    },
    applySearch(query) {
        this.loadPackets({filter: query});
        this.closeDialog();        
        let routeData = this.$router.resolve({path: `/`});
        this.uuid && window.open(routeData.href, '_blank');
    },
    renderData(newValue) {
      console.log(newValue);
      this.dataSetPie = newValue.requestList;
      this.dataSetPie.forEach(ds => this.pieChart.series[0].data.push({
        name: ds.request,
        query: `sip.Method eq ${ds.request} && sip`,
        y: ds.count
      }));

      this.countBarMessagesData = [
        {
          name: 'Messages',
          data: [{
            y: parseInt(newValue.messageCount),
            query: 'sip && sip',
            name: 'Messages'
          }]
        }, 
        {
          data: [{
            y: parseInt(newValue.resentCount),
            query: `sip.resend==${newValue.resentCount} && sip`,
            name: 'Resent Messages'
          }]
        }, 
      ];

      this.countBarMessagesData.forEach(msg => {

        this.messageCountBar.series = [
          ...this.messageCountBar.series,
          msg
        ];

      });

      
      this.statusCodeData = newValue.statusList;

      this.statusCodeData.forEach(statusCode => {

        this.statusCodeBar.series.push({
          name: `${statusCode.status} ${statusCode.statusName}`,
          data: [{
            name: `${statusCode.status} ${statusCode.statusName}`,
            query: `sip.Status-Code==${statusCode.status} && sip`,
            y: parseInt(statusCode.count),
          }]
        });

        console.log(this.statusCodeBar.series);

      });

      this.setupTimeData = newValue.setupTime;

      this.setupTimeBar.series.push({
          name: `Minimum`,
          data: [{
            name: `Minimum`,
            y: parseInt(this.setupTimeData.min),
          }]
      });
      
      this.setupTimeBar.series.push({
          name: `Average`,
          data: [{
            name: `Average`,
            y: parseInt(
              Math.abs((this.setupTimeData.min + this.setupTimeData.min) / 2)
            ), //this.setupTimeData.average
          }]
      });

      this.setupTimeBar.series.push({
          name: `Maximum`,
          data: [{
            name: `Maximum`,
            y: parseInt(this.setupTimeData.max),
          }]
      });
    }
  },
  mounted() {
    if (this.dialogContentProps &&
        (this.dialogContentProps.length > 0) && 
        this.dialogContentProps.requestList) {
      this.dataSetPie = this.dialogContentProps.requestList;
      this.dataSetPie.forEach(ds => this.pieChart.series[0].data.push({
            name: ds.request,
            y: ds.count
          }));

      this.countBarMessagesData = [
        {
          name: 'Messages',
          data: [this.dialogContentProps.messageCount]
        }, 
        {
          name: 'Resent Messages',
          data: [this.dialogContentProps.resentCount]
        }, 
      ];
      this.countBarMessagesData.forEach(msg => {
        this.messageCountBar.series.push(msg)
      });
    }
    if (this.dialogInternal.dialogInternal) {
      this.renderData(this.dialogInternal.dialogInternal);
    }
    if (this.$router.history.current.name === 'window' && !this.internalDialog) {
      this.toggleWindow({value: this.$options.name});
    }
  },
}
</script>

<style scoped>
.sip-stats {
  max-width: 90vw;
  max-height: 80vh;
  width: 100%;
  height: 100%;
}

#sip-stats-graph {
  width: 99%;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    height: 70%;
    margin-top: 5px;
}

.content-wrapper .content {
    display: flex;
    justify-content: center;
    /* height: max-content; */
    height: 100%;
    align-items: flex-start;
    overflow: scroll;
}

.content-wrapper .content .hc-message-count {
  height: 200px;
  margin-top: 100px;
}

.content-wrapper .content .hc {
  margin: 20px auto;
  border: 1px solid #999;
  width: 100%;
  display: flex;
  justify-content: center;
}

.content-wrapper .content::-webkit-scrollbar-track {
  background: transparent;
}

.content-wrapper .content #packet-lengths-graph {
    border: 1px solid rgba(3, 192, 252, 0.25);
    width: 100%;
    min-width: 667px;
    max-width: 850px;
}

.content-wrapper .additonal-description {
    font-size: 13px;
    margin: auto;
    margin-bottom: 25px;
}

.sip-stats .dialog-header .internal-close {
  display: block !important;
}

@media screen and (max-width: 767px) {
  .content-wrapper .content {
    justify-content: initial;
  }
}
</style>
