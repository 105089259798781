<template>
    <div class="filter-button range-filter">
        <svg id="frequency-data" :width="width" :height="height"></svg>
        <div
            class="range-mask"
            :style="{left: `calc(${start}px)`, right: `calc(${end}px)`, width: `calc(${fullWidth - (start + end)}px + 13px)`}"
        ></div>
        <div 
            class="range-filter-controller"
        >
            <div 
              class="button-container end"
              :style="{left: `${start}px`, right: 0}"
            >
                <span></span>
                <button 
                    class="range-filter-start start"
                >
                </button>
            </div>
            <div
             class="button-container start"
              :style="{right: `${end}px`}"
            >
                <span></span>
                <button
                    class="range-filter-end end"

                >
                </button>
            </div>
        </div>
    </div>
</template>

<script>
// v-on:mousemove="dragButton($event)"
/*
v-on:mousedown="grabButton($event, 'start')"
v-on:mousedown="grabButton($event, 'end')"
*/

import * as d3 from "d3";
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'RangeFilter',
  data() {
    return {
        dir: '',
        start: 0,
        end: 0,
        fullWidth: 0,
        width: 669,
        height: 443,
        released: true
    }
  },
  computed: {
    startRange: function () { 
        let rounded = this.start / (this.fullWidth / 100);
        return rounded.toFixed(2);
    },
    endRange: function () {
        let rounded = this.end / ((this.fullWidth - 1) / 100);
        return rounded.toFixed(2);
    },
    percentRange: function () {
        let rounded = 100 - (parseFloat(this.startRange) + parseFloat(this.endRange));
        return rounded.toFixed(2);
    },
    startTimeRange: function () {
        return this.startRange * this.step
    },
    endTimeRange: function () {
        return this.maxTime - (this.endRange * this.step);
    },
    step: function () {
        return this.maxTime / 100;
    },
    ...mapState({
        uploadedFile: state => state.packets.uploadedFile
    }),
    ...mapGetters('packets', {
      dataSet: 'packetsFrequency',
    }),
    // dataSetLength() {
    //     return this.dataSet && this.dataSet.length;
    // },
    maxTime() {
        return this.uploadedFile && parseFloat(this.uploadedFile.captureDuration);
    }
  },
  watch: {
      dataSet(newValue, oldValue) {
        // (newValue > 0) ? this.drawFrequencies() : d3.selectAll("svg > *").remove();
        // (!newValue) && d3.selectAll("svg > *").remove();
        d3.selectAll("#frequency-data g").remove();
        newValue && this.drawFrequencies();
        (oldValue && !newValue) && d3.selectAll("#frequency-data g").remove();
      },
      released(newValue, oldValue) {
        if (newValue !== oldValue) {
            this.filterTimeSequence();
        }
      }
  },
  methods: {
    ...mapActions({
      loadPackets: 'packets/getPacketsList'
    }),
    filterTimeSequence() {
      let timeSequence = 
      `frame.time_relative >= ${ this.startTimeRange.toFixed(6) } && frame.time_relative <= ${ this.endTimeRange.toFixed(6) }`;
      console.log(timeSequence);
      this.loadPackets({filter: timeSequence});
    },
    //   grabButton (ev, dir) {
    //       console.log(ev, dir)

    //       let bounds = ev.target.getBoundingClientRect(),
    //           pointerX = ev.clientX - bounds.left;
    //       this.dir = dir;
    //       this[dir] = pointerX;
    //   },
    //   leaveButton (ev) {
    //     console.log(ev, 'leave');
    //     // this[this.dir] = ev.offsetX;
    //     console.log(this.start, this.end);
    //     this.dir = '';
    //   },
    //   dragButton (ev) {
    //     let bounds = ev.target.getBoundingClientRect(),
    //       pointerX = ev.clientX - bounds.left;

    //     console.log(ev, pointerX, this[this.dir], 'move')
    //     this[this.dir] = this.dir ? pointerX : this[this.dir];
    //   },

    drawFrequencies() {
        let margin = {top: 2, right: 1, bottom: 0, left: 7},
            width = 140 - (margin.left + margin.right),
            height = 34;

        const parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S.%L");

        // set the ranges
        let x = d3.scaleTime().range([0, width]);
        let y = d3.scaleLinear().range([height, 0]);
        // define the area
        const area = d3.area()
            .x(function(d) { return x(parseTime(d.time)); })
            .y0(height)
            .y1(function(d) { return y(d.counter); });

        // define the line
        const valueline = d3.line()
            .x(function(d) { return x(parseTime(d.time)); })
            .y(function(d) { return y(d.counter); });

        const svg = d3.select("#frequency-data")
            .attr("width", width)
            .attr("height", height + margin.top + margin.bottom)
          .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        // get the data
        // const dataLoader = d3.data(this.dataSet);

        // dataLoader.then(dataFiltered => {
        let dataFiltered = this.dataSet;

            // scale the range of the data
          x.domain(d3.extent(dataFiltered, function(d) { return parseTime(d.time); }));
          y.domain([0, d3.max(dataFiltered, function(d) { return d.counter; })]);

            // add the area
            svg.append("path")
                .data([dataFiltered])
                .attr("class", "area")
                .attr("d", area)
                .attr("fill", "rgb(86, 158, 214)");

            // add the valueline path.
            svg.append("path")
                .data([dataFiltered])
                .attr("class", "line")
                .attr("d", valueline)
                .attr("fill", "transparent");//rgb(86, 158, 214)

        // }).catch(e => console.log(e));
    }
  },
  mounted() {
    let self = this;
    const range = document.querySelector('.range-filter-controller');
    this.fullWidth = range.clientWidth - 26;

    range.addEventListener('mouseup', () => {
        self.dir = '';
        self.released = true;
    });
    range.addEventListener('mouseleave', () => {
        self.dir = '';
        self.released = true;
    });
    range.addEventListener('mousedown', (ev) => {
        let dir = ev.target.className.split(" ")[1];
        self.released = false;
        self.dir = dir ? dir : self.dir;
    });
    range.addEventListener('mousemove', (ev) => {
        if (self.dir === "start") {
          let bounds = ev.currentTarget.getBoundingClientRect(),
            pointerX = ev.clientX - bounds.left;

          if (pointerX <= (range.clientWidth - 26) && pointerX >= 0) {
            if (self.end > 0) { 
             if (pointerX < (self.fullWidth - self.end)) {
                self[self.dir] = pointerX;
             }   
            } else {
                self[self.dir] = pointerX;
            }
          }
        }

        if (self.dir === "end") {
          let bounds = ev.currentTarget.getBoundingClientRect(),
            pointerX = range.clientWidth - (ev.clientX - bounds.left);

          if ((pointerX <= (range.clientWidth - 26) && pointerX >= 0)) {
            if (self.start > 0) { 
             if (pointerX < (self.fullWidth - self.start)) {
                self[self.dir] = pointerX;
             }   
            } else {
                self[self.dir] = pointerX;
            }
          }
        }
    });

    if (this.dataSet) {
      this.drawFrequencies();
    }
  }
}
</script>

<style scoped>
.range-filter .range-filter-controller {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 138px;
    border-radius: 6px;
    /* height: 33px; */

    position: relative;
}

.range-filter-controller .button-container {
    position: relative;
    width: 13px;
    height: 100%;
    /* height: 35px; */
}

.range-filter-controller .button-container span {
    /* height: 35px; */
    height: 100%;
    width: 1px;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: #03C0FC;
}

.range-filter #frequency-data {
    position: absolute;
}
.range-filter .range-mask {
    position: absolute;
    background: url('../../assets/range_mask.png');
    width: calc(100% - 13px);
    height: 100%;
    opacity: 0.5;
    left: 0;
    right: 0;
    margin: auto;
}
.range-filter .range-filter-controller button {
    /* width: 13px;
    height: 13px;
    border-radius: 2px;
    cursor: pointer; */

    width: 13px;
    height: 13px;
    border-radius: 2px;
    cursor: pointer;
    z-index: 1;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    background: #fff;
}
.filter-button {
    overflow: hidden;
}
</style>
