<template>
    <div class="dialog-body follow-stream">

      <div class="dialog-header">

        <div class="title-section">
          <div class="header-title">
            <span class="title-text">{{title}}</span>
            <span 
              class="file-name"
              v-on:click="closeDialog"
            >{{filename}}</span>
          </div>
          
          <div class="header-description">
            
          </div>

        </div>

        <div class="close-section">
          <button
            class="close-dialog"
            v-on:click="closeDialog"
            :ref="'close'"
          >
            <span></span>
          </button>
        </div>

      </div>

      <div class="content-wrapper">
          <div class="content-filters">
              <div 
                class="show-only"
                v-on:click="applySearch()"
              >Show only this stream</div>
              <div
                v-on:click="applySearch(true)"
              >Filter out this stream</div>
          </div>
          <div class="content">
            <div
              class="report"
              v-bind:class="{wrapped: isWrapped}"
              v-if="dataType === 'ascii'"
            >
              <p
                v-for="(entry, ind) in this.dataList"
                v-bind:key="'udp-stream' + ind"
                v-bind:entry="entry"
                v-bind:class="{
                  ringing: entry && entry.ringing, 
                  trying: entry && entry.trying,
                  ok: entry && entry.ok
                }"
              >
                <span
                  v-for="(subStr, indx) in entry.strings"
                  v-bind:key="'udp-stream-string' + indx"
                  v-bind:subStr="subStr"
                  class="sub-entry"
                >
                  {{subStr}}
                  <br />
                </span>
              </p>
            </div>
            <div
              class="report hex"
              v-else
            >
              <p
                v-for="(str, indx) in internalContent"
                v-bind:key="'hex-reports' + indx"
              >
                {{str}}
              </p>
            </div>
          </div>
      </div>

      <div class="dialog-buttons">

        <div
          class="content-buttons"
        >

          <div 
            class="dropdown"
            v-on:click="openList"
            v-bind:class="{
              active: listIsOpened,
              unactive: !listIsOpened
            }"
          >
            <span>{{flowDirections[selectedDirection].label}}</span>
            <span class="icon"></span>

            <div class="choice-list">
              <div 
                v-for="(option, indx) in flowDirections"
                v-bind:key="option.label + indx"
                v-bind:option="option"
                v-on:click="selectDirection(option.value)"
                class="option"
              >{{option.label}}</div>
            </div>
          </div>

          <button 
            class="ladder-diagram"
            v-on:click="switchToDiagram"
          >
            <span>Ladder diagram</span>
          </button>


        </div>

        <div class="content-selection">
            <div class="ascii">
                <span 
                  class="radio"
                  v-bind:class="{
                    checked: dataType === 'ascii'
                  }"  
                  v-on:click="switchDataType('ascii')"
                ></span>
                ASCII
            </div>
            <div class="hex-dump">
                <span
                  class="radio"
                  v-bind:class="{
                    checked: dataType === 'hex-dump'
                  }"
                  v-on:click="switchDataType('hex-dump')"
                ></span>
                Hex Dump
            </div>
            <div class="wrap">
                <span 
                  class="check"
                  v-bind:class="{checked: isWrapped}"
                  v-on:click="switchWrap"
                ></span>
                Wrap Long Lines
            </div>
        </div>

        <div class="default-buttons">

          <button
            class="new-window"
            v-on:click="openNewWindow"
          >
            <span>Open In New Window</span>
          </button>
          <button 
            class="done"
            v-on:click="closeDialog"
          >
            <span>Done</span>
          </button>
          
        </div>
      </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'; //mapGetters, mapActions

export default {
  name: 'FollowUDPStream',
  props: {
    closeDialog: Function,
    fileName: String
  },
  data () {
    return {
        title: 'Follow UDP Stream 1 in',
        filename: this.fileName || 'voip-extension.pcap',
        packetCount: 0,
        flowDirections: [
          'Entire conversation'
        ],
        selectedDirection: 0,
        listIsOpened: false,
        dataType: 'ascii',
        isWrapped: false,
        dataList: [],
        dataListSaved: [],
    }
  },
  computed: {
    ...mapState({
      dialogContent: state => state.packets.dialogContent,
      currentDialog: state => state.views.currentDialog,
      uuid: state => state.packets.uuid
    }),
    ...mapGetters('packets', {
      internalContent: 'internalDialogFormat'
    }),
    dialogContentProps() {
      let listedProps = this.dialogContent && 
          Object.entries(this.dialogContent);
      return listedProps[0] && listedProps[0][1];
    },
  },
  watch: {
      dialogContentProps(newValue, oldValue) {
        console.log('window', newValue, oldValue, 'was changed');
        if (newValue) {
          this.getDataFromString(newValue);
        }
      },
  },
  methods: {
    ...mapActions({
      loadPackets: 'packets/getPacketsList',
      getHexDump: 'packets/dialogInternalRequest',
      toggleDiagram: 'views/toggleDiagram',
      toggleWindow: 'views/toggleWindow'
    }),
    getDataFromString(newValue) {
      let entries = newValue.split("U ");
      this.dataList = entries.map((entr) => {

        let oneEntry = {
          strings: entr.split('\n'),
          trying: entr.includes('Trying'),
          ringing: entr.includes('Ringing'),
          ok: entr.includes('200 OK'),
        };

        let pairsArray = this.flowDirections,
            firsString = entr.split('\n')[0],
            ipPair = firsString.substr(0, entr.split('\n')[0].indexOf('#'));
    
        (ipPair.length > 0) && pairsArray.push(
          ipPair
        );

        this.flowDirections = [...new Set(pairsArray)];

        this.flowDirections.forEach((fd, indx) => {
          oneEntry.pair = oneEntry.strings[0] === firsString ? (indx) : 0
        });

        return oneEntry;
      });

      this.dataListSaved = this.dataList;
      this.flowDirections = this.flowDirections.map((opt, indx) => 
        {
          return {
            label: opt,
            value: indx
          }
        }
      );
    },
    openNewWindow() {
      let routeData = this.uuid && this.$router.resolve({path: `window/${this.uuid}/${this.$options.name}`}); //query: {searchField: this.searchField}
      this.uuid && window.open(routeData.href, '_blank');
      this.toggleWindow({value: this.$options.name});
      setTimeout(() => this.$refs.close.click(), 3000);
    },
    openList () {
      this.listIsOpened = !this.listIsOpened;
    },
    selectDirection(val) {
      console.log(this.dataListSaved);
      this.selectedDirection = val;

      this.dataList = (val > 0) ? 
        this.dataListSaved.filter(dl => 
        ( dl.pair === val ) && dl) : this.dataListSaved;

    },
    switchWrap() {
      this.isWrapped = !this.isWrapped;
    },
    switchDataType(dt) {
      this.dataType = dt;
      console.log(this.internalContent);
    },
    switchToDiagram() {
      
      this.closeDialog(this.$options.name);
      this.toggleDiagram({switcher: true, searchQuery: 'udp.stream == 0'});
      let routeData = this.uuid && this.$router.resolve({path: `/analysis/${this.uuid}/ladder`, query: {searchField: this.searchField}});
      this.uuid && window.open(routeData.href, '_blank');

      // this.toggleDiagram({switcher: true, searchQuery: 'udp.stream eq 0'});
      // this.closeDialog(this.$options.name); 
    },
    applySearch(excl) {
      let searchQuery = `${excl ? '!' : ''} (udp.stream == 0)`;
      this.loadPackets({filter: searchQuery});
      this.$refs.close.click();
    }
  },
  mounted() {
    this.getHexDump(this.$options.name)

    if (this.dialogContentProps && this.dialogContentProps.length > 0) {
      this.getDataFromString(this.dialogContentProps);
    }
    if (this.$router.history.current.name === 'window') {
      this.toggleWindow({value: this.$options.name});
    }
    // (this.dataList.length <= 0 && this.dialogContentProps) && this.getDataFromString(this.dialogContentProps);
  }
}
</script>

<style scoped>
.follow-stream {
    width: 100%;
    max-width: 90vw;
    height: 100%;
    max-height: 544px;
}
.content-wrapper {

}
.content-wrapper .content {
    background: rgba(252,3,3,0.13);
    border-radius: 24px;
    padding: 23px 0 21px 24px;
    color: rgba(252,3,3,0.63);
    overflow-y: scroll;
    max-height: calc(338px - 44px);
    margin: 19px auto;
}
.content-wrapper .content .report.wrapped {
  overflow-wrap: break-word;
}
.content-wrapper .content .report.hex.wrapped {
  overflow-wrap: break-word;
}
.content-wrapper .content .report .ringing,
.content-wrapper .content .report .trying,
.content-wrapper .content .report .ok {
  color: rgba(3, 49, 252, 0.63);
}
.content-wrapper .content-filters {
    color: #03C0FC;
    display: flex;
    cursor: pointer;
    margin: 10px auto 19px;
}
.content-wrapper .content-filters .show-only::after {
    content: "|";
    color: #000;
    margin: 5px;
}
.content-buttons .ladder-diagram {
  border: 0.5px solid #03C0FC;
  border-radius: 6px;
  background: none;
  color: #03C0FC;
  width: 133px;
  height: 35px;
  cursor: pointer;
}
.content-buttons .dropdown {
    border: 0.5px solid #000;
    border-radius: 6px;
    background: none;
    width: max-content;
    padding: 0 4px;
    height: 28px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 10px;
}
.content-buttons .dropdown .icon {
    display: flex;
    text-indent: -9999px;
    width: 8px;
    height: 8px;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-image: url('/../assets/chevron_down.svg');
    mask-image: url('/../assets/chevron_down.svg');
    -webkit-mask-size: 8px 8px;
    mask-size: 8px 8px;
    background-color: #000;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    margin-left: 3px;
}


.content-buttons .dropdown .choice-list {
    display: none;

    flex-direction: column;
    align-items: flex-start;

    position: absolute;
    top: 35px;
    left: 0;
    background: #fff;
    width: 300px;
    border-radius: 6px;
    border: 0.5px solid #000;
    color: #000;
    z-index: 10;
    height: 0;
    opacity: 0;

    max-height: 100px;
    overflow: scroll;
}
.content-buttons .dropdown.active .choice-list {
    display: flex;
    animation: listexpand 0.3s linear forwards;
}
.content-buttons .dropdown.unactive .choice-list {
    display: flex;
    animation: listrelease 0.3s linear forwards;
}
.content-buttons .dropdown .choice-list .option {
    padding: 4px 11px;
    font-size: 14px;
    width: calc(100% - (11px * 2));
    text-align: left;
    text-transform: capitalize;
}
.content-buttons .dropdown .choice-list .option:hover {
  background: #d3d3d3;
}

@keyframes listrelease {
    from {
        height: max-content;
        pointer-events: auto;
        opacity: 1;
    }
    to {
        height: 0;
        pointer-events: none;
        opacity: 0;
    }
}
@keyframes listexpand {
    from {
        height: 0;
        pointer-events: none;
        opacity: 0;
    }
    to {
        height: max-content;
        pointer-events: auto;
        opacity: 1;
    }
}


.content-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.dialog-buttons {
  margin-left: 25px;
  justify-content: space-between;
  height: 100%;
  position: relative;
}
.dialog-buttons .content-selection {
    display: flex;
    height: max-content;
    width: 320px;
    position: absolute;
    left: 210px;
    justify-content: space-between;
    margin-top: 3px;
}
.default-buttons {
    align-items: flex-end;
}
.content-selection .ascii {
    display: flex;
    align-items: center;
}
.content-selection .ascii .radio,
.content-selection .ascii .radio.unchecked,
.content-selection .hex-dump .radio,
.content-selection .hex-dump .radio.unchecked {
    display: flex;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-image: url('/../assets/unactive_radio_ascii.svg');
    mask-image: url('/../assets/unactive_radio_ascii.svg');
    -webkit-mask-size: 20px 20px;
    mask-size: 20px 20px;
    background-color: #000;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    margin-right: 5px;
    cursor: pointer;
}

.content-selection .ascii .radio.checked,
.content-selection .hex-dump .radio.checked {
    display: flex;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-image: url('/../assets/active_radio_ascii.svg');
    mask-image: url('/../assets/active_radio_ascii.svg');
    -webkit-mask-size: 20px 20px;
    mask-size: 20px 20px;
    background-color: #03C0FC;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    margin-right: 5px;
}

.content-selection .hex-dump,
.content-selection .wrap {
    display: flex;
    align-items: center;
}

.content-selection .wrap .check.checked {
    display: flex;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-image: url('/../assets/checked_wrap.svg');
    mask-image: url('/../assets/checked_wrap.svg');
    -webkit-mask-size: 20px 20px;
    mask-size: 20px 20px;
    background-color: #03C0FC;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    margin-right: 5px;
}

.content-selection .wrap .check,
.content-selection .wrap .check.unchecked {
    display: flex;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-image: url('/../assets/unactive_radio_ascii.svg');
    mask-image: url('/../assets/unactive_radio_ascii.svg');
    -webkit-mask-size: 20px 20px;
    mask-size: 20px 20px;
    background-color: #03C0FC;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    margin-right: 5px;
}
@media screen and (max-width: 767px) {
  .follow-stream {
    max-height: 641px;
    top: 0;
    bottom: 0;
  }
  .content-buttons {
    height: 78px;
    position: absolute;
  }
  .dialog-buttons .content-selection {
    width: 120px;
    flex-wrap: wrap;
    height: 78px;
  }
}
</style>
