import Vue from 'vue'
import App from './components/App.vue'
import store from './store'
import VueRouter from 'vue-router'
import LadderView from './components/dialogs/LadderView'
import VoipFlow from './components/dialogs/VoipFlow'
// import ErrorPage from './components/ErrorPage'
import EntireWindow from './components/dialogs/EntireWindow'
import Highcharts from "highcharts";
import ExportingHighcharts from "highcharts/modules/exporting";
import boost from 'highcharts/modules/boost';
import Stock from "highcharts/modules/stock";
import HighchartsVue from "highcharts-vue";


boost(Highcharts);
Stock(Highcharts);
ExportingHighcharts(Highcharts);
Vue.use(HighchartsVue);
Vue.use(VueRouter);
Vue.use(require("moment"));

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/:uuid',
      name: 'main',
      component: App
    },
    { path: '/error',
      name: 'error',
      component: App
    },
    { path: '/analysis/:uuid/ladder',
      name: 'ladder',
      component: LadderView
    },
    { path: '/analysis/:uuid/voip_flow',
      name: 'flow',
      component: VoipFlow
    },
    { path: '/window/:uuid/:window',
      name: 'window',
      component: EntireWindow
    }
  ]
})

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')