<template>
  <div
    class="upload-panel"
    :class="{active: pickedFile, filled: droppedFile}"
    v-on:dragover="dragover" v-on:dragleave="dragleave" v-on:drop="drop"
  >
    <a href="http://www.denovolab.com" class="branded-logo"></a>
    <div class="upload-header">Upload Your PCAP File</div>
    <input type="file" name="pcap-field" class="pcap-upload-field" 
        v-on:change="uploadDirect" ref="upload" accept=".pcap,.pcapng" />
  
    <label for="pcap-upload-field" class="field-label">
      <div class="upload-icon"></div>
      <div class="title">
        <span class="main">Drag & Drop PCAP File Here</span>
        <br />
        <span class="or">OR</span>
        <br />
      </div>
      <button
        class="upload-button"
        v-on:click="uploadDirectClick"
      >SELECT A FILE</button>
    </label>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UploadPanel',
  props: {
    dragAndUpload: Function
  },
  data() {
    return {
       droppedFile: null,
       pickedFile: false
    }
  },
  methods: {
    ...mapActions({
      uploadFile: 'packets/uploadAndGetUUID',
    }),
    dragover(event) {
      event.preventDefault();
      this.pickedFile = true;
    },
    dragleave() {
      this.pickedFile = false;
    },
    drop(event) {
      event.preventDefault();
      this.droppedFile = true;
      this.uploadFile(event.dataTransfer.files[0]);
    },
    uploadDirect(ev) {
      this.uploadFile(ev.target.files[0]);
    },
    uploadDirectClick() {
      this.$refs.upload.click();
    }
  }
}
</script>

<style scoped>
  .upload-panel {
    width: calc(100vw - 4px);
    height: calc(100vh - 4px);
    margin: auto;
    border: 2px solid #999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    background: #fff;
  }
  .branded-logo {
    position: absolute;
    background: url('../assets/logo/3-01.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100px;
    height: 50px;
    left: 0;
    top: 0;
  }
  .upload-panel.active {
    opacity: 0.5;
  }
  .upload-panel.filled {
    
  }
  .pcap-upload-field {
    opacity: 0;
    pointer-events: none;
  }
  .upload-header {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 600;
  }
  .field-label {
    border: 3px dashed #999;
    border-radius: 6px;
    padding: 30px 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .upload-icon {
    display: flex;

    text-indent: -9999px;
    width: 100px;
    height: 100px;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-image: url('../assets/cloud-upload-alt-solid.svg');
    mask-image: url('../assets/cloud-upload-alt-solid.svg');
    -webkit-mask-size: 100px 100px;
    mask-size: 100px 100px;
    background-color: #595a5a;
  }
  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
  }
  .main {
    color: #595a5a;
    font-size: 16px;
  }
  .or {
    color: #000;
    font-size: 12px;
  }
  .upload-button {
    border: none;
    border-radius: 15px;
    background: #316dc7;
    color: #fff;
    padding: .6em 2.4em;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
  }
  .upload-button:hover {
    background: #143463;
  }
</style>
