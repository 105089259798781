<template>




    <div 
      class="ladder-view flow main"
      v-if="diagramOpened.switcher"
    >
      <multipane class="resizable" layout="horizontal" v-on:paneResize="handleResize">
        <div class="dialog-header">

        <div class="title-section">

          <div class="header-title">
            <span class="title-text">{{title}}</span>
            <span
              class="file-name"
              v-on:click="toggleDiagram({switcher: false, searchQuery: ''})"
            >{{uploadedFile.fileName}}</span>
          </div>

        </div>

      </div>

      <div class="ladder-content">
        <div class="ladder-toolbar">
            <button 
                class="conversations"
                v-on:click="openDialog({value: 'SIPStats'})"
            >
                <span>SIP Statistics</span>
            </button>

            <div class="calls-dropdown">
                Choose a call:
                <div 
                  class="dropdown"
                  v-on:click="openCallsList"
                  v-bind:class="{
                    active: listIsOpened,
                    unactive: !listIsOpened
                  }"
                >
                    <span>{{callsOptions[selectedCall].label}}</span>
                    <span class="icon"></span>

                    <div class="choice-list">
                      <div 
                        v-for="(option, indx) in callsOptions"
                        v-bind:key="indx + 'calls-options-lv'"
                        v-bind:option="option"
                        v-on:click="selectCallOption(indx)"
                        class="option"
                      >{{option.label}}</div>
                    </div>
                </div>
            </div>

        </div>

        <div class="data-description">
            Apply as Display Filter ({{`${allPackets.length} packets`}})
            <span 
            class="display-link"
            v-on:click="applySearch('show')">
            Show
            </span>
            |
            <span
            class="display-link"
            v-on:click="applySearch('exclude')">
            Exclude
            </span>
        </div>
 
            <div 
              class="ladder-diagrams">
                <div
                  v-if="packetsLength"
                  class="diagram-graph"
                  v-bind:class="{'is-fixed': isFixed}"
                >
                  <svg 
                    id="ips-header"
                    preserveAspectRatio="xMinYMin meet" 
                    :viewBox="`0 0 ${320 * uniqueIpsLength} 50`"
                    v-bind:class="{'is-fixed': isFixed}"
                  >
                      <rect
                        :width="320 * uniqueIpsLength" 
                        height="50" 
                        x="0" 
                        y="0" 
                        fill="rgba(255,255,255,0.7)" 
                        :stroke-dasharray="`${320 * uniqueIpsLength} ${320 * uniqueIpsLength + (25 * 2)}`" 
                        stroke="rgba(0,0,0,0.4)" 
                        stroke-width="1" 
                        :stroke-dashoffset="`-${320 * uniqueIpsLength + (25 * 2)}`"
                        id="header"
                      ></rect>
                      <g
                        :class="`ip-address-${uipind} ip-address`"
                        v-for="(uip, uipind) in uniqueIps"
                        v-bind:key="'uniue-ip-address' + uipind"
                        :transform="`translate(${pointers[uipind] ? pointers[uipind] : 0}, ${pointersY[uipind] ? pointersY[uipind] : 0})`"
                        text-anchor="middle"
                      >
                        <!-- :x="(330 * (uipind + 1)) - 190" -->
                        <!-- :x="(330 * (uipind + 1)) - 184" -->
                        <rect
                          width="70"
                          height="20"
                          :x="step(uipind + 1) - 36"
                          y="15"
                          fill="#fff"
                          stroke="#03C0FC"
                          rx="6"
                          style="cursor: move"
                          v-on:mousedown="dragStart($event, uipind)" 
                          v-on:mousemove="dragMove($event, uipind)" 
                          v-on:mouseleave="dragStop($event, uipind)"
                          v-on:mouseup="dragStop($event, uipind)"
                        ></rect>
                        <text
                          :x="step(uipind + 1) - 2"
                          y="28"
                          font-size="9px"
                          font-family="'Roboto', sans-serif"
                          style="cursor: move; pointer-events: none; user-select: none"
                        >{{
                            (!pointersY[uipind] || (pointersY[uipind] &lt; 20)) ?
                            `${uip}` :
                            `remove`
                          }}</text>
                      </g>
                  </svg>
                  <svg id="path-diagram" preserveAspectRatio="xMinYMin meet" :viewBox="`0 0 ${320 * uniqueIpsLength} ${(40 * allPackets.length) + 50}`">
                    <defs>
                      <marker id="arrow" refX="4" refY="2.5" markerWidth="5" markerHeight="5" orient="auto">
                        <path stroke="none" fill="#000" d="M0,0 L5,2.5 L0,5 Z" class="arrow"></path>
                      </marker>
                      <marker id="backarrow" refX="4" refY="2.5" markerWidth="5" markerHeight="5" orient="auto">
                        <path stroke="none" fill="#000" d="M0,2.5 L5,0 L5,5 Z" class="arrow"></path>
                      </marker>
                    </defs>
                    <g>
                        <text 
                            class="time-labels"
                            v-for="(sipPacket, spIndx) in allPackets"
                            v-bind:key="'sipPacketLabel' + spIndx"
                            v-bind="sipPacket"
                            :x="20" 
                            :y="`${40 * (spIndx + 1)}`"
                            font-size="8px"
                            font-family="'Roboto', sans-serif"
                        >{{`${sipPacket.time.split(".")[0]}`}}</text>
                        <!-- parserTime(sipPacket.time) -->
                    </g>

                    <g 
                      v-for="(col, indx) in uniqueIps"
                      v-bind:key="'uniqueip' + indx"
                      v-bind:col="col"
                      v-bind:uniqueKey="(indx > 0 && indx % 2 !== 0) && indx"
                    >
                        <path 
                            stroke="gray" 
                            fill="none" 
                            :d="`M${step(indx + 1)},0 L${step(indx + 1)},${(50 * allPackets.length) + 50}`" 
                            :transform="`translate(${pointers[indx] ? pointers[indx] : 0}, 0)`"
                            class="arrow"></path>
                    </g>

                    <g
                        v-for="(sipPacket, spIndx) in allPackets"
                        v-bind:key="'sipPacket' + spIndx"
                        v-bind="sipPacket"
                        class="one-path"
                        v-on:click="showDecodedData(sipPacket.no)"
                    >
                        <text 
                            :x="
                                (step(uniqueIps.findIndex(uip => uip===sipPacket.destination) + 1) >
                                step(uniqueIps.findIndex(uip => uip===sipPacket.source) + 1)) ?
                                step(uniqueIps.findIndex(uip => uip===sipPacket.destination) + 1) -170 :
                                step(uniqueIps.findIndex(uip => uip===sipPacket.source) + 1) - 170
                            " 
                            transform="translate(0,-5)"
                            text-anchor="middle"
                            :y="`${40 * (spIndx + 1)}`"
                            font-size="9px"
                            font-family="'Roboto', sans-serif"
                        >{{`${sipPacket[pathsLabel]}`}}</text>
                        <path 
                            :stroke="`${colors.find(color => color[0] === sipPacket.protocol)[1]}`" 
                            :d="`M${
                                step(uniqueIps.findIndex(uip => uip===sipPacket.source) + 1)
                            },${40 * (spIndx + 1)} L${step(uniqueIps.findIndex(uip => uip===sipPacket.destination) + 1)},${40 * (spIndx + 1)}`" 
                            :style="`${step(uniqueIps.findIndex(uip => uip===sipPacket.source) + 1) > 
                                step(uniqueIps.findIndex(uip => uip===sipPacket.destination) + 1) ? 
                                'marker-end: url(#arrow)' : 'marker-end: url(#arrow)'}`"
                        ></path>
                    </g>
                  </svg>
                </div>
                <div
                  v-else
                  class="default-text"
                >
                  Choose a Conversation, Protocol, Endpoint, or Apply an initial Display Filter to generate a ladder diagram of the selected packets.
                </div>
            </div>
        </div>
        <multipane-resizer id="hrz"></multipane-resizer>
        <div class="audio-flow-wrapper" :style="{height: secondPaneSize ? secondPaneSize : ''}">
          <div class="audio-flow-container">
            <AudioFlow
            v-bind:selectCallOption="selectCallOption"
           />
          </div>
        </div>
      </multipane>
      <LeftPaneDialog
        v-if="!protocol && openedDetails"
        v-bind:closeDialog="openLocalDialog"
        v-bind:fileName="uploadedFile.fileName"
      />
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'; //mapGetters, mapActions
import * as d3 from "d3";
import { Multipane, MultipaneResizer } from 'vue-multipane';
import LeftPaneDialog from './content/LeftPaneDialog';
import AudioFlow from './content/parts/AudioFlow';

export default {
  name: 'VoipFlow',
  components: {
    Multipane,
    MultipaneResizer,
    LeftPaneDialog,
    AudioFlow
  },
  props: {
    closeDialog: Function,
    fileName: String,
    protocol: Boolean
  },
  data () {
    return {
        title: 'SIP calls from ',
        isFixed: true,
        uniqueIps: [],
        hardwareAddresses: [],
        uniqueTypes: [],
        allPackets: [],
        colors: [
            ["SIP/SDP", "blue"],
            ["SIP", "orange"],
            ["RTP", "green"],
            ["RTCP", "red"],
            ["RTP EVENT", "purple"],
            ["SIP/sipfrag", "brown"],
        ],
        dragged: false,
        startPoint: 0,
        startPointY: 0,
        pointers: [],
        pointersY: [],
        step: (repeat) => (330 * repeat) - 155,
        callsOptions: [
            {
              label: 
              `All Calls in ${ this.uploadedFile ? 
                this.uploadedFile.fileName : this.fileName}`, 
              value: ''
            }, 
        ],
        searchField: '',
        excludeQuery: '',
        listIsOpened: false,
        selectedCall: 0,
        pathsLabel: 'info',
        openedDetails: false,
        secondPaneSize: '200px'
    }
  },
  computed: {
    ...mapState({
      dialogContent: state => state.packets.dialogContent,
      diagramOpened: state => state.views.diagramFlowOpened,
      packets: state => state.packets.packetList,
      uuid: state => state.packets.uuid,
      currentWindow: state => state.views.currentDialog,
      uploadedFileRaw: state => state.packets.uploadedFile
    }),
    ...mapGetters('packets', {
      uploadedFile: 'uploadedFileFormat',
    }),
    packetsLength() {
        return this.packets && this.packets.length;
    },
    diagramQuery() {
        return this.diagramOpened && this.diagramOpened.searchQuery
    },
    uniqueIpsLength() {
        return this.uniqueIps && this.uniqueIps.length;
    },
    // graphDimensionsCalculated: function(){
    //   return {
    //     width: `${320 * this.uniqueIpsLength}`, 
    //     height: `${(50 * this.allPackets.length) + 500}`
    //   };
    // }
  },
  watch: {
    packetsLength(newValue, oldValue) {
      (newValue !== oldValue) && this.drawDiagram();// : d3.selectAll("svg > *").remove();
    },
    diagramQuery(newValue, oldValue) {
        (newValue !== oldValue) && this.loadPackets({filter: newValue});
    },
    uploadedFile(newValue) {
        if (newValue) this.filename = newValue.fileName;
    },
    selectedCall(newValue) {
        // let searchQuery = 
        //   `(ip.addr == ${
        //     this.callsOptions[newValue].source
        //   } || ip.addr == ${
        //     this.callsOptions[newValue].destination
        //   }) && sdp`;
        // this.searchField = searchQuery;
        // this.excludeQuery = 
        //   `(ip.addr != ${
        //     this.callsOptions[newValue].source
        //   } || ip.addr != ${
        //     this.callsOptions[newValue].destination
        //   }) && !sdp`;
        this.loadPackets({filter: 'sdp', calls: (newValue > 0) ? (newValue - 1) : ''});
    }
  },
  methods: {
    dragStart (d, i) {

        this.dragged = true;
        this.startPoint = d.clientX;
        this.startPointY = d.offsetY;
        let curPointers = [...this.pointers],
            curPointersY = [...this.pointersY];
        curPointers[i] = 0;
        curPointersY[i] = 0;
        this.pointers = curPointers;
        this.pointersY = curPointersY;
        console.log(this.pointersY, d);
        this.$el.addEventListener('mousemove', this.dragMove, false);
        this.$el.addEventListener('mouseup', this.dragStop, false);
        this.$el.addEventListener('mouseleave', this.dragStop, false);
    },
    dragStop (d, i) {
        if (this.dragged && ((this.pointers[i] > 160) || (this.pointers[i] < -160))) {
            let reordered = this.uniqueIps;

            reordered.move = function(from, to) {
            this.splice(to, 0, this.splice(from, 1)[0]);
            };

            (this.pointers[i] > this.startPoint) ? 
                reordered.move(i,(i +1)) : reordered.move(i,(i -1));
            this.uniqueIps = reordered;
        }
        
        if (this.dragged && this.pointersY[i] <= 20) {
            this.pointersY[i] = 0;
        }
        if (this.dragged && this.pointersY[i] > 20) {
            // this.uniqueIps.splice(i, 1);
            // this.pointersY.splice(i, 1);
            this.dragged = false;
            let searchQuery = `!(ip.addr == ${this.uniqueIps[i]})`;
            this.loadPackets({filter: searchQuery});
            // this.pointersY[i] = 0;
        }

        this.dragged = false;
        let curPointers = [...this.pointers];
        curPointers[i] = 0;
        this.pointers = curPointers;
    },
    dragMove (d, i) {
        if (this.dragged && (i >=0 )) {
            console.log(d);
          const svg = document.querySelector('#ips-header');
          let pt = svg.createSVGPoint();

          const cursorPoint = (e) =>  {
            pt.x = e.clientX - this.startPoint; pt.y = 0;
            return pt.matrixTransform(svg.getScreenCTM().inverse());
          }
    
          let loc = cursorPoint(d);

          let pointerMoveY = (d.offsetY - (d.target.height.baseVal.value + d.target.y.baseVal.value));

          let curPointers = [...this.pointers],
            curPointersY = [...this.pointersY];
            curPointers[i] = loc.x;
            curPointersY[i] = (pointerMoveY >= 0) ? pointerMoveY : 0;
          this.pointers = curPointers;
          this.pointersY = curPointersY;
        } 
    },
    showDecodedData(no) {
        this.selectPacket(no);
        this.openLocalDialog();
        // this.toggleDialog({value: 'LeftPaneDialog'});
        // this.openDialog({value: 'LeftPaneDialog'});
    },
    openLocalDialog() {
        this.openedDetails = !this.openedDetails;
    },
    parserTime: (d) => {
        const parseIt = d3.timeParse("%Y-%m-%d %H:%M:%S"),
            formatIt = d3.timeFormat("%S.%L"); 
        return formatIt(parseIt(d))
    },
    reorderDiagram() {
        this.uniqueIps = this.uniqueIps.reverse();
    },
    applySearch(show) {
        show ? 
          this.loadPackets({filter: this.searchField ? this.searchField : 'sdp'}) : 
          this.loadPackets({filter: this.excludeQuery ? this.excludeQuery : '!sdp'});
          
        this.toggleDiagram({switcher: false, searchQuery: ''});
        let routeData = this.$router.resolve({path: `/`});
        this.uuid && window.open(routeData.href, '_blank');
    },
    clearSearch() {
        this.searchField = '';
        this.removeAllPacketData();
    },
    ...mapActions({
      toggleDiagram: 'views/toggleFlowDiagram',
      toggleDialog: 'views/toggleDialog',
      loadPackets: 'packets/getPacketsList',
      openDialog: 'packets/loadDialogData',
      removeAllPacketData: 'packets/removeUploadedPacket',
      selectPacket: 'packets/getSelectedPacket',
    }),
    drawDiagram() {

        let uniqueIps = [],
            uniqueTypes = [],
            allPackets = this.packets,
            // onlySip = this.packets.filter(pl => {
            //   if (pl.protocol.includes('SIP')) {
            //     uniqueTypes.push(pl.info);
            //     return pl;
            //   }
            // }),
        // sources = onlySip.filter((v,i,a)=>a.findIndex(t=>(t.source === v.source))===i),
        // destination = onlySip.filter((v,i,a)=>a.findIndex(t=>(t.destination === v.destination))===i),
        sourcesDestinations = allPackets.filter((v,i,a)=>a.findIndex(t=>((t.source === v.source) && (t.destination === t.destination)))===i);

        // (this.callsOptions.length <= 1) && sourcesDestinations.forEach((sd, indx) => {
        //   this.callsOptions.push({
        //     label:`#${indx} ${sd.source} - ${sd.destination}`, //change to from - to
        //     value: indx + 1,
        //     source: sd.source,
        //     destination: sd.destination
        //   });
        // });

        sourcesDestinations.forEach(sd => {
            uniqueIps.push(sd.source, sd.destination);
        });

        allPackets.forEach(pckt => {
            uniqueTypes.push(pckt.protocol);
        });
        uniqueIps = [...new Set(uniqueIps)];
        // this.hardwareAddresses = this.packets[0];
        uniqueTypes = [...new Set(uniqueTypes)];
        this.allPackets = allPackets;
        this.uniqueIps = uniqueIps;
        this.uniqueTypes = uniqueTypes;

        console.log(uniqueTypes);

    },
    // handleScroll (event) {
    //   this.isFixed = event.target.scrollingElement.scrollTop >= 240;
    // },
    openCallsList () {
      this.listIsOpened = !this.listIsOpened;
    },
    selectCallOption(val) {
        this.selectedCall = val;

        // this.filterCalls(this.callsOptions[val].value);
    },
    // filterCalls(val) {
    //     this.packetList = this.packetList.map(pck => {
    //         (pck.source === val) || (pck.destination === val) && pck
    //     });
    // }
    handleResize(pane, container, size) {
      console.log(pane, container);
      this.secondPaneSize = `calc(100vh - (${size} + 67px))`;
    }
  },
  mounted() {
      let searchField = this.$router.history.current.query.searchField,
          current = this.$router.history.current.params;
      console.log(searchField, current);
      if (this.$router.history.current.name === 'flow') {
        this.loadPackets({filter: searchField});
        this.toggleDiagram({switcher: true, searchQuery: searchField ? searchField : '', data: this.diagramOpened.data});
        this.drawDiagram();
      }
      if (this.diagramOpened.data) {
        this.diagramOpened.data.forEach((dd, indx) => {
          this.callsOptions.push({
            label:`#${indx} ${dd.from} » ${dd.to}`,
            value: indx,
          });
        });
      }
    // document.addEventListener('scroll', (ev) => {
    //     ev.target.scrollingElement && this.handleScroll(ev);
    // });
  }
}
</script>

<style scoped>
/* html {
  height: 100vh;
  overflow: hidden;
}
body {
  overflow: auto;
} */

.ladder-view {
  /* max-width: 760px; */
  max-height: 296px;
  width: 100%;
  height: 30%;
  margin-bottom: auto;
}
.ladder-view .dialog-header {
  margin: 13px 0 0;  
}
.ladder-view.main {
    width: 100%;
    /* height: calc(100vh); */
    height: auto;
    position: absolute;
    top: 0px;
    left: 0;
    background: #fff;
    z-index: 8;
    /* padding-top: 20px; */
    max-width: none;
}
.app-container::-webkit-scrollbar {
    width: 10px;
}

.ladder-diagrams::-webkit-scrollbar {
    width: 10px;
}

.ladder-content {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    flex-direction: column;
}
.main .ladder-content,
.main .ladder-diagrams {
    height: auto;
    overflow: auto;
}
.audio-flow {
  background: #fff;
}
.ladder-diagrams {
    font-size: 14px;
    width: 100%;
    height: 100%;
    margin: auto;
    margin-top: 0;
    text-align: center;
    overflow: scroll;
    padding-bottom: 50px;
}
.diagram-graph {
    /* width: 100%;
    height: 100%; */

}
.diagram-graph.is-fixed {
    margin-top: 67px;
}
.diagram-graph .ips-header {
    display: flex;
    width: 100%;
    position: absolute;
    height: 70px;
    align-items: center;
    background: rgba(255,255,255,0.5);
    border-bottom: 1px solid #d2d2d2;
}

.diagram-graph #ips-header.is-fixed {
    position: absolute;
    top: 102px;
    margin-top: 0;
}
.diagram-graph .ips-header .ip-address {
    display: flex;
    margin-left: 340px;
}
.diagram-graph #ips-header {
    margin-top: 30px;
    overflow: visible;
}
.diagram-graph #ips-header .ip-address button {
    border: 2px solid #03C0FC;
    background: #fff;
    border-radius: 6px;
    height: 35px;
}
.diagram-graph #ips-header .ip-address:first-child {
    margin-left: 190px;
}
#path-diagram {
    /* margin-top: 70px; */
    margin-top: 20px;
}

#path-diagram .one-path {
    cursor: pointer;
}

#path-diagram .one-path:hover text {
    font-weight: 600;
}

.ladder-toolbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 11px;
    height: 40px;
    background: rgba(3, 192, 252, 0.1);
    border-radius: 6px;
}

.ladder-toolbar .dropdown {
    border: 0.5px solid #000;
    border-radius: 6px;
    background: none;
    height: 28px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: max-content;
    /* padding: 5px 8px 6px; */
    font-size: 11px;
    margin-left: 5px;
    position: relative;
}
.ladder-toolbar .dropdown .icon {
    display: flex;
    text-indent: -9999px;
    width: 8px;
    height: 8px;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-image: url('/../assets/chevron_down.svg');
    mask-image: url('/../assets/chevron_down.svg');
    -webkit-mask-size: 8px 8px;
    mask-size: 8px 8px;
    background-color: #000;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}

.ladder-toolbar .search-filter {
    flex-basis: 45%;
    white-space: nowrap;
}

.conversations,
.protocols,
.endpoints {
    height: 22px;
    border: 1px solid #03C0FC;
    color: #03C0FC;
    background: #fff;
    border-radius: 6px;
    font-size: 11px;
    padding: 0;
}

.main .conversations,
.main .protocols,
.main .endpoints {
    /* height: 70%;
    flex-basis: 6%;
    margin-right: 5px;
    height: 35px; */
    width: auto;
    margin-right: 10px;
    padding: .4em 2.1em;
    height: auto;
    font-size: 13px;
}

.main .search-filter {
    flex-basis: 56%;
    height: 6vh;
    justify-content: center;
    font-size: 13px;
}

.main .search-filter input {
    margin-left: 10px;
    height: auto;
    font-size: 13px;
    flex-basis: 40%;
    padding: .4em;
}

.main .search-filter .apply,
.main .search-filter .clear {
    /* flex-basis: 10%;
    height: 70%;
    font-size: 1.2vw; */
    width: auto;
    height: auto;
    padding: .4em 2.1em;
}

.main .ladder-toolbar {
    width: calc(100% - 50px);
    padding: 0 10px;
    font-family: 'Roboto', sans-serif;
}
.main .dialog-header {
    margin: 30px 18px;
    font-family: 'Roboto', sans-serif;
}

.header-title .file-name {
    color: #03C0FC;
    cursor: pointer;
}

.main .ladder-toolbar {

    padding: 10px;
}

.ladder-toolbar button,
.ladder-toolbar .reset {
    cursor: pointer;
}

.main .label-dropdown {
    justify-content: space-around;
    margin-right: 10px;
    font-size: 13px;
}
.main .calls-dropdown {
    justify-content: space-around;
    margin-right: 10px;
    font-size: 13px;
}

.main .calls-dropdown .dropdown {
    /* flex-basis: 60%; */
    padding: .4em 2.1em;
    width: auto;
    height: auto;
    margin-left: 10px;
    font-size: 13px;
    width: 45vw;
    justify-content: space-between;
}
.main .label-dropdown .dropdown {
    /* flex-basis: 50%; */
    padding: .4em 2.1em;
    width: auto;
    height: auto;
    margin-left: 10px;
    font-size: 13px;
}
.main .calls-dropdown .dropdown .icon {
    margin-left: 5px;
}

.main .reset {
    /* flex-basis: 4%; */
    display: flex;
    justify-content: center;
    font-size: 13px;
}

.main .title-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.main .data-description {
    font-size: 14px;
    width: calc(100% - 50px);
    font-family: 'Roboto', sans-serif;
    margin: 20px auto;
}

.main .data-description .display-link {
    color: #03C0FC;
    cursor: pointer;
    text-decoration: underline;
}

.conversations {
    width: 78px;
}
.protocols {
    width: 66px;
}
.endpoints {
    width: 60px;
}
.calls-dropdown {
    display: flex;
    align-items: center;
}

.calls-dropdown .dropdown {
    width: 115px;
    height: 22px;
    background: #fff;
}
.label-dropdown .dropdown {
    width: 40px;
    height: 22px;
    background: #fff;
}

.dropdown.active .choice-list {
    display: flex;
    animation: listexpand 0.3s linear forwards;
}
.dropdown.unactive .choice-list {
    display: flex;
    animation: listrelease 0.3s linear forwards;
}

@keyframes listrelease {
    from {
        height: max-content;
        pointer-events: auto;
        opacity: 1;
    }
    to {
        height: 0;
        pointer-events: none;
        opacity: 0;
    }
}
@keyframes listexpand {
    from {
        height: 0;
        pointer-events: none;
        opacity: 0;
    }
    to {
        height: max-content;
        pointer-events: auto;
        opacity: 1;
    }
}

.dropdown .choice-list {
    display: none;

    flex-direction: column;
    align-items: flex-start;

    position: absolute;
    top: 30px;
    left: 0;
    background: #fff;
    width: 100%;
    border-radius: 6px;
    border: 0.5px solid #000;
    color: #000;
    z-index: 10;
    height: 0;
    opacity: 0;

    max-height: 125px;
    overflow: scroll;
}

.dropdown .choice-list .option {
    padding: 3px;
    width: 100%;
}
.dropdown .choice-list .option:hover {
    background: #d3d3d3;
}

.search-filter {
    align-items: center;
    background: none;
}
.search-filter input {
    height: 20px;
    padding: 0;
    border: 0.5px solid #03C0FC;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    width: 200px;
    text-indent: 14px;
    font-size: 11px;
    margin-left: 5px;
}
.search-filter .apply {
    width: 44px;
    height: 22px;
    background: #fff;
}
.search-filter .clear {
    border-left: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    width: 43px;
    height: 22px;
    background: #fff;
}



.vertical-panel {
  height: 45vh;
  overflow: hidden;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  /* background: #fff; */
}

.multipane.resizable.layout-v .multipane-resizer {
  margin: 0; left: 0;
  background: #c8c8c8;
}

.multipane.resizable.layout-h .multipane-resizer {
  margin: 0; top: 0;
  background: #c8c8c8;
}

.multipane.resizable.layout-h {
  max-height: 100vh;
  overflow: hidden;
}

.audio-flow-wrapper {
    z-index: 10;
    display: flex;
    flex-grow: 1;
    position: static;
    width: 100%;
    height: 100%;
    background: #fff;
    overflow-y: scroll;
}

.audio-flow-wrapper::-webkit-scrollbar {
  width: 10px;
}

.audio-flow-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.audio-flow {
    background: #fff;
    z-index: 10;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    height: max-content;
}

.audio-pane {
    height: max-content;
    width: 100%;
    display: flex;
}

#hrz {
  height: 5px;
  z-index: 99;
}

#vtc {
  height: 500px;
  width: 5px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.panes-layout {
  margin-left: 0!important;
  margin-right: 0!important;
  background: #fff;
}

.panes.mobile {
  width: 100vw;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}

@media screen and (max-width: 767px) {
    .ladder-content {
        height: 80%;
        margin-top: 5%;
    }
    .ladder-toolbar {
        flex-wrap: wrap;
        height: auto;
        justify-content: initial;
        padding: 9px;
    }
    .filter-button {
        margin: auto 0;
    }
    .conversations, .protocols, .endpoints {
        margin-right: 10px;
    }
    .search-filter input {
        margin-left: 10px;
    }
    .dropdown {
        margin-left: 10px;
        margin-right: 10px;
    }
}
</style>