<template>
  <div 
    class="dialog-container"
    :class="{window: isWindow}"
  >

    <div class="dialog-body left-pane">

      <div class="dialog-header">

        <div class="title-section">

          <div class="header-title">
            <span class="title-text">
              {{title[0]}}
              <span 
                class="frame-link"
                v-on:click="closeAndSearch"
              >{{currentPacket}}</span>
              {{title[1]}}
            </span>
            <span class="file-name">{{filename}}</span>
          </div>

        </div>

        <div class="close-section">
          <button
            class="close-dialog"
            v-on:click="closeDialog"
            :ref="'close'"
          >
            <span></span>
          </button>
        </div>

      </div>

      <div
        class="window-buttons"
        v-if="isWindow"
      >

          <button 
            class="stream"
            v-on:click="switchToStream"
          >
            <span>Follow UDP</span>
          </button>
          <button 
            class="sender"
            v-on:click="downloadText"
          >
            <span>Download as .txt</span>
          </button>
          
      </div>

      <div class="content-wrapper">
          <div class="content">
            <LeftPane 
              v-bind:isLadder="true"
            />
          </div>
      </div>

      <div
        class="dialog-buttons"
        v-if="!isWindow"
      >

        <div class="custom-buttons">

          <button 
            class="stream"
            v-on:click="openDialog({value: 'FollowUDPStream'})"
          >
            <span>Follow UDP</span>
          </button>
          <button 
            class="sender"
            v-on:click="downloadText"
          >
            <span>Download as .txt</span>
          </button>
          
        </div>

        <div class="default-buttons">

          <button class="new-window"
            v-on:click="openNewWindow"
          >
            <span>Open In New Window</span>
          </button>
          <button 
            class="done"
            v-on:click="closeDialog"
          >
            <span>Done</span>
          </button>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LeftPane from '../../datapanes/LeftPane';

export default {
  name: 'LeftPaneDialog',
  components: {
    LeftPane
  },
  props: {
    closeDialog: Function,
    fileName: String,
    isWindow: Boolean
  },
  data () {
    return {
      title: [`Protocol Decode of `, ` from`],
      filename: this.fileName,
      currentPacket: `frame ${this.selectedPacket || 0}`
    }
  },
  watch: {
    selectedPacket(newValue) {
      if (newValue) {
        this.currentPacket = newValue;
      }
    },
    dialogContent(newValue) {
      console.log(newValue);
    }
  },
  computed: {
    ...mapState({
      dialogContent: state => state.packets.dialogContent,
      packetDetails: state => state.packets.packetDetails,
      selectedPacket: state => state.packets.selectedPacket,
      currentWindow: state => state.views.currentWindow,
      uuid: state => state.packets.uuid
    })
  },
  methods: {
    ...mapActions({
      toggleDiagram: 'views/toggleDiagram',
      openDialog: 'packets/loadDialogData',
      toggleWindow: 'views/toggleWindow',
    }),
    closeAndSearch() {
      this.closeDialog({});
      this.toggleDiagram({switcher: false, searchQuery: `frame.number eq ${this.selectedPacket}`})
    },
    openNewWindow() {
      let routeData = this.uuid && this.$router.resolve({
        path: `/window/${this.uuid}/${this.$options.name}`
      }); //query: {searchField: this.searchField}
      this.uuid && window.open(routeData.href, '_blank');
      this.toggleWindow({value: this.$options.name, data: this.selectedPacket});
      setTimeout(() => this.$refs.close.click(), 3000);
    },
    switchToStream() {
      this.openDialog({value: 'FollowUDPStream'})

      let routeData = this.uuid && this.$router.replace({
        path: `/window/${this.uuid}/${ 'FollowUDPStream' }`
      });
      this.uuid && window.open(routeData.href, '_self');
      this.toggleWindow({value: 'FollowUDPStream' });
      setTimeout(() => this.$refs.close.click(), 3000);
    },
    downloadText() {
      let plainText = (dataToConvert, desc) => `\n${dataToConvert.map(pck => {
          return `${desc ? '\b\b' : '\n\n'} ${pck.name}\n  ${pck.children ? `\b\b${plainText(pck.children, true)}` : ''}`
        }).join("")}`;

      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(plainText(this.packetDetails)));
      element.setAttribute('download', `frame_${this.selectedPacket}.txt`);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);

    }
  },
  mounted() {
    this.currentPacket = `frame ${this.selectedPacket ? 
    this.selectedPacket : (this.currentWindow ? this.currentWindow.data : 0)}`;
  }
}
</script>

<style scoped>
.dialog-container {
  position: fixed;
  width: 100%;
  height: calc(100% - 60px);
  background: rgba(0,0,0,0.23);
  left: 0;
  top: 60px;
  z-index: 15;
}
.dialog-container.window .left-pane {
  max-width: none;
  max-height: none;
  border-radius: 0;
  top: 0;
  display: block;
}
.window-container {
  height: calc(100% - 60px); 
  top: 60px;
}
.dialog-container.window .dialog-header {
  margin-bottom: 13px;
}
.dialog-container.window .left-pane .close-dialog,
.dialog-container.window .default-buttons {
  display: none;
}
.dialog-body {
  position: absolute;
  left: 0;
  right: 0;
  top: 80px;
  margin: auto;
  background: #fff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}
.window .dialog-body {
  justify-content: space-evenly;
}
.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 13px 18px 0;
}
.title-section {

}
.header-title {

}
.title-text {
  margin-right: 5px;
}
.file-name {
  color: #03C0FC;
  cursor: pointer;
}

.close-section {

}

.close-dialog {
  background: none;
  border: none;
  cursor: pointer;
}

.close-dialog span {
  display: flex;

  text-indent: -9999px;
  width: 27px;
  height: 27px;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url('../../../assets/close_dialog_icon.svg');
  mask-image: url('../../../assets/close_dialog_icon.svg');
  -webkit-mask-size: 27px 27px;
  mask-size: 27px 27px;
  background-color: #000;
}

.content-wrapper {
  margin-right: 18px;
  margin-left: 18px;
}

.dialog-buttons {
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;
  margin-bottom: 25px;
}
.default-buttons {
  display: flex;
  justify-content: space-between;
  width: 257px;
}
.default-buttons button,
.window-buttons button {
  border: 0.5px solid #03C0FC;
  border-radius: 6px;
  background: none;
  color: #03C0FC;
  height: 35px;
}
.window-buttons {
  background: rgba(3, 192, 252, 0.2);
  border-radius: 6px;
  padding: 10px;
  width: calc(100% - 50px);
  margin: 13px auto;
}
.window-buttons button {
  margin-left: 25px;
  background: #fff;
}
.new-window {
  width: 154px;
}
.done {
  width: 85px;
}
@media screen and (max-width: 767px) {
  .dialog-container {
    background: #000;
  }
}

.left-pane {
    width: 100%;
    max-width: 719px;
    height: 100%;
    max-height: 375px;
}
.left-pane .content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 5px;
    overflow-y: scroll;
    max-height: calc(375px - 50px);
}

.left-pane .frame-link {
  color:#03C0FC;
  cursor: pointer;
}

.content-wrapper .content {
    display: flex;
    justify-content: flex-start;
    height: 100%;

    flex-direction: column;
    align-items: self-start;
}
.content-wrapper .content .content-title {
    margin-bottom: 16px;
}
.dialog-buttons {
  justify-content: space-between;
  margin-left: 25px;
}
.dialog-buttons .custom-buttons {
    display: flex;
    justify-content: space-between;
    width: 250px;
}
.dialog-buttons .custom-buttons button {
    border: 0.5px solid #03C0FC;
    border-radius: 6px;
    background: none;
    color: #03C0FC;
    height: 35px;
    padding: 0px 15px;
}

.content-wrapper .log-row {
    color: #03C0FC;
    margin-bottom: 20px;
}

.content-wrapper .log-row .log-name {
    font-weight: 700;
    margin-bottom: 5px;
}
.content-wrapper .log-row .log-description {
    margin-left: 20px;
    margin-bottom: 7px;
}

button {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
    .content-wrapper .content {
        height: 80%;
        margin-top: 5%;
    }
}
</style>
