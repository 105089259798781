<template>
    <div
      class="dialog-body graph-data"
      :class="{'window-view': isWindow}"
    >
        <div class="dialog-header">

        <div class="title-section">

          <div 
            class="header-title"
            v-if="isWindow"
          >
            <span class="title-text">{{title}}</span>
            <span
              class="file-name"
              v-on:click="closeWindow"
              :ref="'filename'"
            >{{uploadedFile.fileName}}</span>
          </div>

        </div>

        <div class="close-section">
          <button
            class="close-dialog"
            v-on:click="closeDialog"
            :ref="'close'"
          >
            <span></span>
          </button>
        </div>

      </div>

      <div class="content-wrapper">

          <GraphToolbar
            v-if="isWindow"
            v-bind:closeWindow="closeWindow"
            v-bind:selectedImageOption.sync="selectedImageOption"
            v-bind:exportChart="exportChart"
            v-bind:printChart="printChart"
            v-bind:applySearch="applySearch"
            v-bind:openEditor="openEditor"
            v-bind:editMode="editMode"
          />

          <div 
            class="subtitle"
            v-if="editMode"
          >{{newGraphName || 'New Graph'}}</div>
          <div 
            class="subtitle"
            v-else
          >Current Display Filter</div>
          <div class="additonal-description">
            bytes at an interval of {{timeIntervalOptions[timeIntervalLabel].label}}
          </div>

          <div class="content">
            <div id="packet-bytes">

              <highcharts 
                :constructorType="'stockChart'" 
                class="hc" 
                :options="chartOptions"
                ref="chart"></highcharts>
              <!-- :updateArgs="[true, false, { duration: 1000 }]" -->
              <div
                class="graph-editor"
                v-if="editMode"
              >
                <div class="display-filters">
                  <div class="caption">Display Filters</div>
                  <div
                    class="filter-option"
                    v-for="(filterOption, indx) in filterOptions"
                    v-bind:key="indx + 'filter-opt'"
                  >
                    <button
                      class="remove"
                      v-on:click="removeFilter"
                    >
                      <span class="icon"></span>
                    </button>
                    <input 
                      placeholder="All traffic" 
                      v-on:input="insertFilterQuery($event, indx)"
                      v-on:blur="activateFilterQuery(indx)"
                      :value="filterQueries[indx] ? filterQueries[indx] : ''"
                    />
                    <div 
                      class="dropdown"
                      v-on:click="openList(indx)"
                      v-bind:class="{
                        active: listIsOpened === indx,
                        unactive: listIsOpened !== indx
                      }"
                    >
                      <span>{{listOptions[selectedOption[indx]]}}</span>
                      <span class="icon"></span>

                      <div class="choice-list">
                      <div 
                          v-for="(option, optIndx) in listOptions"
                          v-bind:key="optIndx + 'filter-graph'"
                          v-bind:option="option"
                          v-on:click="selectOption(indx, optIndx)"
                          class="option"
                      >{{option}}</div>
                      </div>
                    </div>
                    <div
                      class="filter-color"
                      :style="{background: filterColors[indx]}"
                    ></div>
                  </div>

                  <div
                    v-if="filterOptions.length < 5"
                    class="filter-option-add"
                  >
                    <button
                      class="add"
                      v-on:click="addFilter()"
                    >
                      <span class="icon"></span>
                    </button>
                    <input placeholder="All traffic" />
                    <div 
                      class="dropdown"
                    >
                      <span>{{listOptions[0]}}</span>
                      <span class="icon"></span>
                    </div>
                  </div>
                </div>
                <div class="settings-options">
                  <div class="caption">Settings and Display Options</div>
                  <div class="settings-form">
                    <label class="title" >
                      Graph Title
                      <input 
                        v-on:input="insertTitle"
                        :value="newGraphName"
                      />
                    </label>
                    <div class="selections">
                      <label class="time-interval">
                        Time Interval
                        <select
                          v-on:change="timeIntervalChange($event, null, $event.target.selectedIndex)"
                        >
                          <option
                            v-for="tint in timeIntervalOptions"
                            v-bind:key="tint.label"
                            v-bind:value="tint.value"
                            :selected="timeInterval === tint.value"
                          >
                            {{tint.label}}
                          </option>
                        </select>
                      </label>
                      <label class="axis">
                        Y-Axis Units:
                        <select
                          v-on:change="unitsTypeChange($event)"
                        >
                          <option value="packets">packets</option>
                          <option selected value="bytes">bytes</option>
                          <option value="bits">bits</option>
                          <option value="value">value</option>
                          <option value="packets-second">packets / second</option>
                          <option value="bytes-second">bytes / second</option>
                          <option value="bits-second">bits / second</option>
                        </select>
                      </label>
                    </div>
                    <div class="checkers">
                      Options:
                      <label>
                        <input type="checkbox" />
                        Use time of day
                      </label>
                      <label>
                        <input type="checkbox" />
                        Include packet annotations
                      </label>
                      <label>
                        <input type="checkbox" />
                        Stack series of the same type
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>

      <div class="dialog-buttons">

        <div  class="content-buttons">
            

          <button 
            class="create-graph"
            v-if="!editMode"  
            v-on:click="openNewWindow('edit')"
          >
            <span>Create A New Graph</span>
          </button>

        </div>

        <div class="default-buttons">

          <button
            class="edit-graph"
            v-on:click="openNewWindow('edit')"
          >
            <span>Edit This Graph</span>
          </button>

          <button
            class="new-window"
            v-on:click="openNewWindow()"
          >
            <span>Open In New Window</span>
          </button>
          <button 
            class="done"
            v-on:click="closeDialog"
          >
            <span>Done</span>
          </button>
          
        </div>
      </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'; 
import GraphToolbar from './parts/GraphToolbar';
import * as moment from "moment/moment";

export default {
  name: 'GraphsStats',
  components: {
    GraphToolbar,
  },
  props: {
    closeDialog: Function,
    mode: String
  },
  data () {
    return {
        title: 'Current Display Filter from ',
        isWindow: false,
        filteredDataSet: [],
        timeIntervalOptions: [
          { value: 'min', label: 'minimum'},
          {value: '0.001' , label: '1 millisecond'},
          {value: '0.01' , label: '10 milliseconds'},
          {value: '0.1' , label: '100 milliseconds'},
          {value: '1' , label: '1 second'},
          {value: '5', label: '5 seconds'}
        ],
        chartOptions: {
          credits: {
            enabled: false
          },
          chart: {
            spacingTop: 30,
            // height: '90%'
            events: {
              load: (function(self) {
                return function() {
                  self.chart = this; // saving chart reference in the component
                };
              })(this)
            }
          },
          scrollbar: {
            enabled: false
          },
          navigator: {
            enabled: false
          },
          legend: {
              enabled: true,
              layout: 'vertical',
              align: 'right',
              verticalAlign: 'top',
              floating: true,
              y: -30
          },
          rangeSelector: {
            enabled: false,
            inputEnabled: false,
          },
          yAxis: {
            opposite:false,
            gridLineWidth: 1,
            labels: {
              align: 'right',
              x: 20
            },
            title: {
              text: 'bytes'
            }
          },
          xAxis: {
            // type: 'datetime',
            gridLineWidth: 1,
            labels: {
              autoRotation: 0,
            },
            dateTimeLabelFormats: {
              seconds: '0:%S.%L',
              milliseconds: '0:%S.%L'
            },
            events: {
              setExtremes: this.syncExtremes
            },
          },
          plotOptions: {
              area: {
                  fillColor: {
                      linearGradient: {
                          x1: 0,
                          y1: 0,
                          x2: 0,
                          y2: 1
                      },
                  },
                  marker: {
                      radius: 2
                  },
                  lineWidth: 1,
                  states: {
                      hover: {
                          lineWidth: 1
                      }
                  },
                  threshold: null
              }
          },
          exporting: {
            filename: 'chart',
            enabled: true,
            type: 'image/png',
            buttons: {
              contextButton: {
                enabled: false
              }
            }
            // chartOptions: {
            //   chart: {
            //     width: 1400,
            //     height: 1200
            //   }
            // }
          },
          series: [
            {
              name: "All Traffic",
              type: 'area',
              fillColor:'rgba(3, 192, 252, 0.25)',
              data: this.filteredDataSet
            }
          ]
        },
        selectedImageOption: 'PNG',
        imageTypes: {
          PNG: 'image/png',
          JPG: 'image/jpeg',
          SVG: 'image/svg+xml',
          PDF: 'application/pdf'
        },
        selectionStart: 0,
        selectionEnd: 0,
        editMode: false,
        newGraphName: 'New Graph',   
        listIsOpened: false,
        selectedOption: [0, 0, 0, 0, 0],
        listOptions: ['area', 'line', 'column', 'spline', 'areaspline'],
        filterColors: [
          'rgba(124, 181, 236, 0.75)', 
          'rgba(67, 67, 72, 0.75)', 
          'rgba(144, 237, 125, 0.75)',
          'rgba(247, 163, 92, 0.75)',
          'rgba(128, 133, 233, 0.75)'
        ],
        filterOptions: [0],
        timeInterval: '0.1',
        timeIntervalLabel: 3,
        filterQueries: []
        // dataSet: this.filteredDataSet
    }
  },
  computed: {
    ...mapState({
      dialogContent: state => state.packets.dialogContent,
      currentDialog: state => state.views.currentDialog,
      currentWindow: state => state.views.currentWindow,
      specificPackets: state => state.packets.dialogInternal,
      uuid: state => state.packets.uuid
    }),
    ...mapGetters('packets', {
      uploadedFile: 'uploadedFileFormat'
    }),
    dialogContentProps() {
      let listedProps = this.dialogContent && 
          Object.entries(this.dialogContent);
      return listedProps[0] && listedProps[0][1];
    },
    searchQuery() {
      let defaultStart = this.chartOptions.series[0].data[0][0],
          start = moment(this.selectionStart).diff(defaultStart),
          end = moment(this.selectionEnd).diff(defaultStart),
          startFormat = `${moment(start).seconds()}.${moment(start).milliseconds()}`,
          endFormat = `${moment(end).seconds()}.${moment(end).milliseconds()}`;

      return `(frame.time_relative >= ${startFormat} && frame.time_relative <= ${endFormat})&time_display=relative`
      // return `%28frame.time_relative%20%3E%3D%20${startFormat}%20%26%26%20frame.time_relative%20%3C%3D%20${endFormat})%29&time_display=relative`;
    }
  },
  watch: {
    dialogContentProps(newValue) {
      console.log(newValue);
        
      if (this.currentWindow.data) {
        newValue && this.drawByData(newValue);
        newValue && this.addFilter(newValue);
      } else {
        newValue && this.drawByData();
      }

    },
    specificPackets(newValue) {
      console.log(newValue)
      if (newValue) {
        this.filteredDataSet = newValue.dialogInternal;
        this.chartOptions.series[0].data = newValue.dialogInternal;
        this.drawByData(newValue.dialogInternal);
      }
    }
  },
  methods: {
    ...mapActions({
      loadPackets: 'packets/getPacketsList',
      openDialog: 'packets/loadDialogData',
      toggleWindow: 'views/toggleWindow',
      getSpecPackets: 'packets/dialogInternalRequest'
    }),
    drawByData(newVal) {
      console.log('by newVal', newVal)
      let packets = newVal ? newVal : this.dialogContentProps,
          filtered = packets.map(pck => {
            return [parseFloat(pck.endInterval * 1000), parseInt(pck.totalBytes)]; //[Date.parse(pck.time), parseInt(pck.length)]
          });

      Promise.all(filtered).then(res => {
        this.filteredDataSet = newVal ? 
        ((this.filteredDataSet.length > 0) ? this.filteredDataSet : [...res])
        : [...res];
        console.log(res);
        this.chartOptions.series.forEach((sr, ind) => {
          let notSpread = [...res];
          sr.boostThreshold = 200;
          sr.data = notSpread;
          ind === (sr.length - 1) && this.$refs.chart.chart.redraw();
        });
  
      });
    },
    openNewWindow(edit) {
      console.log(edit);
      let routeData = this.uuid && this.$router.resolve({path: `window/${this.uuid}/${this.$options.name}`}); //query: {searchField: this.searchField}
      this.uuid && window.open(routeData.href, '_blank');
      this.toggleWindow({value: this.$options.name, mode: edit ? 'edit' : ''});
      setTimeout(() => this.$refs.close.click(), 3000);
    },
    closeWindow() {
      this.$refs.filename.click();
    },
    exportChart(opt) {
      this.selectedImageOption = opt;
      this.chartOptions.exporting.type = this.imageTypes[opt];
      setTimeout(() => this.$refs.chart.chart.exportChart(), 500);
    },
    printChart() {
      this.$refs.chart.chart.print();
    },
    syncExtremes(e) {
      this.selectionStart = e.min;
      this.selectionEnd = e.max;
      console.log(this.searchQuery);
    },
    applySearch() {
        this.loadPackets({filter: this.searchQuery});
        this.$refs.filename.click();
    },
    openEditor() {
      this.editMode = true;
      this.$router.push(`${this.$router.history.current.path}/new`);
    },
    openList(ind) {
      this.listIsOpened = ind === this.listIsOpened ? false : ind;
    },
    selectOption(indx, optInd) {
      this.selectedOption[indx] = optInd;
      this.chartOptions.series[indx].type = this.listOptions[optInd];
    },
    addFilter(qr) {
        if (qr) {
          this.chartOptions.series = [];
          (this.filterOptions.length < this.filterQueries.length) && 
            this.filterOptions.push(this.filterOptions.length);
        } else {
          this.filterOptions.push(this.filterOptions.length);
        }
        let newFilter = {};//this.chartOptions.series[0];
        console.log(this.filterOptions.length - 1);
        newFilter.name = "All Traffic";
        newFilter.fillColor = this.filterColors[this.filterOptions.length - 1];
        newFilter.type = 'area';
        newFilter.data = qr ? qr : this.chartOptions.series[0].data;
        // this.chartOptions.series[this.chartOptions.series.length] = newFilter;
        this.chartOptions.series.push(newFilter);
        this.$refs.chart.chart.redraw();
    },
    removeFilter() {
      this.filterOptions.length > 1 && this.filterOptions.pop();
      this.chartOptions.series.length > 1 && this.chartOptions.series.pop();
      console.log(this.chartOptions.series[this.chartOptions.series.length -1].fillColor);
    },
    insertTitle(e) {
      this.newGraphName = e.target.value
    },
    timeIntervalChange(e, qr, optInd) {
      this.timeInterval = e.target.value;
      this.timeIntervalLabel = optInd;
      this.openDialog({
        value: this.$options.name, 
        query: `interval=${e.target.value}${qr ? `&filter=${qr}` : ''}`
      });
      if (!this.isWindow) {
        // this.$refs.close.click();
      }
    },
    unitsTypeChange(e) {
      console.log(e.target.value);
    },
    insertFilterQuery(e, indx) {
      this.$set(this.filterQueries, indx, e.target.value);
    },
    activateFilterQuery(indx) {
      this.loadPackets({filter: this.filterQueries[indx]});
    }
  },
  mounted() {
    console.log(this.dialogContentProps);
    if (this.dialogContentProps && this.dialogContentProps.length > 0) {
      this.chartOptions.series[0].data = this.filteredDataSet;
      this.drawByData();
      this.$refs.chart.chart.redraw();
    }
    if (this.$router.history.current.name === 'window') {
      !this.currentWindow.value && this.toggleWindow({value: this.$options.name});
      this.isWindow = true;
      this.chartOptions.scrollbar.enabled = true;
      this.chartOptions.navigator.enabled = true;
      // this.chartOptions.legend.enabled = true;
      this.chartOptions.rangeSelector.enabled = true;
      (this.mode === 'edit') && this.openEditor();
    }

    if (this.currentWindow.data) {
      this.openEditor();
      this.currentWindow.data.queries.forEach((qr, indx) => {
        this.insertFilterQuery({target: {value: qr}}, indx);
        this.timeIntervalChange({target: {value: '1'}}, qr, 4);
      });
      this.insertTitle({target: {value: this.currentWindow.data.title}});
    }

    if (!this.dialogContentProps) {
      this.getSpecPackets('GraphsStats');
    }

    document.addEventListener('click', function (ev) {
      if (ev.target.className === 'file-name') {
        self.openStaticDialog({value: ''});
        self.toggleWindow({value: ''});
        self.$router.push('/');
      }
    });
  },
}
</script>

<style scoped>
.highcharts-credits {
  visibility: hidden;
}
.graph-data {
  max-width: 90vw;
  max-height: 550px;
  width: 100%;
  height: 100%;
}

.graph-data.window-view .content-wrapper {
  height: auto;
}

.dialog-header .title-section {
  margin: auto;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 5px;
}

.content-wrapper .content {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    overflow: scroll;
    flex-direction: column;
}

.graph-editor {
  display: flex;
  width: 90%;
  height: 200px;
  border: 1px solid #03C0FC;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin: auto;
  margin-bottom: 50px;
}

.graph-editor .display-filters {
  display: flex;
  flex-basis: 65%;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif;
}

.graph-editor .display-filters .caption {
  color: #999;
  margin-top: 5px;
  font-size: 16px;
}

.graph-editor .display-filters .filter-option,
.graph-editor .display-filters .filter-option-add {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 5px 0;
}

.graph-editor .display-filters .filter-option-add {
  margin-right: 60px;
}

.graph-editor .display-filters .filter-option input,
.graph-editor .display-filters .filter-option-add input {
  width: 60%;
}

.graph-editor .display-filters .filter-option-add input,
.graph-editor .display-filters .filter-option-add .dropdown {
  opacity: 0.6;
}

.graph-editor .display-filters .filter-option-add .add,
.graph-editor .display-filters .filter-option .remove {
  background: none;
  border: 1px solid #03C0FC;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.graph-editor .display-filters .filter-option-add .add .icon {
  background-image: url('../../../assets/icons_set.png');
  background-position: -16px -128px;
  display: flex;
  width: 16px;
  height: 16px;
}

.graph-editor .display-filters .filter-option .remove .icon {
  background-image: url('../../../assets/icons_set.png');
  background-position: -48px -128px;
  display: flex;
  width: 16px;
  height: 16px;
}

.graph-editor .display-filters .filter-option .filter-color {
  width: 40px;
  height: 15px;
  margin: auto 10px;
}

.graph-editor .settings-options {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-basis: 45%;
  border-left: 1px solid;
  font-family: 'Roboto', sans-serif;
}

.graph-editor .settings-options .caption {
  color: #999;
  margin-top: 5px;
  font-size: 16px;
}

.graph-editor .settings-options .settings-form {
  width: 100%;
  display: flex;
  align-items: center;
}

.graph-editor .settings-options .settings-form {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.graph-editor .settings-options .settings-form .title {
  width: 90%;
  display: flex;
  justify-content: center;
  margin: 10px;
  flex-direction: column;
}

.graph-editor .settings-options .settings-form .title input {
  width: 50%;
  height: 26px;
  margin-top: 5px;
}

.graph-editor .settings-options .settings-form .selections {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 70%;
  margin-left: 10px;
}

.graph-editor .settings-options .settings-form .selections select {
  width: 70%;
  margin-top: 5px;
}

.graph-editor .settings-options .settings-form .checkers {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 70%;
}

.graph-editor .settings-options .settings-form .time-interval {

}
.graph-editor .settings-options .settings-form .axis {}

.graph-editor .dropdown {
    border: 0.5px solid #000;
    border-radius: 6px;
    background: none;
    width: max-content;
    padding: 5px 8px 6px;
    font-size: 11px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 10px;
    position: relative;
    width: 80px;
}

.graph-editor .dropdown .icon {
    display: flex;
    text-indent: -9999px;
    width: 8px;
    height: 8px;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-image: url(/../assets/chevron_down.svg);
    mask-image: url(/../assets/chevron_down.svg);
    -webkit-mask-size: 8px 8px;
    mask-size: 8px 8px;
    background-color: #000;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}



.graph-editor .dropdown .choice-list {
    display: none;

    flex-direction: column;
    align-items: flex-start;

    position: absolute;
    top: 30px;
    background: #fff;
    width: max-content;
    border-radius: 6px;
    border: 0.5px solid #000;
    color: #000;
    z-index: 10;
    height: 0;
    opacity: 0;

    max-height: 125px;
    overflow: scroll;
}
.graph-editor .dropdown.active .choice-list {
    display: flex;
    animation: listexpand 0.3s linear forwards;
}
.graph-editor .dropdown.unactive .choice-list {
    display: flex;
    animation: listrelease 0.3s linear forwards;
}
.graph-editor .dropdown .choice-list .option {
    padding: 4px 11px;
    font-size: 14px;
    width: calc(100% - (11px * 2));
    text-align: left;
    text-transform: capitalize;
}
.graph-editor .dropdown .choice-list .option:hover {
  background: #d3d3d3;
}

@keyframes listrelease {
    from {
        height: max-content;
        pointer-events: auto;
        opacity: 1;
    }
    to {
        height: 0;
        pointer-events: none;
        opacity: 0;
    }
}
@keyframes listexpand {
    from {
        height: 0;
        pointer-events: none;
        opacity: 0;
    }
    to {
        height: max-content;
        pointer-events: auto;
        opacity: 1;
    }
}

.content-wrapper .content::-webkit-scrollbar-track {
  background: transparent;
}

.content-wrapper .content #packet-bytes {
    /* border: 1px solid rgba(3, 192, 252, 0.25); */
    width: 100%;
    min-width: 667px;
}

.content-wrapper .content #packet-bytes .hc {
  border: 1px solid #999;
  border-radius: 6px;
}

.content-buttons .create-graph,
.content-buttons .edit-graph {
  border: 0.5px solid #03C0FC;
  border-radius: 6px;
  background: none;
  color: #03C0FC;
  padding: 0 15px;
  height: 35px;
  cursor: pointer;
}
.content-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 25px;
}

.content-wrapper .subtitle {
  margin: auto;
}
.content-wrapper .additonal-description {
    font-size: 11px;
    margin: auto;
    padding-right: 40px;
    margin-bottom: 25px;
}

.window-view .title-section {
  margin: 0;
}

.window-view .content-wrapper .additonal-description {
  padding-right: 0;
}

.window-view .dialog-buttons .create-graph {
  display: none;
}

.default-buttons {
  width: 370px;
}

.dialog-buttons {
  justify-content: space-between;
}

button {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .content-wrapper .content {
    justify-content: initial;
  }
}
</style>
