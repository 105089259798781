var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dialog-body protocol-hierarchy"},[_c('div',{staticClass:"dialog-header"},[_c('div',{staticClass:"title-section"},[_c('div',{staticClass:"header-title"},[_c('span',{staticClass:"title-text"},[_vm._v(_vm._s(_vm.title))]),_c('span',{staticClass:"file-name"},[_vm._v(_vm._s(_vm.filename))])])]),_c('div',{staticClass:"close-section"},[_c('button',{ref:'close',staticClass:"close-dialog",on:{"click":_vm.closeDialog}},[_c('span')])])]),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"additonal-description"},[_vm._v(" Click on the protocol name to apply a Display Filter for only that traffic. ")]),_c('div',{staticClass:"content"},[_c('table',{attrs:{"cellspacing":"0"}},[_c('thead',[_c('tr',{staticStyle:{"text-align":"left"}},_vm._l((_vm.tableHeaders),function(tableHeader,ind){return _c('th',_vm._b({key:tableHeader.value + ind,class:tableHeader.value,on:{"click":function($event){return _vm.sortOrder(tableHeader, ind)}}},'th',tableHeader,false),[_c('div',{staticClass:"container"},[_vm._v(" "+_vm._s(tableHeader.label)+" "),_c('div',{staticClass:"order-button",class:{
                                asc: (tableHeader.value === _vm.currentHeader.value) 
                                    && _vm.currentHeader.order === 'asc',
                                desc: (tableHeader.value === _vm.currentHeader.value) 
                                    && _vm.currentHeader.order === 'desc'}},[_c('span',{staticClass:"order-icon order-asc"}),_c('span',{staticClass:"order-icon order-desc"})])])])}),0)]),_c('tbody',{staticClass:"show-annotations",attrs:{"tabindex":"0"}},_vm._l((_vm.entriesOrdered),function(entry,ind){return _c('tr',_vm._b({key:'entry_' + ind,on:{"click":function($event){return _vm.searchByQuery(entry.protocol)}}},'tr',entry,false),[_c('td',{staticClass:"bottom-border first",class:{
                        'is-child': entry.child, 
                        'is-parent': entry.parent
                      }},[_vm._v(_vm._s(entry.protocol))]),_c('td',{staticClass:"bottom-border second"},[_vm._v(_vm._s(entry.frames))]),_c('td',{staticClass:"bottom-border"},[_c('div',{staticClass:"percent-container"},[_c('div',{staticClass:"percent-result",style:({width: ((entry.framesPercent) + "%")})}),_c('span',{staticClass:"percent-text"},[_vm._v(_vm._s(entry.framesPercent))])])]),_c('td',{staticClass:"bottom-border fourth"},[_vm._v(_vm._s(entry.bytes))]),_c('td',{staticClass:"bottom-border"},[_c('div',{staticClass:"percent-container"},[_c('div',{staticClass:"percent-result",style:({width: ((entry.bytesPercent) + "%")})}),_c('span',{staticClass:"percent-text"},[_vm._v(_vm._s(entry.bytesPercent))])])])])}),0)])])]),_c('div',{staticClass:"dialog-buttons"},[_c('div',{staticClass:"default-buttons"},[_c('button',{staticClass:"new-window",on:{"click":_vm.openNewWindow}},[_c('span',[_vm._v("Open In New Window")])]),_c('button',{staticClass:"done",on:{"click":_vm.closeDialog}},[_c('span',[_vm._v("Done")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }